import React, { Component } from 'react'
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import { Input } from 'reactstrap';
import { APIURL } from '../../constants/common';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import { Button, Spinner } from 'reactstrap';
import Select from 'react-select';

export default class Index extends Component {
    constructor() {
        super();
        this.state = {
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            userInfo: {
                name: "",
                first_name: "",
                last_name: "",
                email: "",
                introduction: "",
                license_number: "",
                password: "",
                c_password: "",
                address: "",
                country:"",
                street_name: "",
                street_number: "",
            },
            country_id: "",
            phone:"",
            state:"",
            cities_name: [],
            city_id: "",
            zip_code: "",
            languages: [],
            States: [],
            selectType: "",
            msg: "",
            errMsg: {},
            scsMsg: "",
            showPassword: false,
            Cofirm_showPassword: false,
            redirect: false
        }       
    }

    componentDidMount() {
        this.handleCountryState(1)
    }
    
    handleLanguages = (selectedLanguages) => {
        this.setState({
            selectedLanguages: selectedLanguages
        });
    }

    handleZipCode(e) {
        this.setState({
            zip_code: e.target.value
        });
    }

    handleCity(e) {
        this.setState({
            city_id: e
        })
    }


    onChangehandler = (e, key) => {
        const { userInfo } = this.state;
        userInfo[e.target.name] = e.target.value;
        this.setState({
            userInfo,
            errMsg: ""
        });
        console.log("userInfo",userInfo);
    };


    handleCountry(e){
            this.setState({
                country: e
            },()=>{
                console.log(this.state.country)
                this.handleCountryState(e)
            })
    };
    
    // get states
    handleCountryState = (id) => {
        axios
            .post(APIURL + "states", {
                country_id: id,
            })
            .then((response) => {
                console.log(response.data)
                let state_name = response.data.states;
                const stateNames = [];
                for (var c = 0; c < state_name.length; c++) {
                    stateNames.push({ value: state_name[c].id, label: state_name[c].name })
                }
                this.setState({
                    States: stateNames,
                })
            })
            .catch((error) => {
                this.setState({
    
                })
            });
    };

    handleState(e) {
        this.setState({
            state: e
        }, () => {
        if(e) {
            this.getCities(e)
        } else {
            this.setState({
                cities_name: [],
                city_id: ""
            })
        }})
    }

    getCities(state_id) {
        axios
            .post(APIURL + "cities", {
                state_id: state_id,
            })
            .then((response) => {
                let cities_name = response.data.states;
                const cityNames = [];
                for (var c = 0; c < cities_name.length; c++) {
                    cityNames.push({ value: cities_name[c].id, label: cities_name[c].name })
                }
                this.setState({
                    cities_name: cityNames,
                })
            })
    }

    onSubmitHandler = () => {
        
        var token = this.state.token
        const { userInfo, user } = this.state;
        const formData = new FormData();
        formData.append('name', this.state.userInfo.first_name);
        formData.append('email', this.state.userInfo.email);
        formData.append('password', this.state.userInfo.password);
        formData.append('c_password', this.state.userInfo.c_password);
        formData.append('country', 1);
        formData.append('state', this.state.state);
        formData.append('city', this.state.city_id);
        formData.append('zip_code', this.state.zip_code ? this.state.zip_code : "");
        formData.append('address', this.state.userInfo.address);
        formData.append('introduction', this.state.userInfo.introduction);
        formData.append('last_name', this.state.userInfo.last_name);

        this.setState({
            Loader: true
        });
        axios
            .post(APIURL + "admin/add-sub-user", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                this.setState({
                    Loader: false, 
                    scsMsg: response.data.message,
                    errMsg: "",
                }, () => {
                    setTimeout(() => this.setState({ 
                        scsMsg: "",
                        redirect: true
                    }), 4000);                   
                });
            })
            .catch((error) => {
                this.setState({
                    errMsg: error.response.data.error,
                    Loader: false
                }, () => {
                    setTimeout(() => this.setState({ 
                        errMsg: ""
                    }), 4000);                   
                });
            });
    };

    showPassword() {
        this.setState({
            showPassword: !this.state.showPassword
        })
    }

    Cofirm_showPassword() {
        this.setState({
            Cofirm_showPassword: !this.state.Cofirm_showPassword
        })
    }

    render() {
        if (!this.state.user) {
            return <Redirect to="/signin" />;
        }

        if (this.state.redirect) {
            return <Redirect to="/admin/subuserlist" />;
        }
        return (
            <div className='admin'>
                {this.state.Loader ? <div className="loader"> <Spinner type="grow" color="dark" style={{ width: '3rem', height: '3rem' }} /> </div> : ""}
                {this.state.scsMsg ? <div class="alert alert-success" role="alert"> {this.state.scsMsg} </div> : ""}
                {this.state.errMsg.message ? <div class="alert alert-danger" role="alert">  {this.state.errMsg.message}   </div> : ""}

                <Sidebar activePage="addsubuser" />
                <div class="content-area">
                    <Navbar activePage="addsubuser"/>
                    <div class="content">
                        <div class="card">
                            <div class="card-body">
                                <div class="d-flex">
                                    <h2 id="page_headeing"><span>Add SubUser</span></h2>
                                </div>
                                <hr />
                                <form>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="mb-4">
                                                <label for="first_name" class="form-label">First Name <strong className="text-danger" >*</strong></label>
                                                <input
                                                    className="form-control"
                                                    required=""
                                                    type="text"
                                                    name="first_name"
                                                    placeholder="First Name"
                                                    value={this.state.userInfo.first_name}
                                                    onChange={this.onChangehandler}
                                                />
                                                <span className="text-danger">{this.state.errMsg.name}</span>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-4">
                                                <label for="last_name" class="form-label">Last Name <strong className="text-danger" >*</strong></label>
                                                <input
                                                    className="form-control"
                                                    required=""
                                                    type="text"
                                                    name="last_name"
                                                    placeholder="Last Name"
                                                    value={this.state.userInfo.last_name}
                                                    onChange={this.onChangehandler}
                                                />
                                                <span className="text-danger">{this.state.errMsg.last_name}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div class="mb-4">
                                                <label for="introduction" class="form-label"> Introduction </label>
                                                <textarea
                                                    className="form-control"
                                                    required=""
                                                    type="textarea"
                                                    name="introduction"
                                                    placeholder="Introduction"
                                                    value={this.state.userInfo.introduction}
                                                    onChange={this.onChangehandler}
                                                >
                                                </textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        {/* <div class="col-sm-6">
                                            <div class="mb-4">
                                                <label for="exampleInputName1" class="form-label"> Name <strong className="text-danger" >*</strong></label>
                                                <input
                                                    className="form-control"
                                                    required=""
                                                    type="text"
                                                    name="name"
                                                    placeholder="First Name"
                                                    value={this.state.userInfo.name}
                                                    onChange={this.onChangehandler}
                                                />
                                                <span className="text-danger">{this.state.errMsg.name}</span>
                                            </div>
                                        </div> */}
                                        <div class="col-sm-12">
                                            <div class="mb-4">
                                                <label for="email" class="form-label">Email Address <strong className="text-danger" >*</strong></label>
                                                <input
                                                    className="form-control"
                                                    required=""
                                                    type="email"
                                                    name="email"
                                                    placeholder="Email Address"
                                                    value={this.state.userInfo.email}
                                                    onChange={this.onChangehandler}
                                                />
                                                <span className="text-danger">{this.state.errMsg.email}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="mb-4">
                                                <div class="icon-input">
                                                    <label> Password <strong className="text-danger" >*</strong></label>
                                                    <div className="position-relative">
                                                        <div className="input-with-icon">
                                                            <input
                                                                className="form-control"
                                                                required=""
                                                                type={this.state.showPassword ? "text" : "password"}
                                                                name="password"
                                                                placeholder="Password"
                                                                value={this.state.userInfo.password}
                                                                onChange={this.onChangehandler}
                                                            />
                                                            <i className="ti-unlock"></i>
                                                        </div>
                                                        {this.state.showPassword ?
                                                            <span className="fa-eye-pass input-visiblity"> <i onClick={() => this.showPassword()} className="fas fa-eye-slash"></i> </span>
                                                            :
                                                            <span className="fa-eye-pass input-visiblity"> <i onClick={() => this.showPassword()} className="fas fa-eye"></i> </span>
                                                        }
                                                    </div>
                                                </div>
                                                <span className="text-danger">{this.state.errMsg.password}</span>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-4">
                                                <div class="icon-input">
                                                    <label> Confirm Password <strong className="text-danger" >*</strong></label>
                                                    <div className="position-relative">
                                                        <div className="input-with-icon">
                                                            <input
                                                                className="form-control"
                                                                required=""
                                                                type={this.state.Cofirm_showPassword ? "text" : "password"}
                                                                name="c_password"
                                                                placeholder="Confirm Password"
                                                                value={this.state.userInfo.c_password}
                                                                onChange={this.onChangehandler}
                                                            />
                                                            <i className="ti-unlock"></i>
                                                        </div>
                                                        {this.state.Cofirm_showPassword ?
                                                            <span className="fa-eye-pass input-visiblity"> <i onClick={() => this.Cofirm_showPassword()} className="fas fa-eye-slash"></i> </span>
                                                            :
                                                            <span className="fa-eye-pass input-visiblity"> <i onClick={() => this.Cofirm_showPassword()} className="fas fa-eye"></i> </span>
                                                        }
                                                    </div>
                                                </div>
                                                <span className="text-danger">{this.state.errMsg.c_password}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        {/* <div class="col-sm-6">
                                            <div class="mb-4">
                                                <label> Phone Number <strong className="text-danger" >*</strong></label>

                                                <input
                                                    className="form-control"
                                                    required=""
                                                    type="number"
                                                    min="0"
                                                    name="phone"
                                                    onkeypress="return isNumberKey(event)"
                                                    placeholder="Phone Number"
                                                    value={this.state.phone}
                                                    onChange={(e)=>this.handlephone(e.target.value)}
                                                />
                                                <span className="text-danger">{this.state.errMsg.phone}</span>
                                            </div>
                                        </div> */}
                                        <div class="col-sm-6">
                                            <div class="mb-4">
                                                <label for="exampleInputEmail3" class="form-label">Address</label>
                                                <input
                                                    className="form-control"
                                                    required=""
                                                    type="text"
                                                    name="address"
                                                    placeholder="Address"
                                                    value={this.state.userInfo.address}
                                                    onChange={this.onChangehandler}
                                                />
                                                <span className="text-danger">{this.state.errMsg.address}</span>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-4">
                                                <label for="country" class="form-label">Country </label>
                                                <select className="form-control" value={this.state.country} onChange={(e) => this.handleCountry(e.target.value)} disabled>
                                                    <option value="1">Nigeria</option>
                                                </select>
                                                <span className="text-danger">{this.state.errMsg.country}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="mb-4">
                                                <label class="form-label">State <strong className="text-danger" >*</strong></label>
                                                <select className="form-control" autoFocus={true} value={this.state.state} onChange={(e) => this.handleState(e.target.value)} >
                                                    <option value="" >Select State</option>
                                                    {this.state.States.map((option) => (
                                                        <option value={option.value}>{option.label}</option>
                                                    ))}
                                                </select>
                                                <span className="text-danger">{this.state.errMsg.state}</span>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-4">
                                                <label for="city" class="form-label">Locality <strong class="text-danger">*</strong></label>
                                                <select className="form-control" value={this.state.city_id} onChange={(e) => this.handleCity(e.target.value)} >
                                                    <option value="" >Select Locality</option>
                                                    {this.state.cities_name.map((option) => (
                                                        <option value={option.value}>{option.label}</option>
                                                    ))}
                                                </select>
                                                <span className="text-danger">{this.state.errMsg.city}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="mb-4">
                                                <label for="Zip" class="form-label">Zip Code</label>
                                                <Input
                                                    rows="4"
                                                    className="form-control"
                                                    required=""
                                                    type="text"
                                                    name="Zip"
                                                    placeholder="Zip Code"
                                                    value={this.state.zip_code}
                                                    onChange={(e) => this.handleZipCode(e)}
                                                />
                                                <span className="text-danger">{this.state.errMsg.zip_code}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <Button
                                            // type="submit"
                                            className="btn btn-primary"
                                            onClick={() => this.onSubmitHandler()}
                                        >Submit</Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
