import React, { Component } from 'react'
import axios from "axios";
import { APIURL, PAYPAL_CLIENT_ID, SOCKETURL } from '../../../components/constants/common';
import { Button, Spinner, Input} from 'reactstrap';
import { Redirect, Link } from 'react-router-dom';
import Image1 from '../../../assets/images/image-1.webp';
import { ReactComponent as ChatIcon} from '../../../assets/images/comment.svg';
import { ReactComponent as Send} from '../../../assets/images/send.svg';
import SendIcon from '../../../assets/images/send.png';
import { ReactComponent as CloseIcon} from '../../../assets/images/close.svg'
import socketClient from "socket.io-client";

export default class Chat extends Component {
	constructor(props){
		super(props);
        this.chat_board_msg = React.createRef()
		this.state = {
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            isLoggedin: false,
            Loader: false,
            chatToggle:false,
            messageList:[],
            to:0,
            message:"",
            page:0,
            loadMoreVisiblity:false,
            cLoader:false,
            ChatNotification:0,
            socket:"",
            messageGetStatus:false
		}
        this.sendHandle = this.sendHandle.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this);
	}

	sendToggleChat(){
		 this.setState({
                    chatToggle:!this.state.chatToggle,
                    ChatNotification:0
                })

	}

    sendHandle(){
        if(this.state.message.trim()=="")
        {
          return false  
        }

         this.setState({
            Loader:true
        })
        var token = this.state.token
        const formData = new FormData();
        formData.append('to', this.props.seller_id);
        formData.append('message', this.state.message);
        formData.append('property_id',this.props.id);
      
        axios
            .post(APIURL + "buyer/send-message", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                var messageList=this.state.messageList;
                var message=this.state.message;
                messageList.push(response.data.data);

                var socket = this.state.socket;
                socket.emit('message',response.data.data);


                this.setState({
                    messageList: messageList,
                    message:"",
                    Loader:false
                },()=>{
                    this.scrollDownMessage() 
                    
                })


            })
            .catch((error) => {
                this.setState({
                    Loader:false
                })
            });
    }


    getMessage() {
        this.setState({
            cLoader:true
        })
        var token = this.state.token
        const formData = new FormData();
        formData.append('to', this.props.seller_id);
        formData.append('property_id',this.props.id);
        formData.append('page', this.state.page);

        axios
            .post(APIURL + "buyer/get-property-message", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                var messageList=this.state.messageList
                messageList=[...response.data.data,...messageList];
                var loadMoreVisiblity=false;
                if(response.data.data.length>0){
                  loadMoreVisiblity=true
                }
                
                this.setState({
                    messageList: messageList,
                    cLoader:false,
                    messageGetStatus:true,
                    loadMoreVisiblity:loadMoreVisiblity
                },()=>{
                    if(this.state.page==0){
                    this.scrollDownMessage()
                   }
                })

            })
            .catch((error) => {
                this.setState({
                    cLoader:false,
                    messageGetStatus:true
                })
            });
    }

    updateMessage() {
       
        var token = this.state.token
        const formData = new FormData();
        formData.append('to', this.props.seller_id);
        formData.append('property_id',this.props.id);

        axios
            .post(APIURL + "buyer/update-property-message-status", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
            })
            .catch((error) => {
               
            });
    }

    getChatCount() {
        const formData = new FormData();
        formData.append('to', this.props.seller_id);
        formData.append('property_id',this.props.id);
        var token = this.state.token
        var app_url = APIURL+"buyer/get-seller-unread-property-message-count"
        axios
            .post(app_url, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                    this.setState({
                        ChatNotification: response.data.data
                    })
            })
            .catch((error) => {
              
            });
    }


    setTo(item){
      
        this.setState({
            to: item.id,
        },()=>{
            this.getMessage()
        })
    }

    messageHandle(val){
      this.setState({
            message: val
        })
    }

   _handleKeyDown(e) {

        if (e.key === 'Enter') {
          this.sendHandle();
        }
      }


    scrollDownMessage(){
         this.chat_board_msg.current.scrollTop =  this.chat_board_msg.current.scrollHeight;
    }
    
    loadMore(){
        var page=this.state.page;
        page+=1;

    this.setState({
            page: page,
        },()=>{
            this.getMessage()
        })
    }

     configureSocket = () => {
        var socket = socketClient(SOCKETURL);
        
        this.setState({
            socket: socket
        })
        socket.on('message', data => {

           if(this.state.user)
            {
                if(data.to==this.state.user.id && data.property_id==this.props.id && data.from==this.state.to && this.state.chatToggle==true)
                {
                    var messageList=this.state.messageList;
                    messageList.push(data);
                    this.setState({
                        messageList: messageList
                    },()=>{
                        this.scrollDownMessage()
                        this.updateMessage()
                    })
                }
                else if(data.to==this.state.user.id && data.property_id==this.props.id  && this.state.chatToggle==false)
                {
                    var ChatNotification=this.state.ChatNotification;
                    this.setState({
                        ChatNotification: ChatNotification
                    })
                     
                }
            }

        });

      
    }

    componentDidMount() {
     this.getMessage()
     this.getChatCount()
     this.configureSocket()

    }

	render() {
       
		return (
            <div className="chatbox">
                <div className={`chat_bubblebox ${this.state.chatToggle==true ? "open" : ""}`} > {/*open clss toggle*/}
                    <div className="bubblebox_head">
                        <ChatIcon className="img-svg" alt="" /> Chat
                        <CloseIcon  onClick={() => this.sendToggleChat()} className="img-svg close" alt="" />
                    </div>
                    <div className="bubblebox_body" ref={this.chat_board_msg}>
                        { this.state.loadMoreVisiblity==true &&  this.state.cLoader===false && this.state.messageList.length > 9 && 
                            <div className="chat_load_more">
                            <Button className="btn-primary btn btn-sm" onClick={() => this.loadMore()} > Load More</Button>
                            </div>
                        }
                         {this.state.messageList.length > 0 && this.state.messageList.map((item, idx) => (
                            <React.Fragment>
                            {item.to!=this.state.to &&
                                <div className="bubble bubbleIn">
                                    <p>{item.message}</p>
                                    <span className="date">{item.date} {item.timestamp}</span>
                                </div>
                            }
                            {item.to==this.state.to &&
                                <div className="bubble bubbleOut">
                                    <p>{item.message}</p>
                                  
                                    <span className="date">{item.date} {item.timestamp}</span>
                                </div>
                            }
                           
                             </React.Fragment>
                            
                          ))}
                           {this.state.messageList.length == 0 && this.state.messageGetStatus==true &&
                              <div className="bubble no-message">Send message to seller</div>
                           }
                    </div>
                    <div className="bubblebox_footer">
                        <input type="text" placeholder="Type a message" value={this.state.message}  onKeyDown={this._handleKeyDown} onChange={(e) => this.messageHandle(e.target.value)} />
                        <button><img src={SendIcon} alt="send" onClick={() => this.sendHandle()} /></button>
                    </div>
                </div>
                <div className="chat_icon" onClick={() => this.sendToggleChat()}><ChatIcon className="img-svg" alt="" />
                { this.state.ChatNotification>0 && 
                <span className="indicators"></span>
                }
                </div>
            </div>
		)
	}
}
