import React, { Component } from 'react'
import Sidebar from '../Sidebar';
import Navbar from '../Navbar';
import { Redirect } from 'react-router';
import { APIURL } from '../../../constants/common';
import axios from 'axios';
import dateFormat, { masks } from "dateformat";
import DatePicker from "react-datepicker";
import Pagination from "react-js-pagination";

export default class BoostingTransaction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            navigate: false,
            reload: false,
            PropertyList: [],
            errMsg: "",
            scsMsg: "",
            property_id: "",
            user_id:"",
            norecord: false,
            boostList: [],
            activePage: 1,
            limit: 0,
            totalItemsCount: 0,
            package: 100,
            property_count: 1,
            month_count: 1,
            price: "",
            selectedDate: "",
            showDate: "",
            PurchaseList:"",
            totalprice:"",
            boosterPlanModal:false,
            redirect:false,
            isLoading: false,
            fullscrLoader: true,
        }
    }
    componentDidMount() {
        this.purchasePlanList();       
    }


    purchasePlanList() {
        this.setState({
            Loader: true
        })
        var token = this.state.token
        const formData = new FormData();
        formData.append('page', this.state.activePage);
        formData.append('datefilter', this.state.selectedDate);
        formData.append('limit', this.state.limit);

        axios
            .post(APIURL + "seller/get-property-booster-transaction-list", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                console.log("response", response.data)
                this.setState({
                    PurchaseList: response.data.data.data,
                    activePage: response.data.data.current_page,
                    totalItemsCount: response.data.data.total,
                    limit: response.data.data.per_page,
                    norecord: true,
                    Loader: false
                })
                // console.log("PurchaseList",this.state.PurchaseList)
            })
            .catch((error) => {
                this.setState({
                    norecord: true,
                    Loader: false
                })
            });
    }


    // handleproperty = (e) => {
    //     this.setState({
    //         property_id: e.target.value,
            
    //     })
    //     console.log("selected property", e.target.value)
    // }
    handleSelectedDate(e) {
        this.setState({
            showDate: e,
            selectedDate: dateFormat(e, "yyyy-mm-dd"),
            activePage: 1,
        }, () => {
            this.purchasePlanList()
        })
    }

    clearDate() {
        this.setState({
            showDate: "",
            selectedDate: "",
            activePage: 1,
         
        }, () => {
            this.purchasePlanList()
        })
    }

    handlePageChange(pageNumber) {
        this.setState(
            { activePage: pageNumber }
            , () => {
                this.purchasePlanList()
            }
        );
    }

   


    render() {
        if (!this.state.user) {
            return <Redirect to="/signin" />;

        }
        if (this.state.redirect) {
            return <Redirect to="/seller/boost/package" push={true} />;
        }

        return (
            <div className="admin">
                {this.state.scsMsg ? <div class="alert alert-success" role="alert"> {this.state.scsMsg} </div> : ""}
                {this.state.errMsg.message ? <div class="alert alert-danger" role="alert">  {this.state.errMsg.message}   </div> : ""}
                <Sidebar activePage="boost_transaction" />
                <div class="content-area">
                    <Navbar />
                    <div class="content">
                        
                        <div class="card">
                            <div class="card-body">
                                <div class="d-sm-flex justify-content-between align-items-center">
                                    <h2 id="page_headeing"><span>Property Boost Transaction History</span></h2>
                                    <div class="row align-items-end">
                                        <div class="col-12">
                                            <div class="small">Purchase Date</div>
                                                <div className="position-relative">
                                                    <DatePicker
                                                        dateFormat="dd/MM/yyyy"
                                                        selected={this.state.showDate}
                                                        onSelect={(e) =>this.handleSelectedDate(e)}
                                                        placeholderText="Purchase Date"
                                                        className="form-control form-control_new border-0 border-end-0"
                                                    />
                                                    <button onClick={() =>this.clearDate()} className="cleardate">x</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />

                                <div>
                                    <div class="table-responsive">
                                        <table class="table table-striped table-borderless">
                                            <thead>
                                                <tr class="border-bottom">
                                                    <th style={{minWidth:70}}>Sr. No.</th>
                                                    <th style={{minWidth:150}}>No. Of Property</th>
                                                    <th style={{minWidth:130}}>No. Of Month </th>
                                                    <th style={{minWidth:120}}>Total Price</th>
                                                    <th style={{minWidth:120}}>Purchase Date</th>
                                                    <th style={{minWidth:100}}>End Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.PurchaseList.length > 0 && this.state.PurchaseList.map((item, idx) => (
                                                    <tr>
                                                        <td>{idx + 1}</td>
                                                        <td>{item.property_count}</td>
                                                        <td>{item.month_count}</td>
                                                        <td>{item.total_price}</td>
                                                        <td>{dateFormat(item.created_at, "mmm d, yyyy")}</td>
                                                        <td>{dateFormat(item.expired_date, "mmm d, yyyy")}</td>
                                                    </tr>
                                                ))}
                                                {this.state.PurchaseList.length === 0 && this.state.norecord==true && 
                                                    <tr className="text-center">
                                                        <td colspan="7">No record found</td>
                                                    </tr>
                                                    }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="mt-4 text-center">
                                    {this.state.totalItemsCount>0 && 
                                        <div class="justify-content-center d-flex">
                                        <Pagination
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.state.limit}
                                            totalItemsCount={this.state.totalItemsCount}
                                            pageRangeDisplayed={5}
                                            onChange={this.handlePageChange.bind(this)}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            innerClass="pagination justify-content-center"
                                            activeLinkClass="active"
                                            nextPageText="Next"
                                            prevPageText="Previous"
                                        />
                                        </div>
                                    }
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        );
    }
}
