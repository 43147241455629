import React, { Component } from 'react'
import { Modal, Spinner, ModalBody, ModalHeader, ModalFooter, Button, Input } from 'reactstrap'
import { Link, Redirect } from 'react-router-dom'
import { APIURL } from '../../constants/common';
import axios from 'axios'
import Pagination from "react-js-pagination";
import dateFormat, { masks } from "dateformat";
import { ReactComponent as EyeIcon} from '../../../assets/images/eye.svg';
import { ReactComponent as CommentIcon} from '../../../assets/images/comment.svg';
import { ReactComponent as FileIcon} from '../../../assets/images/file-text.svg';
import { ReactComponent as DropDown} from '../../../assets/images/three-dots-vertical.svg';
import Navbar from './Navbar';
import Sidebar from './Sidebar';

export default class BuyerProperty extends Component {
    constructor() {
        super();

        this.state = {
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            propertyName: "",
            AddpropertyModal: false,
            propertyList: [],
            statusArray: [{ 'value': "", "label": "Status" }, { 'value': "under_review", "label": "Under Review" }, { 'value': "pending", "label": "Pending" }, { 'value': "verify", "label": "Verified" }, { 'value': "unverify", "label": "Not Verified" }],
            statusDefault: [{ 'value': "", "label": "Status" }],
            property_id: "",
            activePage: 1,
            limit: 0,
            totalItemsCount: 0,
            search: "",
            status: [],
            sort: false,
            sortby: "DESC",
            columnName: "",
            color: 'white',
            availableCategories: [],
            selectedCategory: "",
            commentModal: false,
            docData: [],
            adminComment: "",
            Loader:false,
            norecord:false
        }
        //this.SortBy = this.SortBy.bind(this);
    }

    

    handlePageChange(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        this.setState(
            { activePage: pageNumber }
            , () => {
                this.getPropertyList()
            }
        );
    }

    handleStatus(e) {
        this.setState(
        {
            status: e,
             activePage: 1
        }
        , () => { this.getPropertyList() });
    }

    commentModal(adminComment) {
        this.setState({
            commentModal: !this.state.commentModal,
            adminComment: adminComment
        })
    }

    commentModalClose() {
        this.setState({
            commentModal: false
        })
    }
    
    getPropertyList() {
        this.setState({
            Loader: true
        })
        var token = this.state.token
        const formData = new FormData();
        formData.append('page', this.state.activePage);
        formData.append('search', this.state.search);
        formData.append('statusfilter', this.state.status);
        // formData.append('sortby', this.state.sortby);
        formData.append('limit', this.state.limit);
        //formData.append('sorttype', this.state.columnName);

        axios
            .post(APIURL + "agent/get-assign-buyer-property-list-for-agent", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                //console.log("seller/get-properties", response.data.data)
                this.setState({
                    propertyList: response.data.data.data,
                    activePage: response.data.data.current_page,
                    totalItemsCount: response.data.data.total,
                    limit: response.data.data.per_page,
                    Loader:false,
                    norecord:true
                })

            })
             .catch((error) => {
                this.setState({
                    norecord:true,
                    Loader: false,
                })
            });
    }

    componentDidMount() {
        this.getPropertyList();
    }
    
    render() {
        
        return (
            <div className='admin'>
                <Sidebar activePage="buyeraddedproperty" />
                <div class="content-area">
                {this.state.Loader ? <div className="loader"> <Spinner type="grow" color="dark" /> </div> : ""}
                <Navbar/>   
                    <div class="content">
                        <div class="card">
                        <div class="card-body">
                            <div class="d-sm-flex justify-content-between align-items-center">
                                <h2 id="page_headeing"><span>Buyer Added</span></h2>
                                <div>
                                    <select onChange={(e) => this.handleStatus(e.target.value)} className="form-control form-control_new border-0">
                                        <option value="">All</option>
                                        <option value="under_review">Under Review</option>
                                        <option value="verify">Verified</option>
                                        <option value="reject">Rejected</option>
                                    </select>
                                </div>
                            </div>
                            <hr />
                            <div>
                            <div class="table-responsive">
                                <table class="table table-striped table-borderless">
                                <thead>
                                    <tr class="border-bottom">
                                    <th style={{minWidth:70}}>Sr. No.</th>
                                    <th style={{minWidth:120}}>Property Name</th>
                                    <th style={{minWidth:120}}>Created Date</th>
                                    {/* <th>Property Type</th> */}
                                    <th>Status</th>
                                    <th width="70px">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.propertyList.length > 0 && this.state.propertyList.map((item, idx) => (
                                    <tr>
                                        <td>{idx+1}</td>
                                        <td>{item.property_name}</td>
                                        <td>{dateFormat(item.created_at, "mmm d, yyyy")}</td>
                                        {/* <td>{item.category_name}</td> */}
                                        <td>
                                        {
                                            item.status === "verify" ?
                                                <span class="badge bg-success">Verified</span>
                                            :
                                            item.status === "under_review" ?
                                                <span class="badge bg-warning">Under Review</span>
                                            : 
                                                item.status === "reject" ?
                                                <span class="badge bg-danger">Rejected</span>
                                            : ""
                                        }
                                        </td>
                                       
                                        <td>
                                            <div class="navbar py-0 no_navbar text-center">
                                            <div class="dropdown">
                                                <a class="dropdown-toggle btn-rounded btn btn-default" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                                <DropDown class="img-svg" />
                                            </a>

                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                {
                                                    item.status === "reject" &&
                                                    <div className='mb-2'><button className="btn mb-0 btn-rounded btn-default ms-0" onClick={() => this.commentModal(item.agent_comment)}><CommentIcon class="img-svg" /></button><span>Comment</span></div>
                                                }
                                                {
                                                    item.status === "verify" &&
                                                    <div className='mb-2'><a href={"/common/property-certificate/" + item.property_id} target="_blank" className="btn mb-0 btn-rounded btn-default ms-0">
                                                    <FileIcon class="img-svg" /></a><span>Certificate</span></div>
                                                }
                                                <div><Link to={"/agent/propertyaddedbybuyer/details/" + item.property_id} className="btn mb-0 btn-rounded btn-info"><EyeIcon class="img-svg" /></Link><span>Details</span></div>
                                            </div>
                                            </div>
                                            </div>
                                        </td>
                                        
                                    </tr>
                                ))}
                                 {this.state.propertyList.length === 0 && this.state.norecord==true && 
                                    <tr className="text-center">
                                        <td colspan="5">No record found</td>
                                    </tr>
                                }
                                </tbody>
                                </table>
                            </div>
                            <div class="mt-4 text-center">
                                {this.state.totalItemsCount>0 && 
                                    <div class="justify-content-center d-flex">
                                    <Pagination
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={this.state.limit}
                                        totalItemsCount={this.state.totalItemsCount}
                                        pageRangeDisplayed={5}
                                        onChange={this.handlePageChange.bind(this)}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        innerClass="pagination justify-content-center"
                                        activeLinkClass="active"
                                        nextPageText="Next"
                                        prevPageText="Previous"
                                    />
                                    </div>
                                }
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                
                <Modal size="md" isOpen={this.state.commentModal} toggle={() => this.commentModalClose()}>
                <ModalHeader className="" toggle={() => this.commentModalClose()}>
                    Comment <br />
                    <span className="text-danger">{this.state.errMsgCmt}</span>
                </ModalHeader>
                <ModalBody className="border-0">
                    <div className="row">
                        <div className="col-lg-12 col-md-6">
                            <div className="form-group">
                                <div className="input-with-icon">
                                    <textarea
                                        rows="4"
                                        className="form-control h-auto"
                                        required=""
                                        type="textarea"
                                        name="comment"
                                        value={this.state.adminComment}
                                        disabled
                                    />
                                    {/* <i class="fas fa-address-card"></i> */}
                                </div>
                                {/* <span className="text-danger">{this.state.errMsg.address}</span> */}
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="">
                        <button type="button" className="btn btn-light" onClick={() => this.commentModalClose()}>Close</button>
                    </div>
                </ModalFooter>
                </Modal>
            </div>
        )
    } 
}
