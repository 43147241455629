import React, { Component } from 'react'
import { Redirect, Link } from 'react-router-dom'
import axios from "axios";
import { APIURL } from '../../components/constants/common';
import {ReactComponent as Check  } from '../../assets/images/check.svg'

export default class Packages extends Component {
	constructor(){
		super();
		this.state = {
			stylePath:true,
			token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            user_type: localStorage.getItem("user_type"),
			plans: []
		}
	}

	componentDidMount(){
		this.getPlans("Buyer");
	}

	getPlans(user) {
		var token = ""
		var app_url = APIURL + "get-subscription-plan-using-user-type"
		const formData = new FormData();
		formData.append('user_type', user);           
		axios
			.post(app_url,formData, {
				headers: {
					'Authorization': `Bearer ${token}`
				}
			})
			.then((response) => {
				this.setState({
					plans: response.data.data ,
				})
			})
			.catch((error) => {
				this.setState({
					errMsg: error.response.data.error ,
				})
			});
    }

	render() {
		return (
		<div>	
			 <section id="packages" class="gradient1 burger">
		        <div class="container">
		          <header class="section_heading">
		            <div class="row">
		              <div class="col-lg-6 col-md-8 col-sm-10 m-auto">
		                <h2>See Our Packages</h2>
		                <p>Select a premium package to enjoy a personalized and improved service option</p>
		              </div>
		            </div>
		          </header>
		          <div class="row">
		            <div class="col-sm-12">
		              <ul class="nav nav-pills mb-3 justify-content-center" id="pills-tab" role="tablist">
		                <li class="nav-item me-2" role="presentation">
		                  <button class="nav-link active" id="pills-buyers-tab" data-bs-toggle="pill" data-bs-target="#pills-buyers" type="button" role="tab" aria-controls="pills-buyers" aria-selected="true" onClick={()=>this.getPlans("Buyer")}>Buyers</button>
		                </li>
		                <li class="nav-item ms-2" role="presentation">
		                  <button class="nav-link" id="pills-sellers-tab" data-bs-toggle="pill" data-bs-target="#pills-sellers" type="button" role="tab" aria-controls="pills-sellers" aria-selected="false" onClick={()=>this.getPlans("Seller")}>Sellers</button>
		                </li>
		              </ul>

		              <div class="tab-content" id="pills-tabContent">
		                <div class="tab-pane fade show active" id="pills-buyers" role="tabpanel" aria-labelledby="pills-home-tab">
		                  <div class="row align-items-center justify-content-center">
						  {this.state.plans.length > 0 && this.state.plans.map((item, idx) => (
                        	<>
                        	{ item.type == "basic"
		                    ? <div class="col-xl-4 col-lg-5 col-md-6">
		                      <div class="packages animate">
		                        <div class="packages__desc animate">
		                          <div class="packages__price">
		                            <div>
										<sup>£</sup>
		                            	<h5>{item.amount}</h5>
									</div>
		                          </div>
		                          <h3 class="packages__name">Basic Package</h3>
		                          <ul class="packages__feature">
		                            <li><Check alt="check" class="img-svg" /><span>Profile Name Identifiable</span></li>
		                            <li><Check alt="check" class="img-svg" /><span>Standard Checkout </span></li>
		                            <li><Check alt="check" class="img-svg" /><span>Individual Shopper</span></li>
		                            <li><Check alt="check" class="img-svg" /><span>Non Transferrable Verification</span></li>
		                            <li><Check alt="check" class="img-svg" /><span>No Referral Discount</span></li>
		                            <li><Check alt="check" class="img-svg" /><span>Free Chatwith Seller</span></li>
		                            <li><Check alt="check" class="img-svg" /><span>Free to Search/Browse</span></li>
		                            <li><Check alt="check" class="img-svg" /><span>Create Property Alert</span></li>
		                          </ul>
		                        </div>
		                        <div class="text-right">
									{
										this.state.user == null
										? <Link to="/signin" className="">Choose Plan</Link>
										: this.state.user.user_type=="Buyer"
										? <Link to="/buyer/subscribe" className="">Choose Plan</Link>
										:<Link to="/seller/subscribe" className="">Choose Plan</Link>
									}	
								</div>
		                      </div>
		                    </div>
							: item.type === "premium" &&
		                    <div class="col-xl-4 col-lg-5 col-md-6">
		                      <div class="packages animate">
		                        <div class="packages__desc">
		                          <div class="packages__price">
		                            <div>
										<sup>£</sup>
		                            	<h5>{item.amount}</h5>
									</div>
		                          </div>
		                          <h3 class="packages__name">Premium Package</h3>
		                          <ul class="packages__feature">
		                            <li><Check alt="check" class="img-svg" /><span>Incognito Mode</span> </li>
		                            <li><Check alt="check" class="img-svg" /><span>Easier/Faster Checkout</span> </li>
		                            <li><Check alt="check" class="img-svg" /><span>Executive purchase(E2E/P2P)</span> </li>
		                            <li><Check alt="check" class="img-svg" /><span>Transferrable Verification</span> </li>
		                            <li><Check alt="check" class="img-svg" /><span>Referral Discount (Unique User)</span> </li>
		                            <li><Check alt="check" class="img-svg" /><span>Free Chatwith Seller</span> </li>
		                            <li><Check alt="check" class="img-svg" /><span>In App Personal Shopper Chat with Recruiter/Assistance</span> </li>
		                            <li><Check alt="check" class="img-svg" /><span>Create Property Alert</span> </li>
		                          </ul>
		                        </div>
		                        <div class="text-right">
									{
										this.state.user == null
										? <Link to="/signin" className="">Choose Plan</Link>
										: this.state.user.user_type == "Buyer"
										? <Link to="/buyer/subscribe" className="">Choose Plan</Link>
										:<Link to="/seller/subscribe" className="">Choose Plan</Link>
									}
		                        </div>
		                      </div>
		                    </div>
							}
							</>
						  ))}
		                  </div>
		                </div>
		                {/* <div class="tab-pane fade" id="pills-sellers" role="tabpanel" aria-labelledby="pills-profile-tab">
		                  <div class="row align-items-center justify-content-center">
		                    <div class="col-lg-4 col-md-6">
		                      <div class="packages animate">
		                        <div class="packages__desc animate">
		                          <div class="packages__price">
		                            <sup>$</sup>
		                            <h5>49</h5>
		                          </div>
		                          <h3 class="packages__name">Basic Package</h3>
		                          <ul class="packages__feature">
		                            <li><Check alt="check" class="img-svg" /><span>Free Property Upload</span></li>
		                            <li><Check alt="check" class="img-svg" /><span>45 Days Upload (Time Limit) </span></li>
		                            <li><Check alt="check" class="img-svg" /><span>Ad Boosting </span></li>
		                            <li><Check alt="check" class="img-svg" /><span>No Commission on Uploads </span></li>
		                            <li><Check alt="check" class="img-svg" /><span>Non Transferrable Verification </span></li>
		                            <li><Check alt="check" class="img-svg" /><span>No Referral Discount </span></li>
		                            <li><Check alt="check" class="img-svg" /><span>Free Chat with Buyer </span></li>
		                            <li><Check alt="check" class="img-svg" /><span>No Verification Banner </span></li>
		                          </ul>
		                        </div>
		                        <div class="text-right">
		                          <a href="#">Choose Plan</a>
		                        </div>
		                      </div>
		                    </div>
		                    <div class="col-lg-4 col-md-6">
		                      <div class="packages animate active">
		                        <div class="packages__desc">
		                          <div class="packages__price">
		                            <sup>$</sup>
		                            <h5>99</h5>
		                          </div>
		                          <h3 class="packages__name">Premium Package</h3>
		                          <ul class="packages__feature">
		                            <li><Check alt="check" class="img-svg" /><span>Free Property Upload</span> </li>
		                            <li><Check alt="check" class="img-svg" /><span>Uncapped Ad Upload</span> </li>
		                            <li><Check alt="check" class="img-svg" /><span>3 Free Ad Boosting/Month</span> </li>
		                            <li><Check alt="check" class="img-svg" /><span>Commission on Upload/VR (Verification Request)</span> </li>
		                            <li><Check alt="check" class="img-svg" /><span>Transferrable Verification (Once)</span> </li>
		                            <li><Check alt="check" class="img-svg" /><span>Referral Discount (Unique Users)</span> </li>
		                            <li><Check alt="check" class="img-svg" /><span>Free Chat with Buyers</span> </li>
		                            <li><Check alt="check" class="img-svg" /><span>In App Verification Banner</span> </li>
		                          </ul>
		                        </div>
		                        <div class="text-right">
		                          <a href="#">Choose Plan</a>
		                        </div>
		                      </div>
		                    </div>
		                  </div>
		                </div> */}
		              </div>
		            </div>
		          </div>
		        </div>
		      </section>

		</div>	
		)
	}
}
