import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import Sidebar from './../Sidebar'
import axios from "axios";
import { APIURL } from '../../../../components/constants/common';
import Navbar from './../Navbar'
import Button from '@restart/ui/esm/Button';
import { Spinner, Input } from 'reactstrap'
import dateFormat, { masks } from "dateformat";
import Pagination from "react-js-pagination";
import { ReactComponent as EditPencil} from '../../../../assets/images/pencil-fill.svg';
import Tooltip from '@mui/material/Tooltip';

export default class PageList extends Component {
    constructor() {
        super();
        this.state = {
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            user_type: localStorage.getItem("user_type"),
            navigate: false,
            errMsg: "",
            scsMsg: "",
            profile_image: "",
            fullscrLoader: false,
            cmsDataList: [],
            activePage: 1,
            limit: 0,
            totalItemsCount: 0,
            userType: ""
            
        }
    }

    componentDidMount() {
        this.getCmsList()
       
    }

 
    handlePageChange(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        this.setState(
            { activePage: pageNumber }
            , () => {
                this.getCmsList()
            }
        );
    }

    
    getCmsList() {
        if (this.state.user) {
            const formData = new FormData();
            
            formData.append('page', this.state.activePage);
            formData.append('limit', this.state.limit);
            var token = this.state.token
            var app_url = APIURL+"admin/get-cms-page-list"
            axios
                .post(app_url, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    // const info = response.data.data;
                    console.log(response.data.data.data)
                    this.setState({
                        cmsDataList: response.data.data.data,
                        activePage: response.data.data.current_page,
                        totalItemsCount: response.data.data.total,
                        limit: response.data.data.per_page
                    })
                })
                .catch((error) => {
                    console.log(error.response.data.errors)
                    this.setState({
                        // errMsg: error.response.data.errors,
                        Loader: false
                    })
                });
        }
    }

   
       

    render() {
       
        return (
           <div className="admin">
           
             {this.state.Loader ? <div className="loader"> <Spinner type="grow" color="dark" style={{ width: '3rem', height: '3rem' }} /> </div> : ""}
               <Sidebar activePage="cms/pagelist" />
                <div class="content-area">
                 <Navbar />

                    <div class="content">
                        <div class="card">
                            <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <h2 id="page_headeing"><span>CMS</span></h2>
                                <div class="d-flex justify-content-end align-items-center">
                                <div class="row align-items-end">
                            </div>
                            </div>
                            </div>
                            <hr />
                            <div>
                            <div class="table-responsive">
                                <table class="table table-striped table-borderless">
                                <thead>
                            <tr>
                                <th className="sort-by" style={{ cursor: "pointer" }} > Page Title </th>
                                <th className="sort-by" style={{ cursor: "pointer" }}> URl key</th>
                                <th> Status </th>
                                <th > Action </th>
                            </tr>
                                </thead>
                                <tbody>
                                {this.state.cmsDataList.length > 0 ?
                                        this.state.cmsDataList.map((item, idx) => (
                                            <tr key={idx} >
                                                <td >
                                                    {item.page_title}
                                                </td>
                                                <td>
                                                    {item.url_key}
                                                </td>
                                                <td>
                                                    {   item.status === "active"
                                                        ? <span>Active</span>
                                                        : <span>Inactive</span>
                                                    }
                                                </td>
                                                <td>               
                                                <Tooltip title="Edit"><Link to={"/edit/cms/" + item.id} className="btn btn-info px-3 btn-rounded btn-sm"><EditPencil class="img-svg" /></Link></Tooltip>
                                                        
                                                </td>

                                            </tr>
                                        )) :
                                        <tr  >
                                            <td className="text-center" colSpan="5">
                                                No Records
                                            </td>
                                        </tr>
                                    }

                                                        </tbody>
                                </table>
                            </div>
                            <div class="mt-4 text-center">
                                {this.state.totalItemsCount>0 && 
                                    <div class="justify-content-center d-flex">
                                    <Pagination
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={this.state.limit}
                                        totalItemsCount={this.state.totalItemsCount}
                                        pageRangeDisplayed={5}
                                        onChange={this.handlePageChange.bind(this)}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        innerClass="pagination justify-content-center"
                                        activeLinkClass="active"
                                        nextPageText="Next"
                                        prevPageText="Previous"
                                    />
                                    </div>
                                }
                            </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>

                          
            </div>
        )
    }
}