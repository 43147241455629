import React, { Component } from 'react'
import Navbar from '../Navbar'
import Sidebar from '../Sidebar'

import axios from "axios";
import Pagination from "react-js-pagination";
import { APIURL, BASEURL } from '../../../../components/constants/common';
import { Redirect, Link } from 'react-router-dom'
import dateFormat, { masks } from "dateformat";
import ReactTooltip from 'react-tooltip';
import { Modal, Spinner, ModalBody, ModalHeader, ModalFooter, Button, Input } from 'reactstrap'
import { ReactComponent as EditPencil} from '../../../../assets/images/pencil-fill.svg';
import { ReactComponent as EyeIcon} from '../../../../assets/images/eye.svg';
import { ReactComponent as CommentIcon} from '../../../../assets/images/comment.svg';
import { ReactComponent as CopyIcon} from '../../../../assets/images/files.svg';
import {FacebookShareButton, FacebookIcon} from "react-share";
import {CopyToClipboard} from 'react-copy-to-clipboard';


export default class PropertyList extends Component {
    constructor() {
        super();
        this.state = {
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            plans: [],
            errMsg: "",
            scsMsg: "",
            activePage: 1,
            limit: 0,
            totalItemsCount: 0,
            search: "",
            status: [],
            sort: false,
            sortby: "DESC",
            records: [],            
            shareUrl:window.location.href,
            copied: false,
            norecord:false,
            Loader:false
        }
    }

    handlePageChange(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        this.setState(
            { activePage: pageNumber }
            , () => {
                this.getPropertyList()
            }
        );
    }



    getCouponList() {
        this.setState({
            Loader:true
        })
        var token = this.state.token
        const formData = new FormData();
        formData.append('page', this.state.activePage);
        formData.append('search', this.state.search);
        formData.append('statusfilter', this.state.status);
        // formData.append('sortby', this.state.sortby);
        formData.append('limit', this.state.limit);
        //formData.append('sorttype', this.state.columnName);

        axios
            .post(APIURL + "buyer/get-referral-coupon-code-used-user-list", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                this.setState({
                    records: response.data.data.data,
                    activePage: response.data.data.current_page,
                    totalItemsCount: response.data.data.total,
                    limit: response.data.data.per_page,
                    norecord:true,
                    Loader:false

                })
            })
            .catch((error) => {
                this.setState({
                    norecord:true,
                      Loader:false
                })
            });
    }
    
    handleSearch(e){
         this.setState({
            search: e,
            activePage:1
        },()=>{this.getCouponList()});
    }

    handleStatus(e){
         this.setState({
            status: e,
            activePage:1
        },()=>{this.getCouponList()});
    }

    handleCopy(){
        let self=this
        this.setState({copied: true})
        setTimeout(function(){
            self.setState({
           copied: false
           });
        },500)
    }

    componentDidMount() {
        this.getCouponList();
    }



    render() {

        if (!this.state.user) {
            return <Redirect to="/signin" />;
        }
        if (this.state.user.user_type !== "Seller") {
            return <Redirect to="/permission" />;
        }
        if (this.state.user.subscriptionPlanDetail && this.state.user.subscriptionPlanDetail.plan_name=="Basic Plan") {
            return <Redirect to="/permission" />;
        }
       

        return (
            <>
             <div className="admin">
                <Sidebar activePage="invited" />

               <div class="content-area">
                  {this.state.Loader ? <div className="loader"> <Spinner type="grow" color="dark" style={{ width: '3rem', height: '3rem' }} /> </div> : ""}

                <Navbar activePage="invited"/>   
                  <div class="content">
                        
                        <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <h2 id="page_headeing"><span>Invite Link</span></h2>
                                <div class="row align-items-end">
                                </div>
                            </div>

                                <div className="d-flex justify-content-start align-items-center mb-3">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <Input
                                            type="text"
                                            placeholder="Search"
                                            className="form-control me-3 form-control_new border-0 border-end-0"
                                            value={BASEURL+"signup?token="+this.state.user.id} style={{width: '230px'}}
                                            disabled
                                        />
                                        <CopyToClipboard text={BASEURL+"signup?token="+this.state.user.id}
                                            onCopy={() => this.handleCopy()}>
                                            <CopyIcon class="img-svg me-1"/>
                                        </CopyToClipboard>
                                        {this.state.copied ? <span style={{color: '#22C176'}}>Copied</span> : null}                                                        
                                    </div>
                                    <div className="ms-3">
                                        <FacebookShareButton
                                            url={BASEURL+"signup?token="+this.state.user.id}
                                            quote="imenso"
                                            className="Demo__some-network__share-button"
                                            >
                                            <FacebookIcon size={32} round />
                                        </FacebookShareButton>
                                    </div>
                                </div>
                                
                                <div class="d-sm-flex justify-content-between align-items-end">
                                    <h2>Invited User</h2>
                                    <div class="row align-items-end">
                                        <div class="col-6">
                                            <Input
                                                type="text"
                                                onChange={(e) => this.handleSearch(e.target.value)}
                                                placeholder="Search"
                                                className="form-control form-control_new border-0 border-end-0"
                                            />
                                        </div>
                                        <div class="col-6">
                                            <div className="small">Status</div>
                                            <select onChange={(e) => this.handleStatus(e.target.value)} className="form-control form-control_new border-0">
                                                <option value="">All</option>
                                                <option value="used">Used</option>
                                                <option value="unused">Unused</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            <hr />
                            <div>
                            <div class="table-responsive">
                                <table class="table table-striped table-borderless">
                                <thead>
                                    <tr class="border-bottom">
                                        <th style={{minWidth:70}}>Sr. No.</th>
                                        <th style={{minWidth:100}}>Coupon Code </th>
                                        <th style={{minWidth:100}}>Used By </th>
                                        <th style={{minWidth:100}}>Date</th>
                                        <th width="14%">Status</th>
                                                                        
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.records.length > 0 && this.state.records.map((item, idx) => (
                                    <tr>
                                        <td>{idx+1}</td>
                                        <td>{item.coupon_code}</td>
                                         <td>{item.user_name}</td>
                                        <td>{dateFormat(item.created_at, "mmm d, yyyy")}</td>
                                        <td>
                                        {
                                            item.status === "used" ?
                                                <span class="badge bg-success">Used</span>
                                            :
                                            <span class="badge bg-warning">Unused</span>
                                           
                                        }
                                        </td>
                                        
                                                                        
                                    </tr>
                                ))}
                                {this.state.records.length === 0 && this.state.norecord==true && 
                                    <tr className="text-center">
                                        <td colspan="5">No record found</td>
                                    </tr>
                                }
                                </tbody>
                                </table>
                            </div>
                            <div class="mt-4 text-center">
                                {this.state.totalItemsCount > 0 &&
                                    <div class="justify-content-center d-flex">
                                        <Pagination
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.state.limit}
                                            totalItemsCount={this.state.totalItemsCount}
                                            pageRangeDisplayed={5}
                                            onChange={this.handlePageChange.bind(this)}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            innerClass="pagination justify-content-center"
                                            activeLinkClass="active"
                                            nextPageText="Next"
                                            prevPageText="Previous"
                                        />
                                    </div>
                                }
                            </div>
                         </div>
                        </div>
                        </div>
                    </div>
                </div>
              </div>
            </>
        )
    }
}
