import React, { Component } from 'react'
import Pagination from "react-js-pagination";
import { ReactComponent as GeoAlt } from '../../assets/images/geo-alt.svg'
import Image1 from '../../assets/images/image-1.webp'
import { ReactComponent as Heart } from '../../assets/images/heart.svg'
import { ReactComponent as HeartRed } from '../../assets/images/heart-fill.svg'
import { ReactComponent as NoItem } from '../../assets/images/no_item.svg'
import { ReactComponent as Size } from '../../assets/images/size.svg'
import { ReactComponent as Property } from '../../assets/images/property.svg'
import { ReactComponent as Bed } from '../../assets/images/bed.svg'
import { ReactComponent as Bathroom } from '../../assets/images/bathroom.svg'
import axios from 'axios'
import NumberFormat from 'react-number-format';
import { Redirect, Link } from 'react-router-dom'
import { APIURL } from './../constants/common';
import { Spinner } from 'reactstrap'

export default class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stylePath: true,
      propertyDetailsData: [],
      propertyImages: [],
      propertyCheck: [],
      requestPriceDetails: [],
      activePage: 1,
      limit: 0,
      totalItemsCount: 0,
      user: JSON.parse(localStorage.getItem("userData")),
      user_type: localStorage.getItem("user_type"),
      token: JSON.parse(localStorage.getItem("token")),
    }
  }

  handlePageChange(e,pageNumber) {
      this.props.handlePropsPageChange(e)
  }

  handelLike(list){
   if(this.state.user!=null && this.state.user.user_type=="Buyer")
   {
        const formData = new FormData();
        formData.append('property_id', list.id);
        var token = this.state.token
        if(list.wishlist==false)
        {
            var app_url = APIURL+"buyer/property-wishlist-add"
        }
        else
        {
           var app_url = APIURL+"buyer/property-wishlist-remove"
        }
       
        axios
            .post(app_url, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                 this.props.getPropertyList(0)
            })
            .catch((error) => {
                this.setState({
                    // errMsg: error.response.data.errors,
                    Loader: false
                })
            });
    }
       
  }

  slug(name)
  {
   if(name == undefined)
    {
      return "undefined";
    }
    else
    {
      return name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '')
    }
  }


  render() {

    return (
      <div>
        <div class="row">
        {this.props.propertyList.length > 0 && this.props.propertyList.map((item, idx) => (  
          <div class="col-sm-6">
            <article class="property">
              <div class="align-items-center">
                
                  <div class="position-relative">
                   {this.state.user==null  &&
                    <Link class="property__like" to="/signin"><Heart class="img-svg" alt="property" /></Link>
                   }
                   { this.state.user!=null &&  this.state.user.user_type=="Buyer" &&
                    <a href="javascript:;" class="property__like" onClick={()=>this.handelLike(item)} >
                    { item.wishlist== true?
                       <HeartRed class="img-svg favourite"  alt="property" />
                      :<Heart class="img-svg"  alt="property" />
                    }
                    </a>
                   }
                    
                    <div class="property__img">
                      {/* <div class="property_verified gradient2"><span>verified</span><small>Premium</small></div> */}
                      {	item.property_image.length > 0
                        ? <img src={item.property_image[0].url_path} class="animate" alt="property" />
                        : <img src={Image1} class="animate" alt="property" />
                      }
                      {item.verification_banner === "yes" && 
                        <div class="property_verified gradient2"><span>verified</span><small>Premium</small></div>
                      }
                    </div>
                  </div>
                
                
                  <div class="property__cnt">
                    <div>
                      <small>{item.property_features.purpose}</small>
                      <h3>{item.name}</h3>
                    </div>
                    <h4 class="price">
                      <span>{
                          item.property_features.denomination === "Dollar" ?
                          "$"
                          : 
                          item.property_features.denomination === "Naira" ?
                          "₦"
                          :
                          item.property_features.denomination === "Pounds" ?
                          "£"
                          : ""
                      }
                      <NumberFormat
                        thousandsGroupStyle="thousand"
                        value={Math.round(item.property_features.listing_price)}
                        displayType="text"
                        type="text"
                        thousandSeparator={true}
                      /></span></h4>
                  </div>
                  <div class="property__features">
                    <ul>
                      <li data-bs-toggle="tooltip" title="Location"><GeoAlt class="img-svg" alt="" /><span class="location">{item.property_address.city_name}</span> </li>
                      <li data-bs-toggle="tooltip" title="Area"><Size class="img-svg" alt="" /> <span class="size">{item.property_features.size} m<sup>2</sup></span> </li>
                      <li data-bs-toggle="tooltip" title="Property Type"><Property class="img-svg" alt="" /> <span class="type">{item.categoryArray.category_name}</span> </li>
                      <li data-bs-toggle="tooltip" title="bedRooms"><Bed class="img-svg" alt="" /> <span class="bed">{item.property_features.total_bedrooms}</span> </li>
                      <li data-bs-toggle="tooltip" title="Bathroom"><Bathroom class="img-svg" alt="" /> <span class="bathroom">{item.property_features.total_bathrooms}</span> </li>
                      <li><Link to={"/realestateproperty/" + this.slug(item.name) + "/" + this.slug(item.property_address.city_name) + "/" + this.slug(item.property_address.state_name) + "/" + item.id} className="btn btn-primary btn-sm">View Details</Link> </li>
                    </ul> 
                  </div>
                
              </div>
            </article>
          </div>
        ))}
        {this.props.propertyList.length === 0 && this.props.norecord == true &&
          <div class="col-sm-12 text-center">
            <div className='no-record-found'><NoItem className="img-svg" alt="home" /><h4>No Property Found</h4></div>
          </div>
        }
        </div>
        <div class="text-center mt-4">
          {/* <a href="#" class="btn btn-secondary">View More Property</a> */}
            {this.props.totalItemsCount>0 && 
                <div class="justify-content-center d-flex">
                <Pagination
                    activePage={this.props.activePage}
                    itemsCountPerPage={this.props.limit}
                    totalItemsCount={this.props.totalItemsCount}
                    pageRangeDisplayed={5}
                    onChange={(e)=>this.handlePageChange(e)}
                    itemClass="page-item"
                    linkClass="page-link"
                    innerClass="pagination justify-content-center"
                    activeLinkClass="active"
                    nextPageText="Next"
                    prevPageText="Previous"
                />
                </div>
            }
        </div>  
      </div>
    )
  }
}
