import React, { Component } from 'react'

export default class Header extends Component {
    render() {
        return (
            <>
                 <div class="page-title">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <h2 class="ipt-title">Welcome!</h2>
                                    <span class="ipn-subtitle">Welcome To Your Account</span>
                                </div>
                            </div>
                        </div>
                    </div>
            </>
        )
    }
}
