import React, { Component } from 'react'
import { Redirect } from 'react-router';
import Sidebar from './Sidebar'
import axios from "axios";
import { APIURL } from '../../../components/constants/common';
import Navbar from './Navbar'
import Button from '@restart/ui/esm/Button';
import { Spinner } from 'reactstrap'

export default class ChangePassword extends Component {
    constructor() {
        super();
        this.state = {
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            user_type: localStorage.getItem("user_type"),
            navigate: false,
            Loader: false,
            redirect: false,
            errMsg: "",
            scsMsg: "",
            seller_data: [],
            property_count_to_pay: "",
            amount: "",
            count_error: ""
        }
    }

    componentDidMount() {
        this.getSellerInfo(this.props.match.params.id)
    }

    handlePropertyCount(e) {
        this.setState({
            property_count_to_pay: e.target.value
        })
    };

    handleCommissionAmount(e) {
        this.setState({
            amount: e.target.value
        })
    };

    getSellerInfo(id) {
        if (this.state.user) {
            const formData = new FormData();
            formData.append('seller_id', id);
            var token = this.state.token
            var app_url = APIURL+"admin/view-remaining-property-for-commission"
            axios
                .post(app_url, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    this.setState({
                        seller_data: response.data.data,
                    })

                })
                .catch((error) => {
                    this.setState({
                        // errMsg: error.response.data.errors,
                        Loader: false
                    })
                });
        }
    }

    onSubmitHandler = (property_count) => {
            var token = this.state.token
            const formData = new FormData();
            formData.append('seller_id', this.props.match.params.id);
            formData.append('commission_pay', this.state.amount);
            formData.append('property_count_to_pay', property_count);
            this.setState({ Loader: true });
            axios
                .post(APIURL + "admin/send-commission-to-seller", formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    this.setState({
                        Loader: false,
                        scsMsg: response.data.message
                    });
                    setTimeout(() => this.setState({
                        scsMsg: "",
                        redirect: true
                    }), 3000);
                })
                .catch((error) => {
                    this.setState({
                        errMsg: error.response.data.error,
                        Loader: false
                    })
                    setTimeout(() => this.setState({
                        errMsg: "",
                    }), 3000);
                });
        
    };  


    render() {
         if (!this.state.user) {
            return <Redirect to="/signin" />;
        }

        if (this.state.redirect) {
            return <Redirect to="/admin/commission-alert-list" push={true} />;
        }
        return (
           <div className="admin">
           
             {this.state.Loader ? <div className="loader"> <Spinner type="grow" color="dark" style={{ width: '3rem', height: '3rem' }} /> </div> : ""}


               <Sidebar activePage="change_password" />
                <div class="content-area">
                 <Navbar activePage="commission-alert-edit"/>

                  <div class="content">
                    <div class="container">
                      <div class="card">
                        <div class="card-body">
                          <div class="px-xxl-4 py-4 px-3">
                            <div>
                                <div>
                                    <h2 id="page_headeing"><span>Commission Alert</span></h2>
                                    <hr />
                                {this.state.scsMsg ? <div class="alert alert-success" role="alert"> {this.state.scsMsg} </div> : ""}
                                {this.state.errMsg.message ? <div class="alert alert-danger" role="alert">  {this.state.errMsg.message}   </div> : ""}
                                </div>
                                { this.state.seller_data.length > 0 && this.state.seller_data.map( (item, index) => (
                                <form>
                                    <div class="row">
                                        <div class="col-md-6 col-sm-8 mb-4">
                                            <label for="Seller_name" class="form-label">Seller Name</label>
                                            <div class="icon-input">
                                                <input
                                                    className="form-control"
                                                    required=""
                                                    type = "text"
                                                    name="seller_name"
                                                    placeholder="Seller Name"
                                                    value={item.seller_name}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-8 mb-5">
                                            <label for="prop_count" class="form-label">Property Count</label>
                                            <div class="icon-input">
                                                <input
                                                    className="form-control"
                                                    required=""
                                                    type="number"
                                                    name="prop_count"
                                                    placeholder="Property Count"
                                                    value = {item.seller_verify_property}
                                                    disabled
                                                />
                                                {/* <span className="text-danger">{this.state.errMsg.confirm_password}</span> */}
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row">
                                        <div class="col-md-6 col-sm-8 mb-4">
                                            <label for="property_count" class="form-label">Verification Count</label>
                                            <div class="icon-input">
                                                <input
                                                    className="form-control"
                                                    required=""
                                                    type="number"
                                                    name="property_count_to_pay"
                                                    placeholder="Verification Count"
                                                    value = {(item.property_count - item.total_given_property_commission_count)}
                                                    disabled
                                                />                                      
                                                {/* <span className="text-danger">{this.state.count_error}</span> */}
                                            </div>
                                        </div>                                
                                        <div class="col-md-6 col-sm-8 mb-5">
                                            <label for="exampleInputPassword1" class="form-label">Commission Amount (£)</label>
                                            <div class="icon-input">
                                                <input
                                                    className="form-control"
                                                    required=""
                                                    type="number"
                                                    name="amount"
                                                    placeholder="Commission Amount"
                                                    min="0"
                                                    onChange={(e)=>this.handleCommissionAmount(e)}
                                                />
                                                {/* <span className="text-danger">{this.state.errMsg.confirm_password}</span> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                    <button type="button" onClick={() => this.onSubmitHandler(item.property_count - item.total_given_property_commission_count)} class="btn btn-primary">Save</button>
                                    </div>
                                </form>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                          
            </div>
        )
    }
}