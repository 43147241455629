import React, { Component } from 'react'
import { Navigation, Pagination, Autoplay} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
  // import Swiper and modules styles
import 'swiper/swiper-bundle.css';
import  Profile1   from '../../assets/images/profile-1.webp'
import  Profile2   from '../../assets/images/profile-2.webp'
import  Profile3   from '../../assets/images/profile-3.webp'



export default class ReviewsByCustomers extends Component {
	constructor(){
		super();
		this.state = {
			stylePath:true
		}
	}

	componentDidMount(){
	}

	render() {
		return (
		<div>					
				<section id="testimonial" class="burger pb-5">
					<div class="container">
						<header class="section_heading mb-1">
						<div class="row">
							<div class="col-lg-6 col-md-8 col-sm-10 m-auto">
							<h2>Good Reviews By Customers</h2>
							{/* <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores</p> */}
							</div>
						</div>
						</header>
					
				<Swiper
					slidesPerView = {1}
					spaceBetween = {30}
					loop= {true}
					centeredSlides= {true}
					autoplay= {{
					  delay: 5000,
					  disableOnInteraction: false,
					}}
					modules={[Autoplay, Pagination]}
					pagination= {{
						clickable: true,
					}}
					breakpoints={{
						768: {
							slidesPerView: 2,
							spaceBetween: 30,
						},
						1024: {
							slidesPerView: 3,
							spaceBetween: 30,
						},
					}}
					className="testimonial_slider"
				>
					<SwiperSlide>
						<div class="testimonial">
							{/* <div class="profile_pic">
							<img src={Profile1} alt="profile" />
							</div> */}
							<div className='para'><p>The team is very detailed and their technical skills comes to play as they help walk you through your options  within the property landscape. They delivered an end to end property deal for my family. Our first property and it was a huge success.</p></div>
							<div class="author">
							<h4>Olumide Ojo</h4>
							{/* <small>Pio Founder</small> */}
							</div>
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div class="testimonial">
							{/* <div class="profile_pic">
							<img src={Profile1} alt="profile" />
							</div> */}
							<div className='para'><p>I Patnered with GENHAVEN in Nigeria to deliver a customer brief of 5 bedroom terraces and we delivered on time and in full. The thing that stood out for me was their Project management capabilities. Their management is first in class and they are always one step ahead.</p></div>
							<div class="author">
							<h4>Ayotunde Oke</h4>
							{/* <small>Pio Founder</small> */}
							</div>
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div class="testimonial">
							{/* <div class="profile_pic">
							<img src={Profile1} alt="profile" />
							</div> */}
							<div className='para'><p>Would recommend GENHAVEN anytime for severally reasons. He is attentive to queries and always willing to resolve issues . Polite and willing to assist regardless. I recall he patiently walked me round a property l bought from him and provided details on the building/construction.
								Interestingly, the team still offers after sales service to me even after a year of purchase and also went ahead to pay for a repair which was done in one of the rooms. I will definitely be calling on them once l am ready to acquire another property.
							</p></div>
							<div class="author ps-5">
							<h4>Mrs Victoria Awoniyi</h4>
							{/* <small>Pio Founder</small> */}
							</div>
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div class="testimonial">
							{/* <div class="profile_pic">
							<img src={Profile1} alt="profile" />
							</div> */}
							<div className='para'><p>Looking for a real estate development company that is tested and trusted? Look no further. GENHAVEN is your only sure bet! There are few businesses who are better than GENHAVEN when it comes to developing and building smart and affordable homes.</p></div>
							<div class="author ps-5">
							<h4>Mrs Kenny Alawode</h4>
							{/* <small>Pio Founder</small> */}
							</div>
						</div>
					</SwiperSlide>
      			</Swiper>
				  </div>
				</section>
			</div>	
		)
	}
}
