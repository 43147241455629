import React, { Component } from 'react'
// import '../frontend_css/'
import logo from '../assets/images/genhaven-white.webp'

import {ReactComponent as Facebook} from '../assets/images/facebook.svg'
import {ReactComponent as Twitter} from '../assets/images/twitter.svg'

import { Link } from 'react-router-dom'
import axios from "axios";
import { Spinner, Button } from 'reactstrap';
import { Redirect } from 'react-router-dom'
import FacebookLogin from 'react-facebook-login';
import { APIURL, BASEURL } from '../components/constants/common';
import Navbar from './Front/TopNavbar'
import Footer from './Front/Footer'
export default class SignIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            errMsgEmail: "",
            errMsgPwd: "",
            msg: "",
            isLoading: false,
            redirect: false,
            errMsg: "",
            scsMsg: "",
            showPassword: false,
            modal: false,
            fullScrLoader: true,
            user: JSON.parse(localStorage.getItem("userData")),
            accountVerified: true,
            scsMsgResend: "",
            ActiveMsg: "",
            stylePath: true,
            checked: false
        }
        
        this.handleCheck = this.handleCheck.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    onChangehandler = (e) => {
        console.log(e)
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);
        console.log(data)
    };

    handleUserName(e) {
        console.log(e)
        this.setState({
            userName: e
        })
        console.log(this.state.userName)
    }

    handlePassword(e) {
        console.log(e)
        this.setState({
            password: e
        })
        console.log(this.state.password)
    }

    handleCheck(e){
        // console.log(e.target.checked, this.state)
        this.setState({
          checked: e.target.checked,
        })
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ isLoading: true });
        const check = this.state.checked;
        this.lsRememberMe(check);
        axios
            .post(APIURL + "login", {
                email: this.state.email,
                password: this.state.password,
            })
            .then((response) => {
                this.setState({
                    isLoading: false,

                })
                localStorage.setItem("isLoggedIn", true);
                localStorage.setItem("userData", JSON.stringify(response.data.user));
                localStorage.setItem("token", JSON.stringify(response.data.token));
                this.setState({
                    redirect: true,
                });
            })
            .catch((error) => {
                this.setState({
                    isLoading: false,
                    accountVerified: error.response.data.accountVerified,
                    errMsg: error.response.data.error,
                })
                setTimeout(() => this.setState({ errMsg: "" }), 4000);
            });
    }

    showPassword() {
        this.setState({
            showPassword: !this.state.showPassword
        })
    }


    resendActivationMail = () => {
        axios
            .post(APIURL + "resend/email", {
                email: this.state.email,
            })
            .then((response) => {
                this.setState({
                    scsMsgResend: response.data.message,
                    errMsg: ""
                })
            });
    }


    ActivationMsg = () => {
        const params = this.props.match.params
        // console.log(params.id)
        if (params.token) {
            axios
                .get(APIURL + "email/verify/" + params.token)
                .then((response) => {

                    this.setState({
                        ActivationMsg: response.data.message
                    })
                    setTimeout(() => this.setState({ ActivationMsg: false }), 4000);

                })
                .catch((error) => {
                    this.setState({
                        errMsg: error.response.data.error,
                    })

                });
        }
    }

    responseFacebook = (response) => {
        axios
            .post(APIURL + "facebook-login", {
                name: "test_user_4",
                email: "test_user_4@mailinator.com",
            })
            .then((response) => {
                this.setState({
                    isLoading: false,

                })
                localStorage.setItem("isLoggedIn", true);
                localStorage.setItem("userData", JSON.stringify(response.data.user));
                localStorage.setItem("token", JSON.stringify(response.data.token));
                this.setState({
                    redirect: true,
                });
            })
            .catch((error) => {
                // this.setState({
                //     isLoading: false,
                //     accountVerified:error.response.data.accountVerified,
                //     errMsg: error.response.data.error,
                // })
                // setTimeout(() => this.setState({ errMsg: "" }), 4000);
            });
    }

    lsRememberMe(check) {
        // if (rmCheck.checked && emailInput.value !== "") {
        if (check) {
          localStorage.username = this.state.email;
          localStorage.password = this.state.password;
          localStorage.checkbox = true;
          // localStorage.checkbox = rmCheck.value;
        } else {
          localStorage.username = "";
          localStorage.password = "";
          localStorage.checkbox = false;
        }
      }

    componentDidMount() {
        this.ActivationMsg()
        setTimeout(() => this.setState({ fullScrLoader: false }), 500);

        if (localStorage.checkbox && localStorage.checkbox !== "") {
            // rmCheck.setAttribute("checked", "checked");
            // emailInput.value = localStorage.username;
            // passwordInput.value = localStorage.password;
             this.setState({
               checked: true,
               email: localStorage.username,
               password: localStorage.password
             });
           } else {
            // rmCheck.removeAttribute("checked");
            // emailInput.value = "";
          //   passwordInput.value = "";
             this.setState({
               checked: false,
               username: '',
               password: ''
             });
           }
    }


    render() {
        console.log(this.props)
        const { user } = this.state;
        if (this.state.redirect) {
            return <Redirect to="/checkuser" />;
        }
        return (
            <div className="resido-front">
                {/* Navbar cmp */}
                <Navbar />
                {/* header cmp */}
                {this.state.errMsg.message ? <div class="alert alert-danger" role="alert">  {this.state.errMsg.message}   </div> : ""}
                {this.state.fullScrLoader ? <div className="loader"> <Spinner type="grow" color="dark" style={{ width: '3rem', height: '3rem' }} /> </div> : ""}
                <div class="content-area">
                    <div class="authentication">
                        <div class="container">
                            <div class="row g-0">
                                <div class="col-sm-12">
                                    <div class="authentication__content">
                                        <div class="row g-0 align-self-center">
                                            <div class="col-md-6">
                                                <div class="mx_h-600">
                                                    <div class="px-5">
                                                        <h1>Login</h1>
                                                        {this.state.errMsg.message ? <div class="alert alert-danger" role="alert">
                                                            {this.state.errMsg.message}<br />
                                                            {this.state.accountVerified ? "" :
                                                                <a className="text-secondary font-weight-bold " onClick={this.resendActivationMail} href="javascript:;">Resend Verification Link</a>
                                                            }
                                                        </div> : ""}

                                                        {/* success msg */}
                                                        {this.state.scsMsgResend ?
                                                            <div class="alert alert-success" role="alert">
                                                                {this.state.scsMsgResend}
                                                            </div> : ""
                                                        }

                                                        {/* activtion msg */}
                                                        {this.state.ActivationMsg ?
                                                            <div class="alert alert-success" role="alert">
                                                                {this.state.ActivationMsg}
                                                            </div> : ""
                                                        }
                                                        {/* <span className="text-danger"></span> */}

                                                        <form onSubmit={(e) => this.handleSubmit(e)}>

                                                            <div class="mb-4">
                                                                <input
                                                                    autoFocus={true}
                                                                    className="form-control"
                                                                    required=""
                                                                    type="text"
                                                                    name="email"
                                                                    id="exampleInputEmail1"
                                                                    placeholder="Enter your email Id"
                                                                    value={this.state.email}
                                                                    onChange={this.onChangehandler}
                                                                />
                                                                <i className="ti-user"></i>
                                                                <span className="text-danger">{this.state.errMsg.email}</span>
                                                            </div>


                                                            <div class="mb-4">
                                                            <div class="icon-input">
                                                                <input
                                                                    className="form-control"
                                                                    type={this.state.showPassword ? "text" : "password"}
                                                                    name="password"
                                                                    id="password"
                                                                    placeholder="Password"
                                                                    value={this.state.password}
                                                                    onChange={this.onChangehandler}
                                                                />
                                                                <i className="ti-unlock"></i>

                                                                {this.state.showPassword ?
                                                                    <span href="" className="input-visiblity"> <i onClick={() => this.showPassword()} className="fas fa-eye-slash"></i> </span>
                                                                    :
                                                                    <span href="" className="input-visiblity"> <i onClick={() => this.showPassword()} className="fas fa-eye"></i> </span>
                                                                }

                                                                {/* {this.state.showPassword ?
                                                        <i onClick={() => this.showPassword()} class="fas fa-eye-slash"></i>
                                                        :
                                                        <i onClick={() => this.showPassword()} class="fas fa-eye"></i>
                                                    } */}
                                                                <span className="text-danger">{this.state.errMsg.password}</span>
                                                            </div>
                                                            </div>
                                                            <div class="mb-4 d-flex justify-content-between">
                                                                <label class="customcheck">
                                                                    <input  
                                                                        type="checkbox" 
                                                                        value="lsRememberMe" 
                                                                        id="rememberMe" 
                                                                        onChange={this.handleCheck}
                                                                        checked={this.state.checked}
                                                                    /><span class="checkmark"></span>
                                                                    Remember Me
                                                                </label>
                                                                <div class="">
                                                                    <Link to="/forgot/password" className="link">Forgot password</Link>

                                                                </div>
                                                            </div>
                                                            <div class="d-flex justify-content-between align-items-center">
                                                                <div class="text-right">
                                                                    <Link to="/signup" className="link">New Account</Link>

                                                                </div>
                                                                <div class="text-right">
                                                                    <Button
                                                                        type="submit"
                                                                        className="btn btn-primary"
                                                                    >
                                                                        Login
                                                                        {this.state.isLoading ? (
                                                                            <span
                                                                                className="spinner-border spinner-border-sm ml-2"
                                                                                role="status"
                                                                                aria-hidden="true"
                                                                            ></span>
                                                                        ) : (
                                                                            <span></span>
                                                                        )}
                                                                    </Button>

                                                                </div>
                                                            </div>

                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 order-md-first">
                                                <div class="px-5 authentication__withsocial gradient1">
                                                    <div class="content">
                                                        <a href="#"><img loading="lazy" src={logo} class="brand-logo" alt="genhaven Logo" /></a>
                                                        <h2>Glad to See You!</h2>
                                                        <p>Login with your Username and Password. New users are to register an account by clicking on “New Account” tab.</p>
                                                        <div>
                                                            <h5>Login With Social Media</h5>
                                                            <div>
                                                                {/* <FacebookLogin
                                                                appId="440232514298290"
                                                                autoLoad={true}
                                                                fields="name,email,picture"
                                                                callback={this.responseFacebook}
                                                                 /> */}
                                                                <a href="#" class="btn btn-facebook" onClick={this.responseFacebook}><Facebook class="img-svg" alt="user" /> <span>Login With Facebook</span></a>
                                                                {/* <a href="#" class="btn btn-facebook"><Facebook class="img-svg" alt="user" /> <span>Login With Facebook</span></a> 
                                                                <a href="#" class="btn btn-twitter"><Twitter class="img-svg" alt="user" /> <span>Login With Twitter</span></a>*/}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <Footer />

            </div>
        )
    }
}
