import React, { Component } from 'react'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import Footer from '../../Front/Footer'
import Header from './Header'
import axios from "axios";
import { ReactComponent as MenuList}  from '../../../assets/images/three-dots-vertical.svg'
import { APIURL } from '../../../components/constants/common';
import { Button ,Spinner } from 'reactstrap'
import { Redirect } from 'react-router'

export default class Index extends Component {
    constructor() {
        super();
        this.state = {
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            user_type: localStorage.getItem("user_type"),
            navigate: false,
            userInfo: {
                current_password: "",
                password: "",
                confirm_password: "",
            },
            errMsg: "",
            scsMsg: "",
            currShowPassword: false,
            newShowPassword: false,
            confirmShowPassword: false,
            mobileView: false
        }
    }

    componentDidMount() {
        console.log(this.state.user_type)
    }

    currShowPassword() {
        this.setState({
            currShowPassword: !this.state.currShowPassword
        })
    }
    newShowPassword() {
        this.setState({
            newShowPassword: !this.state.newShowPassword
        })
    }
    confirmShowPassword() {
        this.setState({
            confirmShowPassword: !this.state.confirmShowPassword
        })
    }

    //form handler
    onChangehandler = (e, key) => {
        const { userInfo } = this.state;
        userInfo[e.target.name] = e.target.value;
        this.setState({ userInfo });
        console.log(userInfo)
    };


    onLogoutHandler = () => {

        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("userData");
        localStorage.clear();
        this.setState({
            navigate: true,
        });
    };

    onSubmitHandler = (e) => {
        var token = this.state.token
        const { userInfo, user } = this.state;
        userInfo['email'] = user.email;
        this.setState({ Loader: true });
        axios
            .post(APIURL + "change-password/update", this.state.userInfo, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                this.setState({ Loader: false  })
                this.setState({
                    scsMsg: response.data.message
                   
                })
               setTimeout(()=>{
                    window.location.reload();
                }, 3000);
            })
            .catch((error) => {
                this.setState({
                    errMsg: error.response.data.error,
                    Loader: false
                  
                })
               
            });
    };

    toggleView() {
		this.setState({
			mobileView: !this.state.mobileView
		})
	}

    render() {
          if (!this.state.user) {
            return <Redirect to="/signin" />;
        }
        if(this.state.user.user_type !== "Buyer"){
            return <Redirect to="/permission" />;
        }
        
        return (
            <>
             {this.state.Loader ? <div className="loader"> <Spinner type="grow" color="dark" style={{ width: '3rem', height: '3rem' }} /> </div> : ""}
              
                <Navbar />
                <div class="content-area">
                        <div class="breadcrumb-area gradient1">
                            <div class="container">
                                <h1>My Account</h1>
                                <div><small>Welcome To Your Account</small></div>
                         
                            </div>
                        </div>
                        <div className={this.state.mobileView ? "sidebar_alpha open" : "sidebar_alpha"}></div>
                        <div class="myaccount">
                            <div className='more-menu' onClick={()=>this.toggleView()}><MenuList class="img-svg" alt="menu-list"/></div>
                            <div class="container">
                                <div class="row">
                                    <div class="col-xxl-3 col-lg-4">
                                        <div className={this.state.mobileView ? "bg-light h-100 sidebar_mobile left open" : "bg-light h-100 sidebar_mobile left"}>
                                            <div class="px-xxl-4 py-4 px-3">
                                                <Sidebar activePage="changepassword" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xxl-9 col-lg-8">
                                        <div class="bg-light">
                                            <div class="px-xxl-4 py-4 px-3">
                                                <div>
                                                    <div>
                                                      <h2 id="page_headeing"><span>Change Password</span></h2>
                                                      <hr />
                                                    </div>

                                                    {this.state.scsMsg ? <div class="alert alert-success" role="alert"> {this.state.scsMsg} </div> : ""}
                                                    {/* messgae err */}
                                                    {this.state.errMsg.message ? <div class="alert alert-danger" role="alert">  {this.state.errMsg.message}   </div> : ""}
                                                    <form>
                                                        <div class="row">
                                                            <div class="col-md-6 col-sm-6 mb-4">
                                                                <label>Current Password</label>
                                                                <div class="icon-input">
                                                                    <input
                                                                        className="form-control"
                                                                        required=""
                                                                        type={this.state.currShowPassword ? "text" : "password"}
                                                                        name="current_password"
                                                                        placeholder="Current Password"
                                                                        value={this.state.current_password}
                                                                        onChange={this.onChangehandler}
                                                                    />
                                                                    {this.state.currShowPassword ?
                                                                        <span className="ad-fa-eye-pass input-visiblity"> <i onClick={() => this.currShowPassword()} className="fas fa-eye-slash"></i> </span>
                                                                        :
                                                                        <span className="ad-fa-eye-pass input-visiblity"> <i onClick={() => this.currShowPassword()} className="fas fa-eye"></i> </span>
                                                                    }
                                                                    <span className="text-danger">{this.state.errMsg.current_password}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div class="row">
                                                            <div class="col-md-6 col-sm-6 mb-4">
                                                                <label>New Password</label>
                                                                <div class="icon-input">
                                                                    <input
                                                                        className="form-control"
                                                                        required=""
                                                                        type={this.state.newShowPassword ? "text" : "password"}
                                                                        name="password"
                                                                        placeholder="New Password"
                                                                        value={this.state.password}
                                                                        onChange={this.onChangehandler}
                                                                    />
                                                                    {this.state.newShowPassword ?
                                                                        <span className="ad-fa-eye-pass input-visiblity"> <i onClick={() => this.newShowPassword()} className="fas fa-eye-slash"></i> </span>
                                                                        :
                                                                        <span className="ad-fa-eye-pass input-visiblity"> <i onClick={() => this.newShowPassword()} className="fas fa-eye"></i> </span>
                                                                    }
                                                                    <span className="text-danger">{this.state.errMsg.password}</span>
                                                                </div>
                                                            </div>
                                                            
                                                            <div class="col-md-6 col-sm-6 mb-5">
                                                                <label>Confirm Password</label>
                                                                <div class="icon-input">
                                                                    <input
                                                                        className="form-control"
                                                                        required=""
                                                                        type={this.state.confirmShowPassword ? "text" : "password"}
                                                                        name="confirm_password"
                                                                        placeholder="Confirm Password"
                                                                        value={this.state.confirm_password}
                                                                        onChange={this.onChangehandler}
                                                                    />
                                                                    {this.state.confirmShowPassword ?
                                                                        <span className="ad-fa-eye-pass input-visiblity"> <i onClick={() => this.confirmShowPassword()} className="fas fa-eye-slash"></i> </span>
                                                                        :
                                                                        <span className="ad-fa-eye-pass input-visiblity"> <i onClick={() => this.confirmShowPassword()} className="fas fa-eye"></i> </span>
                                                                    }
                                                                    <span className="text-danger">{this.state.errMsg.confirm_password}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class=" col-lg-12 col-md-12">
                                                            <Button
                                                                color="primary"
                                                                className="btn btn-theme-light-2 rounded"
                                                                onClick={() => this.onSubmitHandler()}
                                                            >Save Changes</Button>
                                                        </div>                                                           
                                                    </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}

