
import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import { Modal, Spinner, ModalBody, ModalHeader, ModalFooter, Button, Input } from 'reactstrap'
import { APIURL } from '../../constants/common';
import axios from 'axios'
import Pagination from "react-js-pagination";
import dateFormat, { masks } from "dateformat";
import { ReactComponent as EditPencil} from '../../../assets/images/pencil-fill.svg';
import { ReactComponent as CommentIcon} from '../../../assets/images/comment.svg';
import { ReactComponent as FileIcon} from '../../../assets/images/file-text.svg';
import { ReactComponent as DropDown} from '../../../assets/images/three-dots-vertical.svg';

const colourStyles = {

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isFocused ? "#B8BCBD" : null,
            color: "grey",
        };
    },
};

export default class Property extends Component {
    constructor() {
        super();

        this.state = {
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            errMsg:[],
            propertyName: "",
            AddpropertyModal: false,
            propertyList: [],
            statusArray: [{ 'value': "", "label": "Property Status" },{ 'value': "pending", "label": "Pending" }, { 'value': "under_review", "label": "Under Review" }, { 'value': "verify", "label": "Verified" }, { 'value': "reject", "label": "Reject" }],
            statusDefault: [{ 'value': "", "label": "Property Status" }],
            property_id: "",
            activePage: 1,
            limit: 0,
            totalItemsCount: 0,
            search: "",
            status: [],
            sort: false,
            sortby: "DESC",
            columnName: "",
            color: 'white',
            availableCategories: [],
            selectedCategory: "",
            commentModal: false,
            adminComment: "",
            Loader:false,
            norecord:false
        }
        this.SortBy = this.SortBy.bind(this);
    }

    listenScrollEvent = e => {
        if (window.scrollY > 400) {
            this.setState({ color: 'black' })
        } else {
            this.setState({ color: 'white' })
        }
    }

    SortBy(e) {
        this.setState({
            sort: !this.state.sort
        }, () => {
            if (this.state.sort) {
                this.setState({
                    sortby: "ASC",
                    columnName: e
                }, () => {
                    this.getPropertyList()
                })

            }
            if (!this.state.sort) {
                this.setState({
                    sortby: "DESC",
                    columnName: e
                }, () => {
                    this.getPropertyList()
                })
            }
        })
    }

    handleSearch(e) {
        this.setState(
            { search: e.target.value, activePage: 1 }
            , () => { this.getPropertyList() });
    }

    handleStatus(e) {
        this.setState(
            {
                status: e,
                activePage: 1 
            }
            , () => { this.getPropertyList() });
    }

    handlePageChange(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        this.setState(
            { activePage: pageNumber }
            , () => {
                this.getPropertyList()
            }
        );
    }
    // Name handler
    handlechange(e) {
        this.setState({
            propertyName: e
        })
    }

    handleSelectedCategory(e) {
        this.setState({
            selectedCategory: e,
        })
    }

    commentModal(adminComment) {
        this.setState({
            commentModal: !this.state.commentModal,
            adminComment: adminComment
        })
    }

    commentModalClose() {
        this.setState({
            commentModal: false
        })
    }

    getCategoryList() {
        var token = this.state.token;

        axios
            .post(APIURL + "get-category-list", null, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
               // console.log(response.data.data);
                this.setState({
                    availableCategories: response.data.data,
                })
            })
    }

    getPropertyList() {
         this.setState({
                    Loader:true
                })
        var token = this.state.token
        const formData = new FormData();
        formData.append('page', this.state.activePage);
        formData.append('search', this.state.search);
        formData.append('statusfilter', this.state.status);
        // formData.append('sortby', this.state.sortby);
        formData.append('limit', this.state.limit);
        //formData.append('sorttype', this.state.columnName);

        axios
            .post(APIURL + "seller/get-property-list", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                //console.log("seller/get-properties", response.data.data)
                this.setState({
                    propertyList: response.data.data.data,
                    activePage: response.data.data.current_page,
                    totalItemsCount: response.data.data.total,
                    limit: response.data.data.per_page,
                    norecord:true,
                    Loader:false
                })
            })
             .catch((error) => {
                this.setState({
                    norecord:true,
                    Loader:false
                })
            });
    }

    addProperty = (e) => {
        var token = this.state.token
        const { userInfo, user } = this.state;
        const formData = new FormData();
        formData.append('name', this.state.propertyName);
        formData.append('category_id', this.state.selectedCategory)
        this.setState({ Loader: true });
        axios
            .post(APIURL + "seller/property-add", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                //console.log("property-add", response)
                localStorage.setItem("property_id", response.data.data.id);
                this.setState({
                    scsMsg: response.data.message,
                    property_id: response.data.data.id,
                    Loader: false,
                })

                setTimeout(() => {
                    this.setState({
                        redirect: true
                    })
                }, 3000);
            })
            .catch((error) => {
                this.setState({
                    errMsg: error.response.data.error,
                    Loader: false
                })
            });
    };
    
    addPropertyModal() {
        this.setState({
            AddpropertyModal: !this.state.AddpropertyModal
        })
    }
    // AddpropertyModal close
    ModalClose() {
        this.setState({
            AddpropertyModal: false
        })
    }

    componentDidMount() {
        this.getPropertyList();
        this.getCategoryList();
    }

    render() {
        if (!this.state.user) {
            return <Redirect to="/signin" />;
        }
        if (this.state.redirect) {
            return <Redirect to={"/seller/add-property/" + this.state.property_id} />;
        }
        return (
            <div className="admin">
                <Sidebar activePage="property-listing" />
                <div class="content-area">
                    {this.state.Loader ? <div className="loader"> <Spinner type="grow" color="dark" style={{ width: '3rem', height: '3rem' }} /> </div> : ""}
                <Navbar activePage="property-listing"/>   
                    <div class="content">
                        <div className="mb-4 d-flex justify-content-between">
                            <Button onClick={() => this.addPropertyModal()} color="info" className="px-3 btn btn-primary btn-sm">Add Property</Button>
                        </div>
                        <div class="card">
                        <div class="card-body">
                            <div class="d-sm-flex justify-content-between align-items-center">
                                <h2 id="page_headeing"><span>My Properties</span></h2>
                                <div class="row align-items-end">
                                    <div class="col-6">
                                        <Input 
                                            type="text" 
                                            onChange={(e) => this.handleSearch(e)} 
                                            placeholder="Search"  
                                            className="form-control form-control_new border-0 border-end-0"
                                        />
                                    </div>
                                    <div class="col-6">
                                        <div className="small">Status</div>
                                        <select onChange={(e) => this.handleStatus(e.target.value)} className="form-control form-control_new border-0">
                                            <option value="">All</option>
                                            <option value="under_review">Under Review</option>
                                            <option value="pending">Pending</option>
                                            <option value="verify">Published</option>
                                            <option value="reject">Rejected</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div>
                            <div class="table-responsive">
                                <table class="table table-striped table-borderless">
                                <thead>
                                    <tr class="border-bottom">
                                        <th style={{minWidth:70}}>Sr. No.</th>
                                        <th style={{minWidth:120}}>Property Name</th>
                                        <th style={{minWidth:100}}>Created Date</th>
                                        <th style={{minWidth:100}}>Property Type</th>
                                        <th style={{minWidth:200}}>Transferrable Verification Code</th>
                                        <th>Status</th>
                                        <th width="70px">Action</th>                                    
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.propertyList.length > 0 && this.state.propertyList.map((item, idx) => (
                                    <tr>
                                        <td>{idx+1}</td>
                                        <td>{item.name}</td>
                                        <td>{dateFormat(item.created_at, "mmm d, yyyy")}</td>
                                        <td>{item.categoryArray.category_name}</td>
                                        <td>
                                            {   item.coupon_code !== null && item.code_used === "1"
                                                ? <span class="badge bg-light text-dark">{item.coupon_code}</span>
                                                : item.coupon_code !== null && item.code_used === "0"
                                                ? <span class="badge bg-primary">{item.coupon_code}</span>
                                                : ""
                                            }
                                        </td>
                                        <td>
                                        {
                                            item.property_request_status === "verify" ?
                                                <span class="badge bg-success">Published</span>
                                            :
                                            item.property_request_status === "under_review" ?
                                                <span class="badge bg-warning">Under Review</span>
                                            : 
                                                item.property_request_status === "reject" ?
                                                <span class="badge bg-danger">Rejected</span>
                                            :
                                                <span class="badge bg-info">Pending</span>
                                        }
                                        </td>
                                        
                                        <td>
                                        <div class="navbar no_navbar text-center py-0">
                                            <div class="dropdown">
                                                <a class="dropdown-toggle btn-rounded btn btn-default" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <DropDown class="img-svg" />
                                                </a>

                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                            {
                                                item.admin_comment !== null && item.hasOwnProperty('admin_comment')
                                                ? <div className='mb-2'><button className="btn mb-0 btn-rounded btn-default" onClick={() => this.commentModal(item.admin_comment)}><CommentIcon class="img-svg" /></button><span>Comment</span></div>
                                                : ""
                                            }
                                            {
                                                item.property_request_status === "verify" && item.assign_status === "verify"
                                                ? <div className='mb-2'><a href={"/common/property-certificate/" + item.id + "/" + item.request_id} target="_blank" className="btn mb-0 btn-rounded btn-default ms-0"><FileIcon class="img-svg" /></a><span> Certificate</span></div>
                                                : ""
                                            }                                    
                                            <div><Link to={"/seller/add-property/" + item.id} className="btn mb-0 btn-rounded btn-info"><EditPencil class="img-svg" /></Link><span>Edit</span></div>
                                            </div>
                                            </div>
                                        </div>
                                        </td>                                       
                                    </tr>
                                ))}

                                 {this.state.propertyList.length === 0 && this.state.norecord==true && 
                                    <tr className="text-center">
                                        <td colspan="7">No record found</td>
                                    </tr>
                                }
                                </tbody>
                                </table>
                            </div>
                            <div class="mt-4 text-center">
                                {this.state.totalItemsCount>0 && 
                                    <div class="justify-content-center d-flex">
                                    <Pagination
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={this.state.limit}
                                        totalItemsCount={this.state.totalItemsCount}
                                        pageRangeDisplayed={5}
                                        onChange={this.handlePageChange.bind(this)}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        innerClass="pagination justify-content-center"
                                        activeLinkClass="active"
                                        nextPageText="Next"
                                        prevPageText="Previous"
                                    />
                                    </div>
                                }
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>


                <Modal size="md" isOpen={this.state.AddpropertyModal} toggle={() => this.ModalClose()} autoFocus={false}>
                    <ModalHeader toggle={() => this.ModalClose()}>Add Property</ModalHeader>
                    <ModalBody>
                        {this.state.scsMsg ?
                            <div className="text-success">
                                {this.state.scsMsg}
                            </div>
                            : ""}
                        <div className="mb-4">
                                    <label>Property Name</label>
                                    <Input
                                        type="text"
                                        className="form-control "
                                        placeholder="Property Name"
                                        value={this.state.propertyName}
                                        onChange={(e) => this.handlechange(e.target.value)}
                                        autoFocus={true}
                                    />
                                     <span className="text-danger small">{this.state.errMsg.name}</span> 
                        </div>
                        <div className="mb-4">
                                    <label>Property Categotry</label>
                                    <select className="form-control " onChange={(e) => this.handleSelectedCategory(e.target.value)}>
                                        <option value="">Select Property Category</option>
                                    {this.state.availableCategories.length > 0 && this.state.availableCategories.map((category, idx) => (
                                        <option value={category.id}>{category.category_name}</option>
                                    ))}
                                    </select>
                                     <span className="text-danger small">{this.state.errMsg.category_id}</span> 
                               
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                           
                             <button className="btn btn-light me-2" onClick={() => this.ModalClose()}> Close </button>
                             <button className="btn btn-primary" onClick={() => this.addProperty()}> Submit</button>
                        </div>
                    </ModalFooter>
                </Modal>

                <Modal size="md" isOpen={this.state.commentModal} toggle={() => this.commentModalClose()}>
                <ModalHeader className="" toggle={() => this.commentModalClose()}>
                    Comment <br />
                    <span className="text-danger">{this.state.errMsgCmt}</span>
                </ModalHeader>
                <ModalBody className="border-0">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="form-group">
                                <div className="input-with-icon">
                                    <textarea
                                        rows="4"
                                        className="form-control h-auto"
                                        required=""
                                        type="textarea"
                                        name="comment"
                                        value={this.state.adminComment}
                                        disabled
                                    />
                                    {/* <i class="fas fa-address-card"></i> */}
                                </div>
                                {/* <span className="text-danger">{this.state.errMsg.address}</span> */}
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="">
                        <button type="button" className="btn btn-light" onClick={() => this.commentModalClose()}>Close</button>
                    </div>
                </ModalFooter>
                </Modal>
            </div>
        )
    }
}
