import React, { Component } from 'react'
import { Button, Spinner, Input} from 'reactstrap'
import axios from "axios";
import { APIURL } from '../../../../components/constants/common';
// import { Helmet } from "react-helmet";
import Map from '../Map/index'
import PlacesAutocomplete, {  geocodeByAddress,   getLatLng, } from 'react-places-autocomplete';

export default class PropertyInfo extends Component {
    constructor() {
        super();

        this.state = {
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            userInfo: {
                streetNumber: "",
                streetName: "",
                aptNumber: "",
                state: "",
                zip: ""
            },
            countryId: "",
            countries_name: [],
            cities_name: [],
            Languages: [],
            Countries: [],
            States: [],
            state_name: [],
            state: "",
            stateId:"",
            name: "",
            image: "",
            selectType: "",
            errMsg: "",
            scsMsg: "",
            city_id: "",
            country_id: "1",
            address: '',
            lat:"",
            long:"",
            propertyStatus:""
        }
    }

    componentDidMount() {
        this.handleCountryState(1)
        this.getPropertyAddressInfo()
    }

    handleChange = address => {
        this.setState({ address });
      };
     
    handleSelect = address => {
        geocodeByAddress(address)
        .then(results => getLatLng(results[0]))
        .then(latLng => 
        this.setState({
            lat:latLng.lat,
            long:latLng.lng,
            address:address
        })
        )
        .catch(error => console.error('Error', error));
    };

    onChangehandler = (e, key) => {
        const { userInfo } = this.state;
        userInfo[e.target.name] = e.target.value;
        this.setState({
            userInfo,
            errMsg: ""
        });
        console.log(userInfo)
    };

    getCities(state_id) {
        axios
            .post(APIURL + "cities", {
                state_id: state_id,
            })
            .then((response) => {
                let cities_name = response.data.states;
                const cityNames = [];
                for (var c = 0; c < cities_name.length; c++) {
                    cityNames.push({ value: cities_name[c].id, label: cities_name[c].name })
                }
                this.setState({
                    cities_name: cityNames,
                })
            })
    }

    getCountries() {
        axios
            .get(APIURL + "countries")
            .then((response) => {
                let countries_name = response.data.countries;
                const CountryNames = [];
                for (var c = 0; c < countries_name.length; c++) {
                    CountryNames.push({ value: countries_name[c].id, label: countries_name[c].name })
                }
                this.setState({
                    Countries: CountryNames,
                })
            })
    }

    handleCountry(e) {
        this.setState({
            countryId: e
        }, () => {
            console.log(this.state.countryId)
            this.handleCountryState(e)
        })
    };

    // get states
    handleCountryState = (id) => {
        axios
            .post(APIURL + "states", {
                country_id: id,
            })
            .then((response) => {
                console.log(response.data)
                let state_name = response.data.states;
                const stateNames = [];
                for (var c = 0; c < state_name.length; c++) {
                    stateNames.push({ value: state_name[c].id, label: state_name[c].name })
                }
                this.setState({
                    States: stateNames,
                })
            })
    };

    // handle states
    handleState(e) {
        this.setState({
            stateId: e
        }, () => {
            this.getCities(e)
        })
    }

    handleCity(e) {
        this.setState({
            city_id: e
        })
    }

    getPropertyAddressInfo() {
        if (this.state.user) {
            const formData = new FormData();
            // formData.append('token', this.state.token);
            formData.append('property_id', this.props.data.property_id);
            var token = this.state.token
            axios
                .post(APIURL + "seller/property-address-edit", formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    const info = response.data.data;
                    this.setState({
                        userInfo: {
                            streetNumber: info.street_number,
                            streetName: info.street_name,
                            aptNumber: info.apt_number,
                            zip: info.zip,
                        },
                        city_id:info.city,
                        countryId:info.country,
                        stateId:info.state,
                        propertyStatus: info.property_request_status 
                    })
                    this.props.data.getStatus(info.property_request_status);
                    if(info.country > 0)
                    {
                        this.handleCountryState(info.country)
                    }

                    if(info.state > 0)
                    {
                        this.getCities(info.state)
                    }

                })
                .catch((error) => {
                    this.setState({
                        errMsg: error.response.data.errors,
                        Loader: false
                    })
                });
        }
    }
    
    updatePropertyAddress = (e) => {
        var token = this.state.token
        const { userInfo ,user } = this.state;
        const formData = new FormData();
        formData.append('street_number', this.state.userInfo.streetNumber ? this.state.userInfo.streetNumber: "");
        formData.append('street_name', this.state.userInfo.streetName ? this.state.userInfo.streetName: "");
        formData.append('apt_number', this.state.userInfo.aptNumber ? this.state.userInfo.aptNumber: "");
        formData.append('state', this.state.stateId ? this.state.stateId: "");
        formData.append('country', "1");
        formData.append('city', this.state.city_id ? this.state.city_id: "");
        formData.append('zip', this.state.userInfo.zip ? this.state.userInfo.zip: "");
        formData.append('property_id', this.props.data.property_id ? this.props.data.property_id: "");
        formData.append('lat', this.state.lat ? this.state.lat: "");
        formData.append('long', this.state.long ? this.state.long: "");
        formData.append('address', this.state.address ? this.state.address: "");

        this.setState({ Loader: true });
        axios
            .post(APIURL + "seller/property-address-update", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                this.setState({ Loader: false });
                this.setState({
                    errMsg: {},
                }, () => {
                    this.props.data.setMessage(response.data.message);
                    this.props.data.handleActiveTab("3");
                    this.props.data.checkPropertyDetails();
                });
            })
            .catch((error) => {
                this.setState({
                    errMsg: error.response.data.error ,
                    Loader: false
                })
            });
    };
    render() {
        
        return (
            <>
            {/* <Helmet>
                    <link rel="stylesheet" href="http://192.168.1.13/custom.css" />
                </Helmet> */}
             {this.state.Loader ? <div className="loader"> <Spinner type="grow" color="dark" style={{ width: '3rem', height: '3rem' }} /> </div> : ""}
                {/* {this.state.errMsg.message ?
                <div class="alert alert-danger text-center" role="alert">
                {this.state.errMsg.message}
               </div>
               :""}  */}
                <div className={this.props.data.activatedTab === "address" ? "tab-pane fade show" : "tab-pane fade"} id="pills-address" role="tabpanel" aria-labelledby="pills-address-tab">
                    <form class="forms-sample ">
                        <h4>Address Info</h4>
                        <hr />
                        <div class="row">
                        <div class="col-sm-6">
                            <div class="mb-4">
                            <label for="streetNumber">Street Number <strong class="text-danger">*</strong> </label>
                            <Input
                                class="form-control"
                                type="number" 
                                min="0" 
                                name="streetNumber" 
                                placeholder="Street Number"
                                value = {this.state.userInfo.streetNumber}
                                onChange = {this.onChangehandler}
                            />
                            { this.state.errMsg.street_number ? <span className="text-danger">{this.state.errMsg.street_number}</span> : ""} 
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="mb-4">
                            <label for="streetName">Street Name </label>
                            <Input 
                                class="form-control" 
                                type="text" 
                                name="streetName" 
                                placeholder="Street Name"
                                value = {this.state.userInfo.streetName}
                                onChange = {this.onChangehandler}
                            />
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="mb-4">
                            <label for="aptNumber">Apartment Number</label>
                            <Input 
                                class="form-control" 
                                type="number" 
                                min="0" 
                                name="aptNumber" 
                                placeholder="Apartment Number"
                                value = {this.state.userInfo.aptNumber}
                                onChange = {this.onChangehandler}
                            />
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="mb-4">
                            <label for="country" class="form-label">Country <strong class="text-danger">*</strong></label>
                            <select className="form-control" value={this.state.countryId} onChange={(e) => this.handleCountry(e.target.value)} disabled>
                                {/* <option value="">Select Country</option>
                                {this.state.Countries.map((option) => (
                                <option value={option.value}>{option.label}</option>
                                ))} */}
                                <option value="1">Nigeria</option>
                            </select>
                            { this.state.errMsg.country ? <span className="text-danger">{this.state.errMsg.country}</span> : ""}
                        </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="mb-4">
                                <label for="state">State <strong class="text-danger">*</strong></label>
                                <select className="form-control" value={this.state.stateId} onChange={(e) => this.handleState(e.target.value)} >
                                    <option value="" >Select State</option>
                                    {this.state.States.map((option) => (
                                    <option value={option.value}>{option.label}</option>
                                    ))}
                                </select>
                                { this.state.errMsg.state ? <span className="text-danger">{this.state.errMsg.state}</span> : ""}
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="mb-4">
                                <label for="city">Local Government </label>
                                <select className="form-control" value={this.state.city_id} onChange={(e) => this.handleCity(e.target.value)} >
                                    <option value="" >Select Local Government</option>
                                    {this.state.cities_name.map((option) => (
                                        <option value={option.value}>{option.label}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="mb-4">
                            <label for="zipCode">Zip Code</label>
                            <Input
                                class="form-control" 
                                type="number" 
                                min="0" 
                                name="zip" 
                                placeholder="Zip Code"
                                value = {this.state.userInfo.zip}
                                onChange = {this.onChangehandler}
                            />
                            </div>
                        </div>
                        
                        </div>
                        <div class="row">
                        <div class="col-12">
                        {
                            this.state.propertyStatus === null  || this.state.propertyStatus  === "verify" || this.state.propertyStatus  === "reject"
                            ? 
                                <>
                                <button type="button" class="btn btn-secondary me-3 my-2" onClick={(e) => this.props.data.handleActiveTab("1")}>Back to information</button>
                                <button type="button" class="btn btn-primary my-2" onClick={(e) => this.updatePropertyAddress()}>Save Changes</button>
                                </>
                            : ""
                        }
                        </div>
                        </div>
                    </form>
                </div>
            </>
        )
    }
}
