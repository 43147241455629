import React, { Component } from 'react'
import logo from '../assets/images/genhaven-white.webp'
import {ReactComponent as Facebook} from '../assets/images/facebook.svg'
import {ReactComponent as Twitter} from '../assets/images/twitter.svg'

import { Link, Redirect } from 'react-router-dom'
import axios from "axios";
// import Button from '@restart/ui/esm/Button'
import { APIURL } from '../components/constants/common';
import { Spinner ,    Button } from 'reactstrap';
import Select from 'react-select';
import Navbar from './Front/TopNavbar'
import Footer from './Front/Footer'
import queryString from 'query-string'

const options = [
    { value: 'a', label: 'a' },
    { value: 'b', label: 'b' },
];


export default class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            signupData: {
                name: "",
                first_name: "",
                last_name: "",
                email: "",
                phone: "",
                language: "",
                introduction: "",
                license_number: "",
                password: "",
                c_password: "",
                address: "",
                city: "",
                street_name: "",
                street_number: "",
                zip_code: "",
                registered_business_name: "",
                registered_business_address: "",
                rc_number: "",
                tax_id: ""
            },
            languages: [],
            countrycode: "",
            countryId: "",
            countries: [0],
            States: [],
            stateId: "",
            user_type_id: "0",
            user_Type: "",
            selectType: "",
            msg: "",
            errMsg: "",
            scsMsg: "",
            user_types: [],
            redirect: false,
            isLoading: false,
            fullscrLoader: true,
            showPassword: false,
            Cofirm_showPassword: false,
            cities_name: [],
            city_id: "",
            acceptance_check: ""
        }
    }

    acceptCheck = (e) => {
        this.setState({
            acceptance_check: e ? true : ""
        })
    }

    onChangehandler = (e, key) => {
        const { signupData } = this.state;
        signupData[e.target.name] = e.target.value;
        this.setState({ signupData, errMsg: "" });
    };

    handleUserType = (e) => {
        this.setState({
            user_type_id: e
        })
    }

    handleCity(e) {
        this.setState({
            city_id: e
        })
    }

    getCities(state_id) {
        axios
            .post(APIURL + "cities", {
                state_id: state_id,
            })
            .then((response) => {
                let cities_name = response.data.states;
                const cityNames = [];
                for (var c = 0; c < cities_name.length; c++) {
                    cityNames.push({ value: cities_name[c].id, label: cities_name[c].name })
                }
                this.setState({
                    cities_name: cityNames,
                })
            })
    }

    //get language
    getLanguages() {
        axios
            .get(APIURL + "languages")
            .then((response) => {
                let languages = response.data.languages;
                for (var c = 0; c < languages.length; c++) {
                    this.state.languages.push({ value: languages[c].id, label: languages[c].name })
                }  //console.log(this.state.languages)
            })
    }

    handleLanguages = (selectedLanguages) => {
        this.setState({ selectedLanguages });
    }

    getCountries() {
        axios
            .get(APIURL + "countries")
            .then((response) => {
                this.setState({
                    countries: response.data.countries
                })
            })
    }

    handleCountry(e) {
        this.setState({
            countryId: e
        })
    }

    handleCountryState = () => {
        var country = this.state.countryId !== "" ? this.state.countryId : "1"
        axios
            .post(APIURL + "states", {
                country_id: country,
            })
            .then((response) => {
                this.setState({
                    States: response.data.states
                });
                //console.log("dasdAS", response.data)
            })
    };

    handleState(e) {
        this.setState({
            stateId: e,
            cities_name: []
        }, () => {
            if(e !== "") {
                this.getCities(e)
            }
        })
    }

    onSubmitHandler = (e) => {
        // console.log(JSON.stringify(this.state.selectedOptions))
        e.preventDefault()

        const value = queryString.parse(this.props.location.search);
        let referral_id = 0;
        if (value.token) {
           referral_id = value.token;
        }

        const { signupData } = this.state;
        const user_type = parseInt(this.state.user_type_id) === 0 ? "buyer" : parseInt(this.state.user_type_id) === 1 ? "agent" : "seller"
        signupData['user_type'] = user_type
        signupData['state'] = this.state.stateId
        signupData['city'] = this.state.city_id
        signupData['acceptance_check'] = this.state.acceptance_check
        if(user_type === "buyer") {
            signupData['country'] = this.state.countryId
        } else {
            signupData['country'] = "1"
        }
        
        signupData['language'] = JSON.stringify(this.state.selectedLanguages)
        signupData['referral_id'] = referral_id
        
        const success = this.props.location;
        this.setState({ isLoading: true });
        axios
            .post(APIURL + "register", this.state.signupData)
            .then((response) => {
                // console.log("register", response.data.accountVerified)
                this.setState({
                    scsMsg: response.data.message,
                    isLoading: false

                })
                localStorage.setItem("accountVerified", response.data.accountVerified);
                setTimeout(() => this.setState({
                    redirect: true
                }), 4000);
            })
            .catch(error => {
                this.setState({
                    isLoading: false,
                    redirect: false,
                    errMsg: error.response.data.error,
                })
                setTimeout(() => this.setState({
                    errMsg: ""
                }), 4000);
               }
            );
    };

    showPassword() {
        this.setState({
            showPassword: !this.state.showPassword
        })
    }

    Cofirm_showPassword() {
        this.setState({
            Cofirm_showPassword: !this.state.Cofirm_showPassword
        })
    }
    
    componentDidMount() {
        window.scrollTo(0,0)
        setTimeout(() => this.setState({ fullscrLoader: "" }), 500);
        this.getCountries()
        this.handleCountryState()
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to="/signin" push={true} />;
        }
        return (
            <div className="resido-front">
                {/* Navbar cmp */}
                <Navbar />
                {/* header cmp */}
                {this.state.fullscrLoader ? <div className="loader"> <Spinner type="grow" color="dark" style={{ width: '3rem', height: '3rem' }} /> </div> : ""}
                    <div class="content-area">
                      <div class="authentication">
                        <div class="container mt-5">
                          <div class="row g-0">
                            <div class="col-sm-12">
                              <div class="authentication__content">
                                <div class="row g-0 align-self-center">
                                  <div class="col-sm-6">
                                    <div class="px-5 authentication__withsocial gradient1">
                                      <div class="content">
                                        <a href="#"><img loading="lazy" src={logo} class="brand-logo" alt="genhaven Logo" /></a>
                                        <h2>Glad to See You!</h2>
                                        <p>Create a "New Account" by filling the form.</p>
                                        {/*<div>
                                          <h5>Login With Social Media</h5>
                                          <div>
                                            <a href="#" class="btn btn-facebook"><Facebook class="img-svg" alt="user" /> <span>Login With Facebook</span></a>
                                            <a href="#" class="btn btn-twitter"><Twitter class="img-svg" alt="user" /> <span>Login With Twitter</span></a>
                                          </div>
                                        </div>*/}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-sm-6">
                                    <div class="mx_h-600">
                                      <div class="px-5">
                                        <h1>Register</h1>
                                        {this.state.scsMsg ? <div class="alert alert-success" role="alert">
                                            {this.state.scsMsg}
                                        </div> : ""}
                                        <form onSubmit={(e) => this.onSubmitHandler(e)}>
                                        <div>
                                           <div class="mb-4">
                                               <label for="exampleusertype" class="form-label">User Type <strong className="text-danger" >*</strong></label>
                                                    <select className="form-control" id="user_dropdown" onChange={(e) => this.handleUserType(e.target.value)} >
                                                        <option value="0">Buyer</option>
                                                        <option value="1">Partner</option>
                                                        <option value="2">Agent (Seller)</option>
                                                    </select>
                                                    <span className="text-danger">{this.state.errMsg.user_type}</span>
                                                 
                                                <span className="text-danger">{this.state.errMsg.user_type}</span>
                                            </div>
                                            <div class="mb-4">
                                               <label  class="form-label">First Name <strong className="text-danger" >*</strong></label>
                                                    <input
                                                        autoFocus={true}
                                                        className="form-control"
                                                        required=""
                                                        type="text"
                                                        name="name"
                                                        placeholder="First Name"
                                                        value={this.state.signupData.name}
                                                        onChange={this.onChangehandler}
                                                    />
                                                   
                                                <span className="text-danger">{this.state.errMsg.name}</span>
                                            </div>
                                            <div class="mb-4">
                                                    <label class="form-label">Last Name <strong className="text-danger" >*</strong></label>
                                                        <input
                                                            className="form-control"
                                                            required=""
                                                            type="text"
                                                            name="last_name"
                                                            placeholder="Last Name"
                                                            value={this.state.signupData.last_name}
                                                            onChange={this.onChangehandler}
                                                        />
                                                       
                                                    <span className="text-danger">{this.state.errMsg.last_name}</span>
                                            </div>
                                            <div class="mb-4">
                                                <label>Email Address <strong className="text-danger" >*</strong></label>
                                                <input
                                                    className="form-control"
                                                    required=""
                                                    type="email"
                                                    name="email"
                                                    placeholder="Email Address"
                                                    value={this.state.signupData.email}
                                                    onChange={this.onChangehandler}
                                                />                                                       
                                                <span className="text-danger">{this.state.errMsg.email}</span>
                                            </div>
                                            <div class="mb-4">
                                                <div class="icon-input">
                                                    <label> Password <strong className="text-danger" >*</strong></label>
                                                    <div className="position-relative">
                                                        <div className="input-with-icon">
                                                            <input
                                                                className="form-control"
                                                                required=""
                                                                type={this.state.showPassword ? "text" : "password"}
                                                                name="password"
                                                                placeholder="Password"
                                                                value={this.state.signupData.password}
                                                                onChange={this.onChangehandler}
                                                            />
                                                            <i className="ti-unlock"></i>
                                                        </div>
                                                        {this.state.showPassword ?
                                                            <span className="fa-eye-pass input-visiblity"> <i onClick={() => this.showPassword()} className="fas fa-eye-slash"></i> </span>
                                                            :
                                                            <span className="fa-eye-pass input-visiblity"> <i onClick={() => this.showPassword()} className="fas fa-eye"></i> </span>
                                                        }
                                                    </div>
                                                    <span className="text-danger">{this.state.errMsg.password}</span>
                                                </div>
                                            </div>
                                            <div class="mb-4">
                                                <div class="icon-input">
                                                    <label> Confirm Password <strong className="text-danger" >*</strong></label>
                                                    <div className="position-relative">
                                                        <div className="input-with-icon">
                                                            <input
                                                                className="form-control"
                                                                required=""
                                                                type={this.state.Cofirm_showPassword ? "text" : "password"}
                                                                name="c_password"
                                                                placeholder="Confirm Password"
                                                                value={this.state.signupData.c_password}
                                                                onChange={this.onChangehandler}
                                                            />
                                                            <i className="ti-unlock"></i>
                                                        </div>
                                                        {this.state.Cofirm_showPassword ?
                                                            <span className="fa-eye-pass input-visiblity"> <i onClick={() => this.Cofirm_showPassword()} className="fas fa-eye-slash"></i> </span>
                                                            :
                                                            <span className="fa-eye-pass input-visiblity"> <i onClick={() => this.Cofirm_showPassword()} className="fas fa-eye"></i> </span>
                                                        }
                                                    </div>
                                                    <span className="text-danger">{this.state.errMsg.c_password}</span>
                                                </div>
                                            </div>
                                            <div class="mb-4">
                                               <label  class="form-label">Address</label>
                                                <input
                                                    autoFocus={true}
                                                    className="form-control"
                                                    required=""
                                                    type="text"                                                      
                                                    placeholder="Enter Your Address"                                                       
                                                />                                                   
                                                <span className="text-danger">{this.state.errMsg.address}</span>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <div class="mb-4">
                                                        <label>Country <strong className="text-danger" >*</strong></label>
                                                        {   this.state.user_type_id === "0" ?
                                                            <select className="form-control" id="country_dropdown" onChange={(e) => this.handleCountry(e.target.value)}>
                                                                <option  >Select Country</option>
                                                                {this.state.countries.length > 0 &&
                                                                    this.state.countries.map((item, idx) => (
                                                                        <option value={item.id} >{item.name}</option>
                                                                    ))
                                                                }
                                                            </select> : 
                                                            <select className="form-control" id="country_dropdown" onChange={(e) => this.handleCountry(1)} disabled>
                                                                {/* <option  >Select Country</option>
                                                                {this.state.countries.length > 0 ?
                                                                    this.state.countries.map((item, idx) => (
                                                                        <option value={item.id} selected={item.id === 231}  >{item.name}</option>
                                                                    )) :
                                                                    <span></span>
                                                                } */}
                                                                <option value="1">Nigeria</option>
                                                            </select>                                                            
                                                        }
                                                        <span className="text-danger">{this.state.errMsg.country}</span>
                                                    </div>
                                                </div>
                                                {   
                                                    this.state.user_type_id !== "0"
                                                    ? <div class="col-6">
                                                        <div class="mb-4">
                                                            <label>State <strong className="text-danger" >*</strong></label>
                                                            <select className="form-control" id="state_dropdown" onChange={(e) => this.handleState(e.target.value)}  >
                                                                <option value="">Select State</option>
                                                                {this.state.States.length > 0 &&
                                                                    this.state.States.map((item, idx) => (
                                                                        <option value={item.id} >{item.name}</option>
                                                                    ))
                                                                }
                                                            </select>                                                               
                                                            <span className="text-danger">{this.state.errMsg.state}</span>
                                                        </div>
                                                    </div>
                                                    : <div class="col-6">
                                                        <div class="mb-4">
                                                            <label> Zip/Post Code</label>
                                                                <input
                                                                    className="form-control"
                                                                    required=""
                                                                    type="number"
                                                                    min="0"
                                                                    name="zip_code"
                                                                    onkeypress="return isNumberKey(event)"
                                                                    placeholder="Zip/Post Code"
                                                                    value={this.state.signupData.zip_code}
                                                                    onChange={this.onChangehandler}
                                                                />
                                                            <span className="text-danger">{this.state.errMsg.zip_code}</span>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            {   this.state.user_type_id !== "0" &&
                                                <div class="row">
                                                    <div class="col-6">
                                                        <div class="mb-4">
                                                            <label> Locality <strong className="text-danger" >*</strong></label>
                                                            <select className="form-control" value={this.state.city_id} onChange={(e) => this.handleCity(e.target.value)} >
                                                                <option value="" >Select Locality</option>
                                                                {this.state.cities_name.map((option) => (
                                                                    <option value={option.value}>{option.label}</option>
                                                                ))}
                                                            </select>
                                                            
                                                            <span className="text-danger">{this.state.errMsg.city}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="mb-4">
                                                            <label> Zip Code</label>
                                                                <input
                                                                    className="form-control"
                                                                    required=""
                                                                    type="number"
                                                                    min="0"
                                                                    name="zip_code"
                                                                    onkeypress="return isNumberKey(event)"
                                                                    placeholder="Zip Code"
                                                                    value={this.state.signupData.zip_code}
                                                                    onChange={this.onChangehandler}
                                                                />
                                                            <span className="text-danger">{this.state.errMsg.zip_code}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                           
                                            {parseInt(this.state.user_type_id) === 1 ?
                                            <>
                                                <div className="col-lg-12 col-md-6 mb-4">
                                                    <div className="form-group">
                                                        <label for="registered_business_name">Registered Business Name</label>
                                                        <input
                                                            className="form-control"
                                                            required=""
                                                            type="text"
                                                            name="registered_business_name"
                                                            placeholder="Registered Business Name"
                                                            value={this.state.signupData.registered_business_name}
                                                            onChange={this.onChangehandler}
                                                            />
                                                        <span className="text-danger">{this.state.errMsg.registered_business_name}</span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-6">
                                                        <div class="mb-4">
                                                            <label for="rc_number">RC Number</label>
                                                            <input
                                                                className="form-control"
                                                                required=""
                                                                type="text"
                                                                name="rc_number"
                                                                placeholder="RC Number"
                                                                value={this.state.signupData.rc_number}
                                                                onChange={this.onChangehandler}
                                                                />
                                                            <span className="text-danger">{this.state.errMsg.rc_number}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="mb-4">
                                                            <label for="tax_id">Tax ID</label>
                                                            <input
                                                                className="form-control"
                                                                required=""
                                                                type="text"
                                                                name="tax_id"
                                                                placeholder="Tax ID"
                                                                value={this.state.signupData.tax_id}
                                                                onChange={this.onChangehandler}
                                                            />
                                                            <span className="text-danger">{this.state.errMsg.tax_id}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-6 mb-4">
                                                    <div className="form-group">
                                                        <label for="registered_business_address">Registered Business Address</label>
                                                        <input
                                                            className="form-control"
                                                            required=""
                                                            type="text"
                                                            name="registered_business_address"
                                                            placeholder="Registered Business Address"
                                                            value={this.state.signupData.registered_business_address}
                                                            onChange={this.onChangehandler}
                                                            />
                                                        <span className="text-danger">{this.state.errMsg.registered_business_address}</span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-6">
                                                    <div className="form-group">
                                                        <label> License Number</label>

                                                        <div className="input-with-icon">
                                                            <input
                                                                className="form-control"
                                                                required=""
                                                                type="number"
                                                                min="0"
                                                                name="license_number"
                                                                placeholder="License number"
                                                                value={this.state.signupData.license_number}
                                                                onChange={this.onChangehandler}
                                                            />
                                                            <i class="fas fa-address-card"></i>
                                                        </div>
                                                        <span className="text-danger">{this.state.errMsg.license_number}</span>
                                                    </div>
                                                </div>
                                                </>
                                                    : ""}
                                                {
                                                    this.state.user_type_id === 1 ?
                                                    <div className="col-lg-12 col-md-6">
                                                        <div className="form-group">
                                                            <label> Introduction </label>
                                                            <div className="input-with-icon">
                                                                <textarea
                                                                    className="form-control"
                                                                    required=""
                                                                    type="textarea"
                                                                    name="introduction"
                                                                    placeholder="Introduction"
                                                                    value={this.state.signupData.introduction}
                                                                    onChange={this.onChangehandler}
                                                                >
                                                                </textarea>
                                                                <span className="text-danger">{this.state.errMsg.introduction}</span>                                                                   
                                                            </div>
                                                            <span className="text-danger">{this.state.errMsg.introduction}</span>
                                                        </div>
                                                    </div>
                                                    : ""
                                                }
                                                <div className="col-lg-12 col-md-6">
                                                    <input
                                                        type="checkbox"
                                                        name="acceptance_check"
                                                        checked = {this.state.acceptance_check}
                                                        onChange={(e) => this.acceptCheck(e.target.checked)}
                                                    />
                                                    <label className="ms-2">I accept the <a href={"/page/terms-condition"} target="_blank" style={{"font-size": "13px"}}>Terms and Conditions</a></label>
                                                </div>
                                                <span className="text-danger">{this.state.errMsg.acceptance_check}</span>
                                                                                                          
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mt-3">
                                                <div className="text-left mt-3">
                                                    <div className=" fw-bold">
                                                        <i className="ti-user mr-1"></i>
                                                        Already Have An Account?
                                                        <Link to="/signin" className="link ms-2">Go For LogIn</Link>
                                                    </div>
                                                </div>

                                                <div className="form-group mt-3">
                                                    <Button
                                                        type="submit"
                                                        // onSubmit={() => this.onSubmitHandler()}
                                                        className="btn btn-primary"
                                                    >
                                                        Sign Up
                                                        {this.state.isLoading ? (
                                                            <span
                                                                className="spinner-border spinner-border-sm ml-2"
                                                                role="status"
                                                                aria-hidden="true"
                                                            ></span>
                                                        ) : (
                                                            <span></span>
                                                        )}
                                                    </Button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Footer />
            </div>
        )
    }
}
