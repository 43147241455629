import React, { Component } from 'react'
import Lightbox from 'react-image-lightbox';
import { Modal, ModalBody, ModalHeader, Input, ModalFooter, Spinner, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap'
import axios from "axios";
import { Redirect } from 'react-router-dom'
import { APIURL } from '../../.././constants/common';
import Accordion from 'react-bootstrap/Accordion'
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';
import { ReactComponent as Check} from '../../../../assets/images/check-circle1.svg';
import { ReactComponent as Dash} from '../../../../assets/images/dash-circle.svg';

// const images = [
//     { url: "https://images.unsplash.com/photo-1560518883-ce09059eeffa?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8cHJvcGVydHl8ZW58MHx8MHx8&ixlib=rb-1.2.1&w=1000&q=80" },
//     { url: "https://media.istockphoto.com/photos/home-tax-deduction-picture-id1270111816?b=1&k=20&m=1270111816&s=170667a&w=0&h=r15RKKh8GsUCFjX2FvrVmx4CYrk5fiAwSlfAmcwPoUw=" },

// ];

const captionStyle = {
    fontSize: '2em',
    fontWeight: 'bold',
}
const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
}

export default class ViewPropertyDetailsByBuyer extends Component {
    constructor() {
        super();
        this.state = {
            user: JSON.parse(localStorage.getItem("userData")),
            token: JSON.parse(localStorage.getItem("token")),
            p_id: "",
            PropertyInfo: [],
            Loader: true,
            verifyComment: "",
            rejectComment: "",
            verifyCommentModal: false,
            rejectCommentModal: false,
            initialMsg: "verify",
            accepted: "",
            verify: "verify/unverified",
            redirectBack: false,
            imageData: [],
            videoData: [],
            photoIndex: 0,
            isOpen: false,
            propertyCheck: []
        }
    }

    componentDidMount() {
        document.body.classList.add('sidebar-icon-only');
        this.ViewPropertyInfo(this.props.match.params.id)

    }

    ViewPropertyInfo = (e) => {
        var token = this.state.token
        const formData = new FormData();
        formData.append('property_id', e);
        this.setState({ Loader: true });
        axios
            .post(APIURL + "view-buyer-property-detail", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                this.setState({
                    Loader: false,
                    PropertyInfo: response.data.data,
                })
                let Images = [];
                
                this.state.PropertyInfo.map((item, idx) => {
                    item.property_media.map((result, index) => {
                        result.type === "image" &&
                        Images.push({
                            url_path: result.url_path,
                            type: result.type,
                            caption: ""
                        })
                    });
                })

                this.setState({
                    imageData: Images,
                })
            })
            .catch((error) => {
                this.setState({
                    errMsg: error.response.data.error,
                    Loader: false
                })
            });
    };
    
    verifyCommentModal(e) {
        this.setState({
            verifyCommentModal: !this.state.verifyCommentModal,
        })
    }

    rejectCommentModal(e) {
        this.setState({
            rejectCommentModal: !this.state.rejectCommentModal,
        })
    }
    
    verifyModalClose() {
        this.setState({
            verifyCommentModal: false
        })
    }

    rejectModalClose() {
        this.setState({
            rejectCommentModal: false
        })
    }

    verifyComment(e) {
        this.setState({
            verifyComment: e
        })
    }

    rejectComment(e) {
        this.setState({
            rejectComment: e
        })
    }

    VerifyingRequest(status) {
        if (status === "reject") {
            if (this.state.rejectComment) {
                this.onSubmit(status)
            } else {
                this.setState({
                    errMsgCmt: "Please write a comment"
                })
            }
        }

        if (status === "verify") {
            if (this.state.verifyComment) {
                this.onSubmit(status)
            } else {
                this.setState({
                    errMsgCmt: "Please write a comment"
                })
            }
        }
    }

    onSubmit = (status) => {
        this.setState({ isLoading: true })
        var token = this.state.token
        const formData = new FormData();
        formData.append('property_id', this.props.match.params.id);
        formData.append('status', status);
        formData.append('comment', this.state.verifyComment ? this.state.verifyComment : this.state.rejectComment);
        this.setState({ Loader: true, verifyCommentModal: false, rejectCommentModal: false});
        axios
            .post(APIURL + "admin/update-buyer-property-status-by-admin", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                console.log(response.data.data)
                this.setState({
                    Loader: false,
                    VerifyStatus: response.data.data.status
                });
                this.ViewPropertyInfo(this.props.match.params.id);
            })
            .catch((error) => {
                this.setState({
                    Loader: false,
                    errMsg: error.response.data.error,
                })
            });
    }

    Goback() {
        this.setState({
            redirectBack: true
        })
    }

    render() {
        if (!this.state.user) {
            return <Redirect to="/signin" />;
        }

        if (this.state.redirectBack) {
            return <Redirect to={"/agent/assigned/property/"} />;
        }
        const { PropertyInfo } = this.state
        //console.log("details", this.state.Data)
        return (
        <>
            <div className="admin">
                {this.state.Loader ? <div className="loader"> <Spinner type="grow" color="dark" /> </div> : ""}
                <Sidebar activePage="property-listing" />
                <div class="content-area">
                <Navbar activePage="view-addedbuyerpropertydetails-by-admin"/>   
                <div class="content">
                {this.state.PropertyInfo.length > 0 && this.state.PropertyInfo.map((item, idx) => (
                    <div class="card">
                    <div class="card-body">
                        <div class="d-sm-flex justify-content-between align-items-center">
                        <h2 id="page_headeing"><span>{item.name}</span>
                            {
                                item.status === "verify" ?
                                    <span class="badge bg-success ms-2">Verified</span>
                                :
                                item.status === "under_review" ?
                                    <span class="badge bg-warning ms-2">Under Review</span>
                                : 
                                    item.status === "reject" ?
                                    <span class="badge bg-danger ms-2">Rejected</span>
                                : ""                                    
                            }
                        </h2>
                        <div>
                        {
                            item.status === "under_review"
                            ?
                            <>
                                <a href="#" class="py-1 pe-3 text-muted" onClick={(e) => this.rejectCommentModal()}>Reject</a>
                                <a href="#" class="btn btn-primary btn-sm" onClick={(e) => this.verifyCommentModal()}>Verify</a>
                            </>
                            : ""
                        }
                        </div>
                        </div>
                        <hr />
                        <div>
                        <div class="deatil_features">
                            <h4>Property Information</h4>
                            <ul class="list mb-2">
                            <li>
                                <strong>Address:</strong><span>{item.address} </span>
                            </li>
                            <li>
                                <strong>Nearest Bus Stop:</strong><span>{item.near_by_bus_stop} </span>
                            </li>
                            <li>
                                <strong>City:</strong><span>{item.city_name} </span>
                            </li>
                            <li>
                                <strong>State:</strong><span>{item.state_name} </span>
                            </li>
                            <li>
                                <strong>Country:</strong><span>{item.country_name} </span>
                            </li>
                            </ul>
                            <h5 class="fs-5">Description</h5>
                            <p class="mt-2">{item.description} </p>
                            <hr />
                            <h4>Verification Requested</h4>
                            <ul class="list mb-2">
                            <li>
                                <strong>Property Title:</strong><span><Check class="img-svg me-2" alt="" />{item.property_title_check} </span>
                            </li>
                            <li>
                                <strong>Survey Document:</strong><span><Check class="img-svg me-2" alt="" />{item.survey_document_check} </span>
                            </li>
                            <li>
                                <strong>Site Visit:</strong><span><Check class="img-svg me-2" alt="" />{item.site_visit_check} </span>
                            </li>
                            </ul>
                            <hr />
                            <div>
                            {   (item.hands_free_service === "true" || item.hands_free_service === true) &&
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="mb-4 d-flex">
                                            <div><Check class="img-svg" alt="" /></div>
                                            <label class="form-label" className='ms-2'>Hands free service</label>
                                        </div>
                                    </div>
                                </div>
                            }
                            {   (item.verification_acceptance_check === "true" || item.verification_acceptance_check === true) &&
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="mb-4 d-flex">
                                            <div><Check class="img-svg" alt="" /></div>
                                            <label class="form-label" className='ms-2'>This document contains information in connection with the verification requested for the above property.
                                        Issuance of the certificate does not in ANY way mean that your acquisition is fool proof if standard acquisition and 
                                        conveyancing process is not engaged</label>
                                        </div>
                                    </div>
                                </div>
                            }
                            </div>
                        </div>
                        <hr />
                        <div class="deatil_features">
                            <div class="img_gallery">
                                <h4>Gallery</h4>
                                <div class="row">
                                    <div class="col-sm-12">
                                    <div>
                                        <h5>Images</h5>
                                        {this.state.imageData.length > 0 && this.state.imageData.map((item, idx) => (
                                            
                                                item.type === "image" ?
                                                <a href="#" onClick={() => this.setState({ isOpen: true })}><img src={item.url_path} class="img-thumbnail" alt="logo" /></a>
                                                : ""  
                                        ))}
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    ))}
                </div>
                </div>

                {this.state.isOpen && (
                <Lightbox
                    mainSrc={this.state.imageData[this.state.photoIndex].url_path}
                    nextSrc={this.state.imageData[(this.state.photoIndex + 1) % this.state.imageData.length].url_path}
                    prevSrc={this.state.imageData[(this.state.photoIndex + this.state.imageData.length - 1) % this.state.imageData.length].url_path}
                    onCloseRequest={() => this.setState({ isOpen: false })}
                    onMovePrevRequest={() =>
                    this.setState({
                        photoIndex: (this.state.photoIndex + this.state.imageData.length - 1) % this.state.imageData.length,
                    })
                    }
                    onMoveNextRequest={() =>
                    this.setState({
                        photoIndex: (this.state.photoIndex + 1) % this.state.imageData.length,
                    })
                    }
                />
                )}
            </div>

            <Modal size="md" isOpen={this.state.verifyCommentModal} toggle={() => this.verifyModalClose()}>
                <ModalHeader className="" toggle={() => this.verifyModalClose()}>
                    Comment <br />
                    <span className="text-danger">{this.state.errMsgCmt}</span>
                </ModalHeader>
                <ModalBody className="border-0">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="form-group">
                                <div className="input-with-icon">
                                    <textarea
                                        rows="4"
                                        className="form-control h-auto"
                                        required=""
                                        type="textarea"
                                        name="comment"
                                        placeholder="write here"
                                        value={this.state.verifyComment}
                                        onChange={(e) => this.verifyComment(e.target.value)}
                                    />
                                    {/* <i class="fas fa-address-card"></i> */}
                                </div>
                                {/* <span className="text-danger">{this.state.errMsg.address}</span> */}
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="">
                        <button type="button" className="btn btn-primary" onClick={() => this.VerifyingRequest("verify")}>Save changes</button>
                        <button type="button" className="btn btn-light" onClick={() => this.verifyModalClose()}>Close</button>
                    </div>
                </ModalFooter>
            </Modal>

            <Modal size="md" isOpen={this.state.rejectCommentModal} toggle={() => this.rejectModalClose()}>
                <ModalHeader className="" toggle={() => this.rejectModalClose()}>
                    Comment <br />
                    <span className="text-danger">{this.state.errMsgCmt}</span>
                </ModalHeader>
                <ModalBody className="border-0">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="form-group">
                                <div className="input-with-icon">
                                    <textarea
                                        rows="4"
                                        className="form-control h-auto"
                                        required=""
                                        type="textarea"
                                        name="comment"
                                        placeholder="write here"
                                        value={this.state.rejectComment}
                                        onChange={(e) => this.rejectComment(e.target.value)}
                                    />
                                    {/* <i class="fas fa-address-card"></i> */}
                                </div>
                                {/* <span className="text-danger">{this.state.errMsg.address}</span> */}
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="">
                        <button type="button" className="btn btn-primary" onClick={() => this.VerifyingRequest("reject")}>Save changes</button>
                        <button type="button" className="btn btn-light" onClick={() => this.rejectModalClose()}>Close</button>
                    </div>
                </ModalFooter>
            </Modal>    
        </>   
        )
    }
}
