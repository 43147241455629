import React, { Component } from 'react'
import { Redirect } from 'react-router';
import Sidebar from './../Sidebar'
import axios from "axios";
import { APIURL } from '../../../../components/constants/common';
import Navbar from './../Navbar'
import Button from '@restart/ui/esm/Button';
import { Spinner, Input } from 'reactstrap'
import dateFormat, { masks } from "dateformat";
import Pagination from "react-js-pagination";
import { Link } from 'react-router-dom';
import { ReactComponent as EditPencil } from '../../../../assets/images/pencil-fill.svg';
import Tooltip from '@mui/material/Tooltip';

export default class Index extends Component {
    constructor() {
        super();
        this.state = {
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            user_type: localStorage.getItem("user_type"),
            navigate: false,
            errMsg: "",
            scsMsg: "",
            profile_image: "",
            fullscrLoader: false,
            packageRecordes: [],
            activePage: 1,
            limit: 0,
            totalItemsCount: 0,
            userType: ""

        }
    }

    componentDidMount() {
        this.getPackage()

    }


    handlePageChange(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        this.setState(
            { activePage: pageNumber }
            , () => {
                this.getPackage()
            }
        );
    }




    getPackage() {
        if (this.state.user) {
            const formData = new FormData();

            formData.append('page', this.state.activePage);
            formData.append('limit', this.state.limit);
            var token = this.state.token
            var app_url = APIURL + "admin/get-subscription-plan-list"
            axios
                .post(app_url, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    // const info = response.data.data;
                    console.log(response.data.data.data)
                    this.setState({
                        packageRecordes: response.data.data.data,
                        activePage: response.data.data.current_page,
                        totalItemsCount: response.data.data.total,
                        limit: response.data.data.per_page
                    })
                })
                .catch((error) => {
                    this.setState({
                        // errMsg: error.response.data.errors,
                        Loader: false
                    })
                });
        }
    }




    render() {
        if (!this.state.user) {
            return <Redirect to="/signin" />;
        }
        return (
            <div className="admin">

                {this.state.Loader ? <div className="loader"> <Spinner type="grow" color="dark" style={{ width: '3rem', height: '3rem' }} /> </div> : ""}


                <Sidebar activePage="package" />
                <div class="content-area">
                    <Navbar />

                    <div class="content">
                        <div class="card">
                            <div class="card-body">
                                <div class="d-flex justify-content-between align-items-center">
                                    <h2 id="page_headeing"><span>Packages</span></h2>
                                    <div class="d-flex justify-content-end align-items-center">
                                        <div class="row align-items-end">



                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div>
                                    <div class="table-responsive">
                                        <table class="table table-striped table-borderless">
                                            <thead>
                                                <tr class="border-bottom">
                                                    <th style={{minWidth:70}}>Sr. No.</th>
                                                    <th style={{minWidth:100}}>Plan Name</th>
                                                    <th style={{minWidth:70}}>For</th>
                                                    <th style={{minWidth:100}}>Amount (£)</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.packageRecordes.length > 0 && this.state.packageRecordes.map((item, idx) => (
                                                    <>
                                                        { item.plan_name !== "Commission on Property" &&
                                                        <tr>
                                                            <td>{idx + 1}</td>
                                                            <td>{item.plan_name}</td>
                                                            <td>{item.user_type}</td>
                                                            <td>{item.amount}</td>
                                                            <td><Tooltip title="Edit"><Link to={"/admin/package/editpackage/" + item.id} className="btn btn-rounded btn-info"><EditPencil class="img-svg" /></Link></Tooltip></td>
                                                        </tr>
                                                        }
                                                    </>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="mt-4 text-center">
                                        {/*<ul class="pagination justify-content-center">
                                    <li class="page-item"><a class="page-link active" href="#">1</a></li>
                                    <li class="page-item"><a class="page-link" href="#" >2</a></li>
                                    <li class="page-item"><a class="page-link" href="#" >3</a></li>
                                </ul>*/}
                                        {this.state.totalItemsCount > 0 &&
                                            <div class="justify-content-center d-flex">
                                                <Pagination
                                                    activePage={this.state.activePage}
                                                    itemsCountPerPage={this.state.limit}
                                                    totalItemsCount={this.state.totalItemsCount}
                                                    pageRangeDisplayed={5}
                                                    onChange={this.handlePageChange.bind(this)}
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                    innerClass="pagination justify-content-center"
                                                    activeLinkClass="active"
                                                    nextPageText="Next"
                                                    prevPageText="Previous"
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        )
    }
}