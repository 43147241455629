import React, { Component } from 'react'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import Footer from '../../Front/Footer'
import axios from "axios";
import { APIURL, PAYPAL_CLIENT_ID } from '../../../components/constants/common';
import { Redirect, } from 'react-router-dom'
import { ReactComponent as MenuList}  from '../../../assets/images/three-dots-vertical.svg'
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Modal, Spinner, ModalBody, ModalHeader, ModalFooter, Button, Input } from 'reactstrap'

export default class PropertyList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),

            userInfo: {
                name: "",
                address: "",
                country: "",
                city: "",
                near_by_bus_stop: "",
                description: "",

            },
            States: [],
            selectedFiles: [],
            msg: "",
            state: "",
            errMsg: {},
            scsMsg: "",
            user_types: [],
            redirect: false,
            isLoading: false,
            cities_name: [],
            city_id: "",
            image_error: "",
            hands_free_service: "",
            verification_acceptance: "",
            property_title: "No",
            survey_doc: "No",
            site_visit: "No",
            amount: 0,
            codeChecked: false,
            verificationCode: "",
            requestPriceDetails: [],
            actualAmount: 0,
            haveVerificationCode: false,
            disableSendButton: false,
            showSuccessMsg: false,
            paymentModal: false,
            checkamountStatus: false,
            mobileView: false
        }
    }

    componentDidMount() {
        this.handleCountryState(1);
        this.getRequestPrice();
    }

    handleVerificationCode = (e) => {
        this.setState({
            verificationCode: e
        })
    }

    paymentModal() {
        var token = this.state.token     
        const formData = new FormData();
        formData.append('name', this.state.userInfo.name);
        formData.append('address', this.state.userInfo.address);
        formData.append('state', this.state.state);
        formData.append('city', this.state.city_id);
        formData.append('near_by_bus_stop', this.state.userInfo.near_by_bus_stop);
        this.setState({ Loader: true });

        axios
            .post(APIURL + "buyer/check-buyer-property-validation", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                this.setState({
                    Loader: false,
                    paymentModal: !this.state.paymentModal
                }) 
            })
            .catch((error) => {
                this.setState({
                    Loader: false,
                    errMsg: error.response.data.error
                });
                setTimeout(() => this.setState({ errMsg: "", }), 4000); 
            })
    }

    paymentModalClose() {
        this.setState({
            paymentModal: false
        })
    }

    handleVerificationCodeCheck = (e) => {      
        if(this.state.codeChecked == false)
        {
            this.setState({
                verificationCode:""
            })
        }

        if (e) {
            this.setState({
                disableSendButton: true,
            })
        } else {
            this.setState({
                amount: this.state.actualAmount,
                disableSendButton: false,
                showSuccessMsg: false
            })
        }

        this.setState({
            haveVerificationCode: e,
            codeChecked: !this.state.codeChecked
        })       
    }

    handlepropertyTitle(e) {
        this.setState({
            property_title: e,
        }, () => {
            if(e === "Yes" && !this.state.checkamountStatus) {
                this.setState({
                    amount: this.state.site_visit === "Yes" ?  this.state.amount : parseInt(this.state.actualAmount) + 50,
                    checkamountStatus: true
                })
            } else if(e === "No" && this.state.checkamountStatus && this.state.survey_doc === "No") {
                this.setState({
                    amount: this.state.site_visit === "Yes" ? this.state.amount : parseInt(this.state.amount) - 50,
                    checkamountStatus: false
                })
            }
        })
    }

    handlesurveyDoc(e) {
        this.setState({
            survey_doc: e
        }, () => {
            if(e === "Yes" && !this.state.checkamountStatus) {
                this.setState({
                    amount: this.state.site_visit === "Yes" ?  this.state.amount : parseInt(this.state.actualAmount) + 50,
                    checkamountStatus: true
                })
            } else if(e === "No" && this.state.checkamountStatus && this.state.property_title === "No") {
                this.setState({
                    amount: this.state.site_visit === "Yes" ? this.state.amount : parseInt(this.state.amount) - 50,
                    checkamountStatus: false
                })
            }
        })
    }

    handlesiteVisit(e) {
        this.setState({
            site_visit: e
        }, () => {
            if(e === "Yes") {
                this.setState({
                    amount: parseInt(this.state.actualAmount) * 2,
                })
            } else if(e === "No") {
                this.setState({
                    amount: (this.state.property_title === "No" &&  this.state.survey_doc === "No") ? parseInt(this.state.amount)/2 : parseInt(this.state.actualAmount) + 50,
                })
            }
        })
    }

    handlehandsFreeService = (e) => {
        this.setState({
            hands_free_service: e
        })
    }

    handleverificationAcceptance = (e) => {
        this.setState({
            verification_acceptance: e
        })
    }

    handleCity(e) {
        this.setState({
            city: e.target.value
        });
    }
    
    onChangehandler = (e, key) => {
        const { userInfo } = this.state;
        userInfo[e.target.name] = e.target.value;
        this.setState({
            userInfo,
            errMsg: ""
        });
    };

    getCountries() {
        axios
            .get(APIURL + "countries")
            .then((response) => {
                let countries_name = response.data.countries;
                const CountryNames = [];
                for (var c = 1; c < countries_name.length; c++) {
                    CountryNames.push({ value: countries_name[c].id, label: countries_name[c].name })
                }
                this.setState({
                    Countries: CountryNames,
                })
            })
    }
    
    handleCountry(e){
            this.setState({
                countryId: e
            },()=>{
                this.handleCountryState(e)
            })
    };
    
    // get states
    handleCountryState = (id) => {
        axios
            .post(APIURL + "states", {
                country_id: id,
            })
            .then((response) => {
                let state_name = response.data.states;
                const stateNames = [];
                for (var c = 0; c < state_name.length; c++) {
                    stateNames.push({ value: state_name[c].id, label: state_name[c].name })
                }
                this.setState({
                    States: stateNames,
                })
            })
            .catch((error) => {
                this.setState({
    
                })
            });
    };

    handleState(e) {
        this.setState({
            state: e
        }, () => {
            if(e) {
                this.getCities(e)
            } else {
                this.setState({
                    cities_name: [],
                    city_id: ""
                })
            }
        })
    }

    // handleupload = (e) => {
    //     this.setState({
    //         upload_files: e.target.files[0]
    //     })
    // }

    handleselectedFile = (e) => {
        if(this.state.selectedFiles.length < 10){
            this.setState({
                selectedFiles: [...this.state.selectedFiles, ...e.target.files],
                image_error: ""
            }, () => {
                e.target.value = null;
            })
        } else {
            this.setState({
                image_error: "Only 10 pictures are allowed",
            }, () => {
                e.target.value = null;
                setTimeout(() => {
                    this.setState({
                        image_error: ""
                    })
                }, 4000);
            })
        }
    }

    handleRemoveFile = (index) => {
        var tempArray = this.state.selectedFiles;
        tempArray.splice(index, 1);
        this.setState({
            selectedFiles: tempArray
        })
    }

    getRequestPrice() {
        const formData = new FormData();
        formData.append('user_type', 'Buyer');
        formData.append('plan_type', 'buyer_added_property_request');

        axios
        .post(APIURL + "get-subscription-plan", formData)
        .then((response) => {
            this.setState({
				requestPriceDetails: response.data.data,
                amount: response.data.data.amount,
                actualAmount: response.data.data.amount
			})
        })
    }

    checkVerificationCodeStatus() {
        var token = this.state.token     
        const formData = new FormData();
        formData.append('coupon_code', this.state.verificationCode ? this.state.verificationCode : "");
        formData.append('plan_type', 'buyer_added_property_request');
        
        this.setState({ Loader: true });
        if(this.state.haveVerificationCode)
            axios
                .post(APIURL + "buyer/check-coupon-code-used-or-not", formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                        // var discounted_price = 0;
                        // var amount = this.state.actualAmount;
                        // discounted_price = amount - (amount*(this.state.requestPriceDetails.fixed_rate/100));
                        this.setState({
                            amount: response.data.price,
                            disableSendButton: false,
                            showSuccessMsg: true
                        // }, () => {
                        //     setTimeout(() => this.setState({ 
                        //         showSuccessMsg: false
                        //     }), 4000);
                        });
                })
                .catch((error) => {
                    this.setState({
                        Loader: false,
                        errMsg: error.response.data.error
                    });
                    setTimeout(() => this.setState({ errMsg: "", }), 4000); 
                })
        else{
            //this.sendVerificationRequest(); 
        }
    }

    onSubmitHandler = () => {
        var token = this.state.token
        const { userInfo, user } = this.state;
        const formData = new FormData();
        formData.append('name', this.state.userInfo.name);
        // formData.append('country', 1);
        formData.append('state', this.state.state);
        formData.append('city', this.state.city_id);
        formData.append('address', this.state.userInfo.address);
        formData.append('description', this.state.userInfo.description);
        formData.append('near_by_bus_stop', this.state.userInfo.near_by_bus_stop);
        formData.append('hands_free_service', this.state.hands_free_service);
        formData.append('verification_acceptance_check', this.state.verification_acceptance);
        formData.append('property_title_check', this.state.property_title);
        formData.append('survey_document_check', this.state.survey_doc);
        formData.append('site_visit_check', this.state.site_visit);
        formData.append('coupon_code', this.state.verificationCode ? this.state.verificationCode : "");
        formData.append('amount', this.state.amount);

        this.state.selectedFiles.forEach(file => {
            formData.append('upload_files[]', file)
        });

        this.setState({
            Loader: true
        });
        axios
            .post(APIURL + "buyer/add-buyer-property", formData,{
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                this.setState({
                    Loader: false, 
                    scsMsg: response.data.message,
                    errMsg: "",
                })
                setTimeout(() => this.setState({
                    scsMsg:response.data.message,
                    errMsg: "",
                    redirect:true

                }), 4000);
            })
            .catch((error) => {
                this.setState({
                    errMsg: error.response.data.error,
                    Loader: false
                })
            });
    };

    handleCity(e) {
        this.setState({
            city_id: e
        })
    }

    getCities(state_id) {
        axios
            .post(APIURL + "cities", {
                state_id: state_id,
            })
            .then((response) => {
                let cities_name = response.data.states;
                const cityNames = [];
                for (var c = 0; c < cities_name.length; c++) {
                    cityNames.push({ value: cities_name[c].id, label: cities_name[c].name })
                }
                this.setState({
                    cities_name: cityNames,
                })
            })
    }

    toggleView() {
		this.setState({
			mobileView: !this.state.mobileView
		})
	}

    render() {

        if (this.state.redirect) {
            return <Redirect to="/buyer/addproperty" />;
        }

        return (
            <>
                <Navbar />
                <div class="content-area">
                    <div class="breadcrumb-area gradient1">
                        <div class="container">
                            <h1>My Account</h1>
                            <div><small>Welcome To Your Account</small></div>
                        </div>
                    </div>
                    <div className={this.state.mobileView ? "sidebar_alpha open" : "sidebar_alpha"}></div>
                    <div class="myaccount">
                        <div className='more-menu' onClick={()=>this.toggleView()}><MenuList class="img-svg" alt="menu-list"/></div>
                        <div class="container">
                            <div class="row">
                                <div class="col-xxl-3 col-lg-4">
                                <div className={this.state.mobileView ? "bg-light h-100 sidebar_mobile left open" : "bg-light h-100 sidebar_mobile left"}>
                                        <div class="px-xxl-4 py-4 px-3">
                                            <Sidebar activePage="addproperty" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xxl-9 col-lg-8">
                                    <div class="bg-light">
                                        <div class="px-xxl-4 py-4 px-3">
                                            <div>
                                                <div class="d-flex justify-content-between align-items-end">
                                                    <h2 class="mb-3">New Add Property</h2>
                                                </div>
                                                {this.state.scsMsg ? <div class="alert alert-success" role="alert"> {this.state.scsMsg} </div> : ""}
                                                {/* messgae err */}
                                                {this.state.errMsg.message ? <div class="alert alert-danger" role="alert">
                                                    {this.state.errMsg.message}   </div> : ""}
                                                <form>
                                                    <div class="submit-section">
                                                        <div class="row">
                                                            <div class="col-sm-6">
                                                                <div class="mb-4">
                                                                    <label class="form-label"> Property Name <strong className="text-danger" >*</strong></label>
                                                                    <input
                                                                        className="form-control"
                                                                        required=""
                                                                        type="text"
                                                                        name="name"
                                                                        placeholder="Property Name"
                                                                        value={this.state.userInfo.name}
                                                                        onChange={this.onChangehandler}
                                                                    />
                                                                    <span className="text-danger">{this.state.errMsg.name}</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-6">
                                                                <div class="mb-4">
                                                                    <label class="form-label">Address <strong className="text-danger" >*</strong></label>
                                                                    <input
                                                                        className="form-control"
                                                                        required=""
                                                                        type="text"
                                                                        name="address"
                                                                        placeholder="Address"
                                                                        value={this.state.userInfo.address}
                                                                        onChange={this.onChangehandler}
                                                                    />
                                                                    <span className="text-danger">{this.state.errMsg.address}</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="row">
                                                            <div class="col-sm-6">
                                                                <div class="mb-4">
                                                                    <label for="country" class="form-label">Country </label>
                                                                    <select className="form-control"
                                                                        value={this.state.country}
                                                                        onChange={(e) => this.handleCountry(e.target.value)} disabled>
                                                                        <option value="1">Nigeria</option>
                                                                    </select>
                                                                    <span className="text-danger">{this.state.errMsg.country}</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-6">
                                                                <div class="mb-4">
                                                                <label  class="form-label">State <strong className="text-danger" >*</strong></label>
                                                                    <select className="form-control" autoFocus={true} value={this.state.state}   onChange={(e) => this.handleState(e.target.value)} >
                                                                            <option value="" >Select State</option>
                                                                            {this.state.States.map((option) => (
                                                                                <option value={option.value}>{option.label}</option>
                                                                            ))}
                                                                    </select>
                                                                    <span className="text-danger">{this.state.errMsg.state}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-sm-6">
                                                                <div class="mb-4">
                                                                    {/* <label for="exampleTextarea1" class="form-label">City</label>
                                                                    <Input
                                                                        rows="4"
                                                                        className="form-control"
                                                                        required=""
                                                                        type="text"
                                                                        name="city"
                                                                        placeholder="City"
                                                                        value={this.state.city}
                                                                        onChange={(e) => this.handleCity(e)}
                                                                    /> */}
                                                                    <label for="city" class="form-label">Locality <strong className="text-danger" >*</strong></label>
                                                                        <select className="form-control" value={this.state.city_id} onChange={(e) => this.handleCity(e.target.value)} >
                                                                            <option value="" >Select Locality</option>
                                                                            {this.state.cities_name.map((option) => (
                                                                                <option value={option.value}>{option.label}</option>
                                                                            ))}
                                                                        </select>
                                                                    <span className="text-danger">{this.state.errMsg.city}</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-6">
                                                                <div class="mb-4">
                                                                    <label class="form-label">Nearby Bus Stop<strong className="text-danger" >*</strong></label>
                                                                    <input
                                                                        className="form-control"
                                                                        required=""
                                                                        type="text"
                                                                        name="near_by_bus_stop"
                                                                        placeholder="Nearby Bus Stop"
                                                                        value={this.state.userInfo.Nearbystop}
                                                                        onChange={this.onChangehandler}
                                                                    />
                                                                    <span className="text-danger">{this.state.errMsg.near_by_bus_stop}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-sm-12">
                                                                <div class="mb-4">
                                                                    <label for="property_pictures" class="form-label">Pictures</label>
                                                                    <input
                                                                        type="file"
                                                                        className="form-control form-control-sm"
                                                                        onChange = {this.handleselectedFile}
                                                                       accept="image/png, image/jpg, image/jpeg"
                                                                    />
                                                                    <span className="text-danger">{this.state.image_error}</span>
                                                                    {/* <span className="text-info small"> (Only png, jpg, jpeg format and size upto 2 MB is allowed)</span><br /> */}

                                                                    <span className="text-danger">{this.state.errMsg.upload_files}</span>
                                                                </div>
                                                                <div className="mb-4">
                                                                    <div class="row">
                                                                        {this.state.selectedFiles.length > 0 && this.state.selectedFiles.map((file, idx) =>(         
                                                                        <div class="col-sm-3 col-6">                           
                                                                            <div className='img-gallery'>
                                                                                <img className="w-100 " src={URL.createObjectURL(file)} />
                                                                                <div className="img-delete"><i onClick={(e) => this.handleRemoveFile(idx)} className="cursor_pointer far fa-trash-alt"></i></div>
                                                                            </div>
                                                                        </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <h5>Verification Requested</h5>
                                                            <div className="col-sm-4">
                                                                <div className="mb-3">
                                                                    <label for="property_title" class="form-label">Property Title</label>
                                                                    <div className="d-flex">
                                                                        <div class="form-check me-3"> 
                                                                            <Input 
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="property_title"
                                                                                value="Yes"
                                                                                checked = {this.state.property_title === "Yes"}
                                                                                onChange={(e) => this.handlepropertyTitle(e.target.value)}
                                                                            />
                                                                            <label class="form-check-label">Yes</label>
                                                                        </div>
                                                                        <div class="form-check"> 
                                                                            <Input 
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="property_title"
                                                                                value="No"
                                                                                checked = {this.state.property_title === "No"}
                                                                                onChange={(e) => this.handlepropertyTitle(e.target.value)}
                                                                            />
                                                                            <label class="form-check-label">No</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <div className="mb-3">
                                                                    <label for="survey_doc" class="form-label">Survey Document</label>
                                                                    <div className="d-flex">
                                                                        <div class="form-check me-3"> 
                                                                            <Input 
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="survey_doc"
                                                                                value="Yes"
                                                                                checked = {this.state.survey_doc === "Yes"}
                                                                                onChange={(e) => this.handlesurveyDoc(e.target.value)}
                                                                            />
                                                                            <label class="form-check-label">Yes</label>
                                                                        </div>
                                                                        <div class="form-check"> 
                                                                            <Input 
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="survey_doc"
                                                                                value="No"
                                                                                checked = {this.state.survey_doc === "No"}
                                                                                onChange={(e) => this.handlesurveyDoc(e.target.value)}
                                                                            />
                                                                            <label class="form-check-label">No</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <div className="mb-3">
                                                                    <label for="site_visit" class="form-label">Site Visit</label>
                                                                    <div className="d-flex">
                                                                        <div class="form-check me-3"> 
                                                                            <Input 
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="site_visit"
                                                                                value="Yes"
                                                                                checked = {this.state.site_visit === "Yes"}
                                                                                onChange={(e) => this.handlesiteVisit(e.target.value)}
                                                                            />
                                                                            <label class="form-check-label">Yes</label>
                                                                        </div>
                                                                        <div class="form-check"> 
                                                                            <Input 
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="site_visit"
                                                                                value="No"
                                                                                checked = {this.state.site_visit === "No"}
                                                                                onChange={(e) => this.handlesiteVisit(e.target.value)}
                                                                            />
                                                                            <label class="form-check-label">No</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-sm-12">
                                                                <div class="mb-4">
                                                                    <label class="form-label">Description</label>
                                                                    <textarea
                                                                        className="form-control"
                                                                        required=""
                                                                        type="text"
                                                                        name="description"
                                                                        placeholder="Description"
                                                                        value={this.state.userInfo.description}
                                                                        onChange={this.onChangehandler}
                                                                    ></textarea>
                                                                    <span className="text-danger">{this.state.errMsg.description}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <div className='mb-4 d-flex'>
                                                                    <div><Input
                                                                        class="form-control"
                                                                        type="checkbox"
                                                                        name="hands_free_service"
                                                                        checked = {this.state.hands_free_service}
                                                                        onChange={(e) => this.handlehandsFreeService(e.target.checked)}
                                                                    /></div>
                                                                    <label for="hands_free_service" class="ms-2">Hands free service</label>
                                                                </div>
                                                                
                                                            </div>
                                                            <span className="text-danger">{this.state.errMsg.hands_free_service}</span>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <div className='mb-4 d-flex'>
                                                                    <div><Input
                                                                        class="form-control"
                                                                        type="checkbox"
                                                                        name="verification_acceptance"
                                                                        checked = {this.state.verification_acceptance}
                                                                        onChange={(e) => this.handleverificationAcceptance(e.target.checked)}
                                                                    /></div>
                                                                    <label for="acceptance" class="ms-2">This document contains information in connection with the verification requested for the above property.
                                                                Issuance of the certificate does not in ANY way mean that your acquisition is fool proof if standard acquisition and 
                                                                conveyancing process is not engaged</label>
                                                                </div>
                                                                
                                                            </div>
                                                            <span className="text-danger">{this.state.errMsg.verification_acceptance_check}</span>
                                                        </div>
                                                        <hr />
                                                        <div className="row mb-4 align-items-center">
                                                            <div className="col-sm-6">
                                                                <div className='d-flex'>
                                                                    <div>
                                                                        <Input
                                                                            class="form-control"
                                                                            type="checkbox"
                                                                            name="verification_code_check"
                                                                            checked={this.state.codeChecked}
                                                                            onChange={(e) => this.handleVerificationCodeCheck(e.target.checked)}
                                                                        />
                                                                    </div>
                                                                    <label for="verification_code_check" class="ms-2">Do you have Transferrable Verification Code or Coupon Code</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <h4 className='mb-0 bg-secondary d-inline-block p-3 text-white rounded'>
                                                                    <span className="d-inline-block me-3 my-1">Verification Price</span> 
                                                                    <span className='rounded bg-white text-secondary p-2 d-inline-block my-1'>£{this.state.amount}</span>
                                                                </h4>
                                                            </div>
                                                            <span className="text-danger">{this.state.errMsg.verification_acceptance_check}</span>
                                                        </div>
                                                        {  
                                                            this.state.haveVerificationCode &&
                                                            <div className="row mb-3">
                                                                <div className="col-sm-12 col-md-8">
                                                                <div className="coupon_code_cnt">
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Enter Code"
                                                                        value = {this.state.verificationCode}
                                                                        onChange={(e) => this.handleVerificationCode(e.target.value)}
                                                                    />
                                                                    <Button className="mb-2 btn btn-secondary" onClick={(e) => this.checkVerificationCodeStatus()}>Apply Code</Button>
                                                                </div>
                                                                {
                                                                    this.state.showSuccessMsg && 
                                                                    <span className="text-success">Verification Code applied successfully</span>
                                                                }
                                                                </div>
                                                            </div>
                                                        }
                                                        <div>
                                        {/* <Button
                                            // type="submit"
                                            className="btn btn-primary"
                                            onClick={() => this.onSubmitHandler()}
                                            disabled={this.state.disableSendButton?true:false}
                                        >Submit</Button> */}
                                        <Button
                                            // type="submit"
                                            className="btn btn-primary"
                                            onClick={() => this.paymentModal()}
                                            disabled={this.state.disableSendButton?true:false}
                                        >Submit</Button>
                                    </div>
                                                    </div>

                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal size="md" isOpen={this.state.paymentModal} toggle={() => this.paymentModalClose()}>
                        <ModalHeader className="" toggle={() => this.paymentModalClose()}>
                            Choose Payment Option <br />
                            <span className="text-danger">{this.state.errMsgCmt}</span>
                        </ModalHeader>
                        <ModalBody className="border-0">
                            <div className="row">
                                <div className="col-lg-12 col-md-6">
                                    <PayPalScriptProvider options={{ "client-id": PAYPAL_CLIENT_ID,}}>
                                    <PayPalButtons
                                        //fundingSource="paypal"
                                        createOrder={(data, actions) => {
                                            return actions.order.create({
                                                purchase_units: [
                                                    {
                                                        amount: {
                                                            value: this.state.amount,
                                                        },
                                                    },
                                                ],
                                                application_context: {
                                                    shipping_preference: 'NO_SHIPPING'
                                                }
                                            });
                                        }}
                                        onApprove={(data, actions) => {
                                            return actions.order.capture().then((details) => {
                                                const name = details.payer.name.given_name;
                                                {
                                                    details.status === "COMPLETED" &&
                                                    this.onSubmitHandler()
                                                }
                                            });
                                        }}
                                    />
                                    </PayPalScriptProvider>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <div className="">
                                <button type="button" className="btn btn-light" onClick={() => this.paymentModalClose()}>Close</button>
                            </div>
                        </ModalFooter>
                    </Modal>

                </div>
                <Footer />

            </>
        )
    }
}
