import React, { Component } from 'react'
import { Link ,Redirect } from 'react-router-dom'
import axios from "axios";
import face1 from '../../../assets/images/profile-2.webp'
import { APIURL, SOCKETURL } from '../../../components/constants/common';
import { ReactComponent as ArrowRight}  from '../../../assets/images/caret-down-fill.svg'
import socketClient from "socket.io-client";

export default class Sidebar extends Component {
    constructor() {
        super();
        this.state = {
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            user_type: localStorage.getItem("user_type"),
            navigate: false,
            profile_image:"",
            subscribedPlans: {},
            NoImage:false,
            name:"",
            errMsg: "",
            scsMsg: "",
            subChatNotification:0,
            sellerChatNotification:0,
            subUser:[],
            socket:""
        }
    }

    onLogoutHandler = () => {
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("userData");
        localStorage.clear();
        this.setState({
            navigate: true,
        });
    };

    getProfileInfo() {
        if (this.state.user) {

            const formData = new FormData();
            formData.append('token', this.state.token);
            formData.append('id', this.state.user.id);
            var token = this.state.token
            var app_url = APIURL+"buyer/edit-profile"
            axios
                .post(app_url, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    
                    const info = response.data.data;
                    
                    this.setState({
                        profile_image: response.data.data.url_path,
                        name:response.data.data.name,
                        NoImage:true
                    })
                    console.log("bjsbvjx",this.state.profile_image)
                })
                .catch((error) => {
                    this.setState({
                        // errMsg: error.response.data.errors,
                        Loader: false
                    })
                });
        }
    }

    getUserSubscribedPlans() {
        if (this.state.user) {
            var token = this.state.token
            var app_url = APIURL+"get-user-subscription-plan"
            const formData = new FormData();
            formData.append('user_id', this.state.user.id);
           
            axios
                .post(app_url,formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    this.setState({
                        subscribedPlans: response.data.data
                    })
                })
                .catch((error) => {
                    this.setState({
                        //rrMsg: error.response.data.error ,
                    })
                });
        }
    }

    getSubChatCount() {
        if (this.state.user) {
            const formData = new FormData();
            var token = this.state.token
            var app_url = APIURL+"buyer/get-sub-user-unread-message-count"
            axios
                .post(app_url, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                        this.setState({
                            subChatNotification: response.data.data
                        })
                })
                .catch((error) => {
                  
                });
        }
    }

    getSellerChatCount() {
        if (this.state.user) {
            const formData = new FormData();
            var token = this.state.token
            var app_url = APIURL+"buyer/get-seller-unread-message-count"
            axios
                .post(app_url, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                        this.setState({
                            sellerChatNotification: response.data.data
                        })
                })
                .catch((error) => {
                  
                });
        }
    }

    getSubUserCheck() {
        if (this.state.user) {
            const formData = new FormData();
            var token = this.state.token
            var app_url = APIURL+"buyer/check-sub-user-exist-for-chat"
            axios
                .post(app_url, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    this.setState({
                        subUser: response.data.data
                    })
                })
                .catch((error) => {
                  
                });
        }
    }

    configureSocket = () => {
        var socket = socketClient(SOCKETURL);
        
        this.setState({
            socket: socket
        })
        socket.on('message', data => {

            var subUser_id=0;
            if(this.state.subUser.length>0)
            {
               subUser_id=this.state.subUser[0].id;
            }

             if(this.state.subUser.length>0 && data.to==this.state.user.id && subUser_id==data.from && this.props.activePage !== "support_chat")
            {
              
                var subChatNotification=this.state.subChatNotification
                subChatNotification+=1;
                this.setState({
                    subChatNotification: subChatNotification
                })
                
            }
            else if(this.props.activePage !== "seller_chat" && data.to==this.state.user.id && subUser_id!=data.from)
            {
            
                var sellerChatNotification=this.state.sellerChatNotification
                sellerChatNotification+=1;
                this.setState({
                    sellerChatNotification: sellerChatNotification
                })
                
            }
        });

    }

    componentDidMount(){
        this.getProfileInfo()
        this.getUserSubscribedPlans()
        this.getSubUserCheck()
        if(this.props.activePage !== "support_chat"){
         this.getSubChatCount()
        }
        if(this.props.activePage !== "seller_chat"){
         this.getSellerChatCount()
        }
        this.configureSocket()

    }
    
    

    render() {
        const {user ,name} = this.state
       
        if (this.state.navigate) {
            return <Redirect to="/signin" push={true} />;
        }
       
        return (
            <div>

                <div class="text-center profile">
                    <div class="rounded-circle profile__pic">
                    {
                        this.state.NoImage && this.props.data !== undefined && this.props.data.profile_image !== ""
                        ? <img src={this.props.data.profile_image} alt="profile" />
                        : this.state.NoImage && <img src={this.state.profile_image !== "" ? this.state.profile_image : face1} alt="profile" />
                    }
                    </div>
                    <h6>{name}</h6><span class="text-muted text-small">{this.state.subscribedPlans != null ? this.state.subscribedPlans.plan_name : ""}</span>
                    {/*<small>Canada USA</small>*/}
                </div>
                <div class="sidebar">
              
                    <ul>
                       {/* <li><Link to="/buyer" className={this.props.activePage === "dashboard" ? "active" : ""}>Dashboard</Link></li>*/}
                        <li><Link to="/buyer" className={this.props.activePage === "profile" ? "active" : ""}> My Profile</Link></li>
                        <li><Link to="/buyer/wishlist" className={this.props.activePage === "wishlist" ? "active" : ""} > Wishlist</Link></li>
                        {/* <li><Link to="/buyer/profile" className={this.props.activePage === "chat" ? "active" : ""} > Chat</Link></li> */}
                        <li><Link to="/buyer/property" className={this.props.activePage === "property" ? "active" : ""} > Verifications </Link></li>
                        <li><Link to="/buyer/addproperty" className={this.props.activePage === "addproperty" ? "active" : ""} > My Property </Link></li>
                        <li><Link to="/buyer/propertyalert" className={this.props.activePage === "propertyalert" ? "active" : ""} > Property Alert </Link></li>
                        { this.state.user &&  this.state.user.subscriptionPlanDetail && this.state.user.subscriptionPlanDetail.plan_name!="Basic Plan" &&
                        <li><Link to="/buyer/invited" className={this.props.activePage === "invited" ? "active" : ""} > Invited User </Link></li>
                        }
                        <li><Link to="/buyer/subscribe" className={this.props.activePage === "subscribe" ? "active" : ""} > Subscribe</Link></li>
                        <li><a href="#trx_list" data-bs-toggle="collapse">Transaction History<ArrowRight className="img-svg ms-3" alt="Arrow"/></a></li>
                    </ul>
                        
                        <div id="trx_list" class="collapse">
                            <ul className='mb-0 mt-0 ms-4'>
                            <li><Link className={this.props.activePage === "package_history" ? "active" : ""} to="/buyer/package-history">
                                Package
                                </Link>
                            </li>
                            <li>
                                <Link className={this.props.activePage === "request_history" ? "active" : ""} to="/buyer/request-history">
                                Request
                                </Link>
                            </li>
                            <li>
                                <Link className={this.props.activePage === "buyer_added_request_history" ? "active" : ""} to="/buyer/not-listed/request-history">
                                My Property Request
                                </Link>
                            </li>
                            </ul>
                        </div>
                        <ul className='mt-0'>
                        
                           
                            <li><Link to="/buyer/seller-chat" className={this.props.activePage === "seller_chat" ? "active" : ""} > Seller Chat
                            { this.state.sellerChatNotification>0 && 
                                <div class="indicator">{this.state.sellerChatNotification}</div>
                            }
                            </Link></li>
                           
                           { this.state.subUser.length>0 && this.state.subscribedPlans.plan_name=="Premium Plan" &&
                            <li><Link to="/buyer/support-chat" className={this.props.activePage === "support_chat" ? "active" : ""} > Support Chat
                            { this.state.subChatNotification>0 && 
                                <div class="indicator">{this.state.subChatNotification}</div>
                            }
                            </Link></li>
                           }
                            <li><Link to="/buyer/change-password" className={this.props.activePage === "changepassword" ? "active" : ""} > Change Password</Link></li>
                            <li><a href="#" onClick={() => this.onLogoutHandler()}  > Logout</a></li>
                        </ul>
                </div>
                
            </div>
        )
    }
}
