import React, { Component } from 'react'
import axios from "axios";
import { APIURL } from '../../.././constants/common';
import Sidebar from '../Sidebar'
import Navbar from '../Navbar'
import Pagination from "react-js-pagination";
import { Button } from "reactstrap"
import { Modal, ModalBody, ModalHeader, ModalFooter, Input, Spinner } from 'reactstrap'
import Select from 'react-select'
import { Redirect, Link } from 'react-router-dom'
import { ReactComponent as EyeIcon} from '../../../../assets/images/eye.svg';
import { ReactComponent as CommentIcon} from '../../../../assets/images/comment.svg';
import { ReactComponent as AssignIcon} from '../../../../assets/images/assign.svg';
import { ReactComponent as FileIcon} from '../../../../assets/images/file-text.svg';
import { ReactComponent as DropDown} from '../../../../assets/images/three-dots-vertical.svg';
import dateFormat, { masks } from "dateformat";

const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isbuyer_id }) => {
        return {
            ...styles,
            backgroundColor: isFocused ? "#B8BCBD" : null,
            color: "grey",
        };
    },
};

export default class Index extends Component {
    constructor() {
        super();
        this.state = {
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            buyerList: [],
            addSubUser: false,
            UserList: [],
            errMsg:"",
            scsMsg:"",
            activePage: 1,
            limit: 0,
            totalItemsCount: 0,
            search: "",
            noRecordStatus:false,
            buyer_id:"",
            Loader:false,
            user_id:"",
            redirect: false,
            search_user_id:"",
        }
    }

    componentDidMount() {
        window.scrollTo(0,0)
        this.getUsertList();
        this.getBuyerList()
    }

    handleSearch(e) {
        this.setState(
        { search: e.target.value, activePage: 1 }
        , () => { this.getBuyerList() });
    }

   handlePageChange(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        this.setState(
            { activePage: pageNumber }
            , () => {
                this.getBuyerList()
              
            }
        );
    }

    getBuyerList() {
        var token = this.state.token
        const formData = new FormData();
        formData.append('page', this.state.activePage);
        formData.append('search', this.state.search);
        formData.append('sub_user_id', this.state.search_user_id);
        formData.append('limit', this.state.limit);

        axios
            .post(APIURL + "admin/get-buyer-list-for-sub-user-assign", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                window.scrollTo(0,0)
                this.setState({
                    buyerList: response.data.data.data,
                    activePage: response.data.data.current_page,
                    totalItemsCount: response.data.data.total,
                    limit: response.data.data.per_page,
                    noRecordStatus:true,
                    Loader: false,
                })
            })
    }
   
    getUsertList() {
        var token = this.state.token
        const formData = new FormData();
       
         axios
            .post(APIURL + "admin/get-sub-user-list-for-buyer-assign",formData,{
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
            //   console.log("dropdown",response.data.data)
                this.setState({
                    UserList: response.data.data,
                    Loader: false
                })
           
            })
    }

    addSubUser(item) {
        this.setState({
            addSubUser: !this.state.addSubUser,
            buyer_id:item.id,
            user_id:"",
            errMsg:""
        })
    }
    // addSubUser close
    ModalClose() {
        this.setState({
            addSubUser: false
        })
    }
    
    handleSubUser(e) {
        this.setState({
            user_id: e,
            errMsg:"",
        })
      
    }

    handleSearchSubUser(e) {
        this.setState({
            search_user_id: e
        }
        , () => { this.getBuyerList() });
    }
    
    SubmitSubUserList= () => {
       
        this.setState({ Loader: true, });
        var token = this.state.token
         const formData = new FormData();
        formData.append('buyer_id', this.state.buyer_id);
        formData.append('sub_user_id', this.state.user_id);
        axios
            .post(APIURL + "admin/assign-buyer-to-sub-user", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                this.setState({
                    scsMsg: response.data.message,
                    Loader: false,
                   
                })
                
                setTimeout(() =>{
                    this.setState({
                      scsMsg:"",
                      addSubUser:false,
                    })
                    this.getBuyerList();
                },3000);
                
               
            })
            .catch((error) => {
                this.setState({
                   errMsg: error.response.data.error,
                    // Loader: false,
                    Loader:true
                })
               
            });
    };
    
    render() {
      
        if (!this.state.user) {
            return <Redirect to="/signin" />;
        }
       
        return (
          <div class="admin">
             {this.state.Loader ? <div className="loader"> <Spinner type="grow" color="primary" style={{ width: '3rem', height: '3rem' }} /> </div> : ""}
                <Sidebar activePage="buyerlist" />
                  <div class="content-area">
                    <Navbar />
                     <div class="content">
                        <div class="card">
                            <div class="card-body">
                                <div class="d-flex justify-content-between align-items-center">
                                    <h2 id="page_headeing"><span>Buyer List</span></h2>
                                    <div class="row align-items-end w-500">
                                        <div className="col-6">
                                            <div className="small"></div>
                                        <Input 
                                                    type="text" 
                                                    onChange={(e) => this.handleSearch(e)} 
                                                    placeholder="Search" 
                                                    className="form-control form-control_new border-0 border-end-0"
                                                />
                                        </div>
                                        <div className="col-6">
                                        <div className="small">SubUser List</div>
                                        <select  className="form-control form-control_new border-0 border-end-0" 
                                        onChange={(e) => this.handleSearchSubUser(e.target.value)}>
                                                <option value="">Select SubUser</option>
                                            {this.state.UserList.length > 0 && this.state.UserList.map((option) => (
                                                <option value={option.id}>{option.name+ " "+option.last_name }</option>
                                            ))}
                                        </select>
                                        </div>
                                       
                                    </div>
                                </div>
                                <hr />
                                    <div class="table-responsive">
                                        <table class="table table-striped table-borderless">
                                            <thead>
                                                <tr class="border-bottom">
                                                    <th>Full Name</th>
                                                    <th>Country</th>
                                                    <th>Registeration Date</th>
                                                    <th>Assigned To</th>
                                                   <th width="70px">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.buyerList.length > 0 && this.state.buyerList.map((item, idx) => (
                                                    <React.Fragment>
                                                        <tr >
                                                            <td className="text-left" >{item.fullName}</td>
                                                            <td>{item.country_name}</td>
                                                           <td>{dateFormat(item.created_at)}</td>

                                                            <td>  {item.sub_user_name? item.sub_user_name : "Not Assigned"} </td>
                                                           
                                                            <td>
                                                            <div class="navbar py-0 no_navbar text-center">
                                                                <div class="dropdown">
                                                                    <a class="dropdown-toggle btn-rounded btn btn-default" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <DropDown class="img-svg" />
                                                                </a>
                                                                     <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                                    <div className='mb-2'>
                                                                        <Button color='default' className="btn-rounded mb-0 btn btn-default ms-0"
                                                                         onClick={() => this.addSubUser(item)}><AssignIcon class="img-svg" />
                                                                        </Button><span>Assign SubUser</span></div>
                                                                  </div>
                                                                </div>
                                                            </div>
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                )) }
                                               { this.state.buyerList.length === 0 && this.state.noRecordStatus==true &&
                                                    <tr>
                                                        <td colSpan="6" className="text-center">
                                                            No Request Available
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="mt-4 text-center">

                                        {this.state.totalItemsCount > 0 &&
                                            <div class="justify-content-center d-flex">
                                                <Pagination
                                                    activePage={this.state.activePage}
                                                    itemsCountPerPage={this.state.limit}
                                                    totalItemsCount={this.state.totalItemsCount}
                                                    pageRangeDisplayed={5}
                                                    onChange={this.handlePageChange.bind(this)}
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                    innerClass="pagination justify-content-center"
                                                    activeLinkClass="active"
                                                    nextPageText="Next"
                                                    prevPageText="Previous"
                                                />
                                            </div>
                                        }
                                    </div>
                            </div>
                          
                        </div>
                    </div>
                </div>


                <Modal size="md" isOpen={this.state.addSubUser} toggle={() => this.ModalClose()} autoFocus={false}>
                    <ModalHeader className="header-less" toggle={() => this.ModalClose()}>Assign SubUser</ModalHeader>
                    <ModalBody className="border-0 h-auto">
                    {this.state.scsMsg ? <div class="text-success"> {this.state.scsMsg} </div> : ""}
                                                                 
                        <div className="mb-4">
                                    <label>Select SubUser</label>
                           <select className="form-control " onChange={(e) => this.handleSubUser(e.target.value)}>
                                        <option value="">Select SubUser</option>
                                    {this.state.UserList.length > 0 && this.state.UserList.map((option) => (
                                         <option value={option.id}>{option.name +" "+option.last_name }</option>
                                    ))}
                                    </select>
                                     
                               <span className="text-danger">{this.state.errMsg.sub_user_id}</span>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                           
                             <button className="btn btn-light me-2" onClick={() => this.ModalClose()}> Close </button>
                             <button className="btn btn-primary" onClick={() => this.SubmitSubUserList()}> Submit</button>
                        </div>
                    </ModalFooter>
                </Modal>
         </div>
        )
    }
}
