import React, { Component } from 'react'
import { Redirect } from 'react-router';
import Sidebar from './Sidebar'
import axios from "axios";
import { APIURL } from '../../../components/constants/common';
import Navbar from './Navbar'
import Button from '@restart/ui/esm/Button';
import { Spinner, Input } from 'reactstrap'
import dateFormat, { masks } from "dateformat";
import DatePicker from "react-datepicker";
import Pagination from "react-js-pagination";

export default class TransactionHistory extends Component {
    constructor() {
        super();
        this.state = {
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            user_type: localStorage.getItem("user_type"),
            navigate: false,
            errMsg: "",
            scsMsg: "",
            profile_image: "",
            fullscrLoader: false,
            transactionRecords: [],
            activePage: 1,
            limit: 0,
            totalItemsCount: 0,
            userType: "",
            selectedPlan: "",
            plans: [],
            selectedDate: "",
            showDate: "",
            noRecordStatus: false
        }
    }

    componentDidMount() {
        this.getTransactionHistory()
        this.getSubscriptionPlans()
    }

    onLogoutHandler = () => {

        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("userData");
        localStorage.clear();
        this.setState({
            navigate: true,
        });
    };

    handlePageChange(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        this.setState(
            { activePage: pageNumber }
            , () => {
                this.getTransactionHistory()
            }
        );
    }

    handleUserType(e) {
        this.setState({
            userType: e,
            activePage: 1,
        },()=>{
            this.getTransactionHistory()
        })
    }

    handlePlanType(e) {
        this.setState({
            selectedPlan: e,
            activePage: 1,
        },()=>{
            this.getTransactionHistory()
        })
    }

    handleSelectedDate(e) {
        this.setState({
            showDate: e,
            selectedDate: dateFormat(e, "yyyy-mm-dd"),
            activePage: 1,
        },()=>{
            this.getTransactionHistory()
        })
    }

    clearDate(){
        this.setState({
            showDate: "",
            selectedDate: "",
            activePage: 1,
        },()=>{
            this.getTransactionHistory()
        })
    }

    getTransactionHistory() {
        if (this.state.user) {
            const formData = new FormData();
            formData.append('userfilter', this.state.userType);
            formData.append('planfilter', this.state.selectedPlan);
            formData.append('datefilter', this.state.selectedDate);
            formData.append('page', this.state.activePage);
            formData.append('limit', this.state.limit);
            var token = this.state.token
            var app_url = APIURL+"admin/get-purchase-subscription-plan-list"
            axios
                .post(app_url, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    // const info = response.data.data;
                    console.log('Expired Date', response.data.data.data,)
                    this.setState({
                        transactionRecords: response.data.data.data,
                        activePage: response.data.data.current_page,
                        totalItemsCount: response.data.data.total,
                        limit: response.data.data.per_page,
                        noRecordStatus: true
                    })
                })
                .catch((error) => {
                    this.setState({
                        // errMsg: error.response.data.errors,
                        Loader: false,
                        noRecordStatus: true
                    })
                });
        }
    }

    getSubscriptionPlans() {
        if (this.state.user) {
            var token = this.state.token
            var app_url = APIURL+"get-subscription-plan-list-for-filter"
            const formData = new FormData();
            // formData.append('token', this.state.token);
           
           axios
                .post(app_url,null, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {

                    this.setState({
                        plans: response.data.data
                    })
                })
                .catch((error) => {
                    this.setState({
                        errMsg: error.response.data.error ,
                    })
                });
        }
    }

    render() {
         if (!this.state.user) {
            return <Redirect to="/signin" />;
        }
        return (
           <div className="admin">
           
             {this.state.Loader ? <div className="loader"> <Spinner type="grow" color="dark" style={{ width: '3rem', height: '3rem' }} /> </div> : ""}


               <Sidebar activePage="transaction_history" />
                <div class="content-area">
                 <Navbar />

                    <div class="content">
                        <div class="card">
                            <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <h2 id="page_headeing mb-0"><span>Transaction History</span></h2>
                                <div class="row align-items-end w-500">
                                    <div class="col-4">
                                        <div class="small">Transaction Date</div>

                                        {/* <input
                                            className="form-control form-control_new border-0 border-end-0"
                                            required=""
                                            type="date"
                                            name="dateFilter"
                                            value={this.state.selectedDate}
                                            placeholder="Select Date"
                                            onChange={(e) =>this.handleSelectedDate(e.target.value)}
                                        /> */}
                                        <div className="position-relative">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                selected={this.state.showDate}
                                                //isClearable={true}
                                                onSelect={(e) =>this.handleSelectedDate(e)}
                                                placeholderText="Transaction Date"
                                                className="form-control form-control_new border-0 border-end-0"
                                            />
                                        <button onClick={() =>this.clearDate()} className="cleardate">x</button>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="small">User Type</div>
                                        <select className="form-control form-control_new border-0 border-end-0" onChange={(e) => this.handleUserType(e.target.value)}>
                                            <option value="">Select User Type</option>
                                            <option value="seller">Seller</option>
                                            <option value="buyer">Buyer</option>
                                        </select>
                                    </div>
                                    <div class="col-4">
                                        <div class="small">Plan Name</div>
                                        <select className="form-control form-control_new border-0" onChange={(e) => this.handlePlanType(e.target.value)}>
                                        <option value="">Select Plan</option>
                                        {this.state.plans.length > 0 && this.state.plans.map((plan, idx) => (
                                            <> 
                                                <option value={plan.type}>{plan.plan_name}</option>
                                            </>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div>
                            <div class="table-responsive">
                                <table class="table table-striped table-borderless">
                                <thead>
                                    <tr class="border-bottom">
                                        <th style={{minWidth:70}}>Sr. No.</th>
                                        <th style={{minWidth:120}}>Transaction Date</th>
                                        <th style={{minWidth:100}}>User Type</th>
                                        <th style={{minWidth:100}}>User Name</th>
                                        <th style={{minWidth:100}}>Plan Name</th>
                                        <th style={{minWidth:100}}>Amount (£)</th>
                                        <th style={{minWidth:120}}>Coupon Applied</th>
                                        <th style={{minWidth:100}}>End Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.transactionRecords.length > 0 && this.state.transactionRecords.map((item, idx) => (
                                    <>
                                    <tr>
                                    <td>{idx+1}</td>
                                    <td>{dateFormat(item.created_at, "mmm d, yyyy")}</td>
                                    <td>{item.user_type}</td>
                                    <td>{item.user_name}</td>
                                    <td>{item.plan_name}</td>
                                    <td>{item.total_amount}</td>
                                    <td>{ item.coupon_code !== null &&
                                            <span class="badge bg-success">Yes</span>
                                    }</td>
                                    <td>{dateFormat(item.expired_date, "mmm d, yyyy")}</td>
                                    </tr>
                                    </>
                                ))}
                                { this.state.transactionRecords.length === 0 && this.state.noRecordStatus==true &&
                                    <tr className="text-center">
                                        <td colSpan="8">
                                            No record found
                                        </td>
                                    </tr>
                                }
                                </tbody>
                                </table>
                            </div>
                            <div class="mt-4 text-center">
                                {/*<ul class="pagination justify-content-center">
                                    <li class="page-item"><a class="page-link active" href="#">1</a></li>
                                    <li class="page-item"><a class="page-link" href="#" >2</a></li>
                                    <li class="page-item"><a class="page-link" href="#" >3</a></li>
                                </ul>*/}
                                {this.state.totalItemsCount>0 && 
                                    <div class="justify-content-center d-flex">
                                    <Pagination
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={this.state.limit}
                                        totalItemsCount={this.state.totalItemsCount}
                                        pageRangeDisplayed={5}
                                        onChange={this.handlePageChange.bind(this)}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        innerClass="pagination justify-content-center"
                                        activeLinkClass="active"
                                        nextPageText="Next"
                                        prevPageText="Previous"
                                    />
                                    </div>
                                }
                            </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>

                          
            </div>
        )
    }
}