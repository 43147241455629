import React, { Component } from 'react'
import axios from "axios";
import { APIURL } from '../../../components/constants/common';
import { Redirect, Link } from 'react-router-dom'
import genhavenWhite from '../../../assets/images/genhaven-white.webp'
import {ReactComponent as GeoAlt  } from '../../../assets/images/geo-alt.svg'
import Image1 from '../../../assets/images/image-1.webp'
import {ReactComponent as Heart  } from '../../../assets/images/heart.svg'
import {ReactComponent as Size  } from '../../../assets/images/size.svg'
import {ReactComponent as Property  } from '../../../assets/images/property.svg'
import {ReactComponent as Bed  } from '../../../assets/images/bed.svg'
import {ReactComponent as Bathroom  } from '../../../assets/images/bathroom.svg'
import { ReactComponent as Check} from '../../../assets/images/check-circle1.svg';
import { ReactComponent as Dash} from '../../../assets/images/dash-circle.svg';
import Navbar from '../../Front/TopNavbar'
import Footer from '../../Front/Footer'
import Sidebar from './Sidebar'
import Chat   from './Chat'
import ImageGallery from 'react-image-gallery';
import NumberFormat from 'react-number-format';

export default class Index extends Component {
	constructor(props){
		super(props);
		this.state = {
			stylePath:true,
            propertyDetailsData: [],
            propertyImages: [],
            wishlist: false,
            propertyCheck: [],
            propertyVideos: [],
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            seller_id:0
		}
        this.getPropertyDetails = this.getPropertyDetails.bind(this);
        this.changePropes = this.changePropes.bind(this);
	}

    getPropertyDetails(id) {
        const formData = new FormData();
        formData.append('property_id', id);
        var token = this.state.token
        axios
            .post(APIURL + "view-property-detail", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {

                console.log(response.data.data)

                this.setState({
					propertyDetailsData: response.data.data,
                    seller_id:response.data.data[0].user_id

				})
                let tempImage = []
                let tempCheck = []
                let tempVideo = []

                this.state.propertyDetailsData.map((media, mediaIndex) => (
                    media.property_media.map((propImage, imageIndex) => (
                        propImage.type === "image" &&
                            tempImage.push({
                                original: propImage.url_path,
                                thumbnail: propImage.url_path
                            })
                    ))
                ))

                this.state.propertyDetailsData.map((video, videoIndex) => (
                    video.property_media.map((propVideo, Index) => (
                        propVideo.type === "video" &&
                            tempVideo.push({
                                src: propVideo.url_path
                            })
                    ))
                ))
                
                this.state.propertyDetailsData.map((checkStatus, checkIndex) => (
                    checkStatus.property_checkbox_feature.map((propCheck, checkBoxIndex) => (
                        propCheck.status === "true" || propCheck.status === true ?
                            tempCheck.push({
                                name: propCheck.name,
                                status: propCheck.status
                            }) : ""
                    ))
                ))

                this.setState({
                    propertyImages: tempImage,
                    propertyCheck: tempCheck,
                    propertyVideos: tempVideo
                })
            })
    }

	componentDidMount(){
        window.scrollTo(0,0)
        this.getPropertyDetails(this.props.match.params.id) 
      
	}

    changePropes(item)
    {
        let currentUrlParams = new URLSearchParams(window.location.search);
        currentUrlParams.set('id', item.id);
        this.getPropertyDetails(item.id) 
      

        this.props.history.push("/realestateproperty/" + this.slug(item.name) + "/" + this.slug(item.property_address.city_name) + "/" + this.slug(item.property_address.state_name) + "/" + item.id);
        window.scrollTo(0,0)
        /*
          this.props.match.params.id=id;
          this.getPropertyDetails(this.props.match.params.id) 
          */
    }

      slug(name)
      {
       if(name==undefined)
        {
          return "undefined";
        }
        else
        {
          return name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '')
        }
      }





	render() {
		//console.log(this.state.propertyCheck)
		return (
		<div>	
		
				{/* Navbar cmp */}
				<Navbar activePage="property-details" />
				{/* header cmp */}
                <div className="content-area">
                    <div className="breadcrumb-area gradient1">
                        <div className="container">
                            <h1>Property Details</h1>
                            {/* <div><small>Select Your Property With filters</small></div> */}
                        </div>
                    </div>

                    <section id="explore_places" className="burger">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                    { this.state.propertyDetailsData.length > 0 && this.state.propertyDetailsData.map((item, index) => (
                                    <div className="property-area property-details-area">
                                        <div className="d-flex justify-content-between align-items-center mb-3 property_basic">
                                        <div>
                                            <h2 className="mb-1">
                                                <span>{item.name}</span>
                                                <small className="text-muted small ms-2">{item.categoryArray.category_name}</small>
                                            </h2>
                                            <div data-bs-toggle="tooltip" title="Location" className="location">
                                            <GeoAlt class="img-svg" alt="" /><span>{item.property_address.state_name}, {item.property_address.city_name}</span> 
                                            </div>
                                        </div>
                                        <div>
                                            <h4 className="price">
                                                <span>{
                                                    item.property_features.denomination === "Dollar" ?
                                                    "$"
                                                    : 
                                                    item.property_features.denomination === "Naira" ?
                                                    "₦"
                                                    :
                                                    item.property_features.denomination === "Pounds" ?
                                                    "£"
                                                    : ""
                                                }
                                                <NumberFormat
                                                    thousandsGroupStyle="thousand"
                                                    value={Math.round(item.property_features.listing_price)}
                                                    displayType="text"
                                                    type="text"
                                                    thousandSeparator={true}
                                                /></span>
                                                {/* <small>Monthly</small> */}
                                            </h4>
                                        </div>
                                        </div>
                                        
                                        <div className="property-slider">
                                            { item.verification_banner === "yes" &&
                                                <span class="bg-warning varify_prop">Verified Premium</span>
                                            }
                                            <ImageGallery 
                                                items = {this.state.propertyImages} 
                                                showPlayButton = {false} 
                                                showFullscreenButton = {false}
                                                showNav = {false}
                                            />
                                            {/* {	item.verification_banner === "yes" && 
    									        <div class="property_verified gradient2"><span>verified</span><small>Premium</small></div>
    								        } */}
                                        </div>

                                        <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between align-items-center">
                                            <div className="mb-3 d-flex justify-content-start align-items-center">
                                                <h4 className="me-2">Features Information</h4>
                                                
                                            </div>
                                            <h5>(<span>{item.property_features.purpose}</span>)</h5>
                                            </div>
                                            <ul className="list-details">
                                            <li>
                                                <div>
                                                <strong>Property Type</strong>
                                                <span>{item.categoryArray.category_name}</span>
                                                </div>
                                            </li>    
                                            <li>
                                                <div>
                                                <strong>Areas</strong>
                                                <span>{item.property_features.size} sq ft</span>
                                                </div>
                                            </li> 
                                            <li>
                                                <div>
                                                <strong>Newly Built</strong>
                                                <span>{item.property_features.newly_built === "true" || item.property_features.newly_built === true ? "Yes" : "No"}</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div>
                                                <strong>Year Built</strong>
                                                <span>{item.property_features.year_built}</span>
                                                </div>
                                            </li>
                                            {(item.property_features.total_bedrooms !== null) && 
                                                <li>
                                                    <div>
                                                    <strong>Bedrooms</strong>
                                                    <span>{item.property_features.total_bedrooms} Beds</span>
                                                    </div>
                                                </li>
                                            }
                                            {(item.property_features.total_bathrooms !== null) && 
                                                <li>
                                                    <div>
                                                    <strong>Bathrooms</strong>
                                                    <span>{item.property_features.total_bathrooms} Bath</span>
                                                    </div>
                                                </li>
                                            }
                                            {(item.property_features.toilets !== null) && 
                                                <li>
                                                    <div>
                                                    <strong>Toilets</strong>
                                                    <span>{item.property_features.toilets}</span>
                                                    </div>
                                                </li>
                                            }
                                            {(item.property_features.parking_space !== null) && 
                                                <li>
                                                    <div>
                                                    <strong>Parking</strong>
                                                    <span>{item.property_features.parking_space}</span>
                                                    </div>
                                                </li>
                                            }
                                            {(item.property_features.stories_of_the_house !== null) && 
                                                <li>
                                                    <div>
                                                    <strong>Stories</strong>
                                                    <span>{item.property_features.stories_of_the_house}</span>
                                                    </div>
                                                </li>
                                            }
                                            {(item.property_features.heating !== null) && 
                                                <li>
                                                    <div>
                                                    <strong>Heating Type</strong>
                                                    <span>{item.property_features.heating}</span>
                                                    </div>
                                                </li>
                                            }
                                            {(item.property_features.cooling !== null) &&
                                                <li>
                                                    <div>
                                                    <strong>Cooling</strong>
                                                    <span>{item.property_features.cooling}</span>
                                                    </div>
                                                </li>
                                            }
                                            {
                                                item.property_features.furnished === "true" || item.property_features.furnished === true ?
                                                <li>
                                                    <div>
                                                    <strong>Furnished</strong>
                                                    <span><Check class="img-svg" alt="" /></span>
                                                    </div>
                                                </li>
                                                : ""
                                            }
                                            {
                                                item.property_features.serviced === "true" || item.property_features.serviced === true ?
                                                <li>
                                                    <div>
                                                    <strong>Serviced</strong>
                                                    <span><Check class="img-svg" alt="" /></span>
                                                    </div>
                                                </li>
                                                : ""
                                            }
                                            {
                                                item.property_features.newly_built === "true" || item.property_features.newly_built === true ?
                                                <li>
                                                    <div>
                                                    <strong>Newly Built</strong>
                                                    <span><Check class="img-svg" alt="" /></span>
                                                    </div>
                                                </li>
                                                :""
                                            }
                                            {(item.property_features.finished !== null) &&
                                                <li>
                                                    <div>
                                                    <strong>Basement<br />Finished</strong>
                                                    <span>{item.property_features.finished}</span>
                                                    </div>
                                                </li>
                                            }
                                            {(item.property_features.walkout !== null) &&
                                                <li>
                                                    <div>
                                                    <strong>Basement<br />Walkout</strong>
                                                    <span>{item.property_features.walkout}</span>
                                                    </div>
                                                </li>
                                            }
                                            {(item.property_features.flooring !== null) &&
                                                <li>
                                                    <div>
                                                    <strong>Flooring</strong>
                                                    <span>{item.property_features.flooring}</span>
                                                    </div>
                                                </li>
                                            }
                                            {(item.property_features.fireplace !== null) &&
                                                <li>
                                                    <div>
                                                    <strong>Fireplace</strong>
                                                    <span>{item.property_features.fireplace}</span>
                                                    </div>
                                                </li>
                                            }
                                            </ul>
                                        </div>
                                        </div>

                                        <div className="card">
                                        <div className="card-body">
                                            <h4 className="mb-3">Description</h4>
                                            <div>
                                            <p>{item.property_features.house_description}</p>
                                            </div>
                                        </div>
                                        </div>

                                        {
                                            this.state.propertyCheck.length > 0 &&
                                            <div className="card">
                                                <div className="card-body">
                                                    <h4 className="mb-3">Other Features Details</h4>
                                                    <div className="list-details row">
                                                    {
                                                        this.state.propertyCheck.length > 0 && this.state.propertyCheck.map((innerItem, index) => (
                                                        innerItem.status === "true" || innerItem.status === true
                                                        ? <div className='col-sm-4 col-6 p-2'>
                                                                <div className="icon">
                                                                    <Check class="img-svg" alt="" />
                                                                    <span className='ms-2'>{innerItem.name}</span>
                                                                </div>
                                                            </div>
                                                        : ""
                                                    ))}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        
                                        {
                                            this.state.propertyVideos.length > 0 && 
                                            <div className="card">
                                            <div className="card-body">
                                                <h4 className="mb-3">Property Video</h4>
                                                {
                                                    item.property_media.map((propVideo, videoIndex) => (
                                                    propVideo.type === "video" &&
                                                    <div className="video mb-3">
                                                    <video controls className='w-100'>
                                                        <source src={propVideo.url_path} type="video/mp4" />
                                                    </video>
                                                    </div>
                                                ))}
                                            </div>
                                            </div>
                                        }
                                    </div>
                                    ))}
                                </div>

                                <div className="col-lg-4">
                                    <Sidebar 
                                        id = {this.props.match.params.id}
                                        changePropes={this.changePropes.bind()}
                                        getPropertyDetails={this.getPropertyDetails}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

				{/* footer cmp */}
				<Footer />
                 {/* chat */}
                  {this.state.user!=null  && this.state.user.user_type=="Buyer" &&  this.state.propertyDetailsData.length > 0 &&
                     <Chat 
                        id = {this.props.match.params.id}
                        seller_id={this.state.seller_id}
                    />
                }
		</div>	
		)
	}
}
