import React, { Component } from 'react'
import axios from "axios";
import { APIURL } from '../../.././constants/common';
import Sidebar from '../Sidebar'
import Navbar from '../Navbar'
import Pagination from "react-js-pagination";
import { Button } from "reactstrap"
import { Modal, ModalBody, ModalHeader, ModalFooter, Input, Spinner } from 'reactstrap'
import Select from 'react-select'
import dateFormat, { masks } from "dateformat";
import { Redirect, Link } from 'react-router-dom'
import { ReactComponent as EyeIcon} from '../../../../assets/images/eye.svg';
import { ReactComponent as Message} from '../../../../assets/images/info.svg';
import { ReactComponent as History} from '../../../../assets/images/clock-history.svg';
import { ReactComponent as CommentIcon} from '../../../../assets/images/comment.svg';
import { ReactComponent as AssignIcon} from '../../../../assets/images/assign.svg';
import { ReactComponent as FileIcon} from '../../../../assets/images/file-text.svg';
import { ReactComponent as DropDown} from '../../../../assets/images/three-dots-vertical.svg';

const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isFocused ? "#B8BCBD" : null,
            color: "grey",
        };
    },
};

export default class BuyerProperty extends Component {
    constructor() {
        super();
        this.state = {
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            PropertyList: [],
            statusArray: [{ 'value': "", "label": "All" }, { 'value': "under_review", "label": "Under Review" }, { 'value': "verify", "label": "Verified" }, { 'value': "reject", "label": "Rejected" }],
            statusDefault: [{ 'value': "", "label": "All" }],
            agentStatusArray: [{ 'value': "", "label": "All" }, { 'value': "under_review", "label": "Under Review" }, { 'value': "verify", "label": "Verified" }, { 'value': "reject", "label": "Rejected" }], 
            agentStatusDefault: [{ 'value': "", "label": "All" }],
            AgentList: [],
            modifyAgentList:[],
            agentReq: [],
            Visible: false,
            activePage: 1,
            limit: 0,
            totalItemsCount: 0,
            search: "",
            status: [],
            agentStatus:"",
            AssignModal: false,
            agent_id: "",
            request_id:"",
            sortby: "DESC",
            sort: false,
            noRecordStatus:false,
            Comments: [],
            commentModal: false,
            messageModal: false,
            buyerMessage: "",
            historyModal: false,
            history: ""
        }
    }

    handleSearch(e) {
        this.setState(
            { search: e.target.value, activePage: 1 }
            , () => { this.getPropertyList() });
    }

    SortBy(e) {
        this.setState({
            sort: !this.state.sort
        }, () => {
            if (this.state.sort) {
                this.setState({
                    sortby: "ASC",
                    columnName: e
                }, () => {
                    this.getPropertyList()
                })

            }
            if (!this.state.sort) {
                this.setState({
                    sortby: "DESC",
                    columnName: e
                }, () => {
                    this.getPropertyList()
                })
            }
        })
    }
    
    handleStatus(e) {
        this.setState(
            {
                activePage:1,
                status: e.value,
                statusDefault: [{ value: e.value, label: e.label }]
            }
            , () => { this.getPropertyList() });
    }

    handleAgentStatus(e) {
        this.setState(
            {
                activePage:1,
                agentStatus: e.value,
                agentStatusDefault: [{ value: e.value, label: e.label }]
            }
            , () => { this.getPropertyList() });
    }

    

    handlePageChange(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        this.setState(
            { activePage: pageNumber }
            , () => {
                this.getPropertyList()
                this.getApprovedAgentList()
            }
        );
    }

    getPropertyList() {
        var token = this.state.token
        const formData = new FormData();
        formData.append('page', this.state.activePage);
        formData.append('search', this.state.search);
        formData.append('statusfilter', this.state.status);
        formData.append('agentstatus', this.state.agentStatus);
        formData.append('limit', this.state.limit);
        formData.append('sortby', this.state.sortby);
        formData.append('sorttype', this.state.columnName ? this.state.columnName : "");

        axios
            .post(APIURL + "admin/get-property-request-list-send-by-buyer", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                window.scrollTo(0,0)
                this.setState({
                    PropertyList: response.data.data.data,
                    activePage: response.data.data.current_page,
                    totalItemsCount: response.data.data.total,
                    limit: response.data.data.per_page,
                    noRecordStatus:true
                })
            })
    }

    getApprovedAgentList() {
        const token = this.state.token
        const formData = new FormData();
        formData.append('page', this.state.activePage);
        formData.append('search', this.state.search);
        formData.append('status', this.state.status);
        formData.append('limit', this.state.limit);
        axios
            .post(APIURL + "admin/get-agent-list", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                if (response.data.data && response.data.data.length > 0) {
                    this.setState({
                        AgentList: response.data.data
                    });
                }
            });
    }

    handleAgentName(e) {
        console.log(e)
        this.setState({
            agent_id: e,

        })
    };

    ActiveDrop = (e) => {
        
        let modifyAgentList=this.state.AgentList.filter(person => person.id !== e.agent_id);

        this.setState({
            request_id: e.id,
            AssignModal: true,
            modifyAgentList:modifyAgentList
        })
    }

    ModalClose() {
        this.setState({
            AssignModal: false
        })
    }

    commentModal(item) {
        this.setState({
            commentModal: !this.state.commentModal,
            Comments: item
        })
    }

    commentModalClose() {
        this.setState({
            commentModal: false
        })
    }

    messageModal(item) {
        this.setState({
            messageModal: !this.state.messageModal,
            buyerMessage: item.message
        })
    }

    messageModalClose() {
        this.setState({
            messageModal: false
        })
    }

    historyModalClose() {
        this.setState({
            historyModal: false
        })
    }

    SubmitProperty = () => {
        var token = this.state.token
        const formData = new FormData();
        formData.append('request_id', this.state.request_id);
        formData.append('agent_id', this.state.agent_id);
        this.setState({ Loader: true });
        axios
            .post(APIURL + "admin/property-assign-by-admin", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                console.log(response.data)
                this.setState({
                    Loader: false,
                    AgentInfo: response.data.data,
                    Visible: false,
                    AssignModal: false
                });
                this.getPropertyList();
                console.log(this.state.AgentInfo)
            })
            .catch((error) => {
                this.setState({
                    errMsg: error.response.data.error,
                    Loader: false,
                    Visible: false
                })
            });
    };

    getVerificationHistory = (id) => {
        var token = this.state.token
        const formData = new FormData();
        formData.append('property_id', id);
        this.setState({ Loader: true });
        axios
            .post(APIURL + "admin/get-agent-history-for-property", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                this.setState({
                    historyModal: !this.state.historyModal,
                    history: response.data.data
                })
            })
            .catch((error) => {
                // this.setState({
                //     errMsg: error.response.data.error,
                //     Loader: false,
                //     Visible: false
                // })
            });
    };

    componentDidMount() {
        window.scrollTo(0,0)
        this.getApprovedAgentList();
        this.getPropertyList()
    }



    render() {
        if (!this.state.user) {
            return <Redirect to="/signin" />;
        }
        return (
          <div class="admin">
             {this.state.Loader ? <div className="loader"> <Spinner type="grow" color="dark" style={{ width: '3rem', height: '3rem' }} /> </div> : ""}
                <Sidebar activePage="buyerproperty" />
                  <div class="content-area">
                    <Navbar />
                     <div class="content">
                        <div class="card">
                            <div class="card-body">
                                <div class="d-sm-flex justify-content-between align-items-center">
                                    <h2 id="page_headeing"><span>Buyer Property</span></h2>
                                    <div class="row align-items-end w-500">
                                        <div className="col-4">
                                            <div className="small"></div>
                                            <Input 
                                                type="text" 
                                                onChange={(e) => this.handleSearch(e)} 
                                                placeholder="Search" 
                                                className="form-control form-control_new border-0 border-end-0"
                                            />
                                        </div>
                                        <div className="col-4">
                                            <div className="small">Property Status</div>
                                            <Select
                                                aria-label=".form-select-lg example"
                                                required=""
                                                placeholder="Select Status"
                                                className="form-select-control form-control_new border-0 border-end-0"
                                                options={this.state.statusArray}
                                                value={this.state.statusDefault}
                                                styles={colourStyles}
                                                onChange={(e) => this.handleStatus(e)}
                                            />
                                        </div>
                                        <div className="col-4">
                                            <div className="small">Agent Status</div>
                                            <Select
                                                aria-label=".form-select-lg example"
                                                required=""
                                                placeholder="Select Status"
                                                className="form-select-control form-control_new border-0"
                                                options={this.state.agentStatusArray}
                                                value={this.state.agentStatusDefault}
                                                styles={colourStyles}
                                                onChange={(e) => this.handleAgentStatus(e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div class="table-responsive">
                                    <table class="table table-striped table-borderless">
                                        <thead>
                                            <tr class="border-bottom">
                                                <th className="sort-by text-left" style={{ cursor: "pointer", minWidth:120}} onClick={(e) => this.SortBy("name")}> Property Name</th>
                                                <th>Buyer</th>
                                                <th style={{minWidth:120}}>Property Status</th>
                                                <th className="sort-by" style={{ cursor: "pointer", minWidth:100}} onClick={() => this.SortBy("seller_name")}> Assigned To</th>
                                                <th style={{minWidth:120}}>Agent Status</th>
                                                <th width="70px">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.PropertyList.length > 0 && this.state.PropertyList.map((item, idx) => (
                                                <React.Fragment>
                                                    <tr >
                                                        <td className="text-left" >{item.property_name}</td>
                                                        <td>{item.seller_name}</td>
                                                        <td>
                                                            {
                                                                item.status === "verify" ?
                                                                    <span class="badge bg-success">Verified</span>
                                                                :
                                                                    item.status === "under_review"?
                                                                    <span class="badge bg-warning">Under Review</span>
                                                                :
                                                                    item.status === "reject" ?
                                                                    <span class="badge bg-danger">Rejected</span>
                                                                :
                                                                    ""                                                                        
                                                            }
                                                        </td>

                                                        <td>  {item.agent_name ? item.agent_name : "Not Assigned"} </td>
                                                        <td >
                                                            {
                                                                item.agent_assign_status === "verify" ?
                                                                    <span class="badge bg-success">Verified</span>
                                                                :
                                                                    item.agent_assign_status === "under_review" ?
                                                                    <span class="badge bg-warning">Under Review</span>
                                                                :
                                                                    item.agent_assign_status === "reject" ?
                                                                    <span class="badge bg-danger">Rejected</span>
                                                                :
                                                                    ""      
                                                            }
                                                        </td>
                                                        <td>
                                                        <div class="navbar py-0 no_navbar text-center">
                                                            <div class="dropdown">
                                                                <a class="dropdown-toggle btn-rounded btn btn-default" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <DropDown class="img-svg" />
                                                            </a>

                                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                            {item.agent_assign_status !== "verify" &&
                                                                <div className='mb-2'><Button color='default' className="btn-rounded mb-0 btn btn-default ms-0" onClick={() => this.ActiveDrop(item)}><AssignIcon class="img-svg" /></Button><span>Assign Agent</span></div>
                                                            }
                                                            {item.admin_comment !== null || item.agent_comment !== null ?
                                                                <div className='mb-2'><Button color='default'  className="btn-rounded mb-0 btn btn-default" onClick={() => this.commentModal(item)}><CommentIcon class="img-svg" /></Button><span>Comment</span></div>
                                                                : ""
                                                            }
                                                            {
                                                                item.agent_assign_status === "verify" &&
                                                                <div className='mb-2'><a href={"/common/property-certificate/" + item.property_id + "/" + item.id} target="_blank" className="btn mb-0 btn-rounded btn-default ms-0"><FileIcon class="img-svg" /></a><span>Certificate</span></div>
                                                            }
                                                            {item.message !== null &&
                                                                <div className='mb-2'><Button color='default'  className="btn-rounded mb-0 btn btn-default" onClick={() => this.messageModal(item)}><Message class="img-svg" /></Button><span>Buyer Message</span></div>
                                                            }
                                                            <div className='mb-2'><Button color='default'  className="btn-rounded mb-0 btn btn-default" onClick={() => this.getVerificationHistory(item.property_id)}><History class="img-svg" /></Button><span>Verification History</span></div>
                                                            <div><Link to={"/admin/propertybybuyer/details/" + item.id} className="btn mb-0 btn-info btn-rounded"><EyeIcon class="img-svg" /></Link><span>Details</span></div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                            
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            )) }
                                           { this.state.PropertyList.length === 0 && this.state.noRecordStatus==true &&
                                                <tr className="text-center">
                                                    <td colSpan="6">
                                                        No Request Available
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div class="mt-4 text-center">
                                    {this.state.totalItemsCount > 0 &&
                                    <div class="justify-content-center d-flex">
                                        <Pagination
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.state.limit}
                                            totalItemsCount={this.state.totalItemsCount}
                                            pageRangeDisplayed={5}
                                            onChange={this.handlePageChange.bind(this)}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            innerClass="pagination justify-content-center"
                                            activeLinkClass="active"
                                            nextPageText="Next"
                                            prevPageText="Previous"
                                        />
                                    </div>
                                    }
                                </div>
                            </div>                          
                        </div>
                    </div>
                </div>


                <Modal size="md" isOpen={this.state.AssignModal} toggle={() => this.ModalClose()}>
                    <ModalHeader className="header-less" toggle={() => this.ModalClose()}>Assign Property</ModalHeader>
                    <ModalBody className="border-0 h-auto">
                        <select className="form-control" onChange={(e) => this.handleAgentName(e.target.value)} >
                            <option value="">Select</option>
                            {this.state.modifyAgentList.map((option) => (
                                <option value={option.id}>{option.fullName}</option>
                            ))}
                        </select>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                          
                            <button onClick={() => this.ModalClose()} className="btn btn-default me-2">Close </button>
                            <button onClick={() => this.SubmitProperty()} className="btn btn-primary"> Assign</button>
                        </div>
                    </ModalFooter>
                </Modal>

                <Modal size="md" isOpen={this.state.commentModal} toggle={() => this.commentModalClose()}>
                    <ModalHeader className="" toggle={() => this.commentModalClose()}>
                        Comment <br />
                        <span className="text-danger">{this.state.errMsgCmt}</span>
                    </ModalHeader>
                    <ModalBody className="border-0">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <div className="input-with-icon">
                                    {   
                                        this.state.Comments.agent_comment !== null 
                                        ?
                                        <>
                                        <label>Agent Comment</label>
                                            <textarea
                                                rows="4"
                                                className="form-control h-auto"
                                                required=""
                                                type="textarea"
                                                name="comment"
                                                value={this.state.Comments.agent_comment}
                                                disabled
                                            />
                                        </>
                                        : ""
                                    }
                                    {   
                                        this.state.Comments.admin_comment !== null 
                                        ?
                                        <>
                                        <label>My Comment</label>
                                            <textarea
                                                rows="4"
                                                className="form-control h-auto"
                                                required=""
                                                type="textarea"
                                                name="comment"
                                                value={this.state.Comments.admin_comment}
                                                disabled
                                            />
                                        </>
                                        : ""
                                    }
                                        {/* <i class="fas fa-address-card"></i> */}
                                    </div>
                                    {/* <span className="text-danger">{this.state.errMsg.address}</span> */}
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button type="button" className="btn btn-light" onClick={() => this.commentModalClose()}>Close</button>
                        </div>
                    </ModalFooter>
                </Modal>

                <Modal size="md" isOpen={this.state.messageModal} toggle={() => this.messageModalClose()}>
                    <ModalHeader className="" toggle={() => this.messageModalClose()}>
                        Buyer Message <br />
                        <span className="text-danger">{this.state.errMsgCmt}</span>
                    </ModalHeader>
                    <ModalBody className="border-0">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <div className="input-with-icon">
                                            <textarea
                                                rows="4"
                                                className="form-control h-auto"
                                                required=""
                                                type="textarea"
                                                name="comment"
                                                value={this.state.buyerMessage}
                                                disabled
                                            />
                                        {/* <i class="fas fa-address-card"></i> */}
                                    </div>
                                    {/* <span className="text-danger">{this.state.errMsg.address}</span> */}
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button type="button" className="btn btn-light" onClick={() => this.messageModalClose()}>Close</button>
                        </div>
                    </ModalFooter>
                </Modal>

                <Modal className='modalScroller' size="md" isOpen={this.state.historyModal} toggle={() => this.historyModalClose()}>
                    <ModalHeader className="" toggle={() => this.historyModalClose()}>
                        Verification History<br />
                        <span className="text-danger">{this.state.errMsgCmt}</span>
                    </ModalHeader>
                    <ModalBody className="border-0">
                        <div className="row">
                            <div className="col-lg-12">
                                <div class="table-responsive">
                                    <table class="table table-striped table-borderless">
                                        <thead>
                                            <tr class="border-bottom">
                                                <th>Verified By</th>
                                                <th>Verified At</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.history.length > 0 && this.state.history.map((item, idx) => (
                                                <React.Fragment>
                                                    <tr >
                                                        <td className="text-left" >{item.agent_name}</td>
                                                        <td>{dateFormat(item.created_at)}</td>
                                                    </tr>
                                                </React.Fragment>
                                            )) }
                                            { this.state.history.length === 0 &&
                                                <tr>
                                                    <td colSpan="6" className="text-center">
                                                        No Record Available
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button type="button" className="btn btn-light" onClick={() => this.historyModalClose()}>Close</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}
