import React, { Component } from 'react'
import { Button, Spinner, Input } from 'reactstrap'
import axios from "axios";
import { APIURL } from '../../../../components/constants/common';
// import { Helmet } from "react-helmet";

export default class PropertyInfo extends Component {
    constructor() {
        super();

        this.state = {
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            listingPrice: "",
            hoa_free: "",
            featureTypeId: "",
            countryId: "",
            countries_name: [],
            Languages: [],
            Countries: [],
            States: [],
            state_name: [],
            state: "",
            name: "",
            image: "",
            selectType: "",
            errMsg: {},
            scsMsg: "",
            country_id: "",
            flooring: "",
            fireplace: "",
            swimmingPool: "",
            propertyStatus: "",
            totalBathRooms: "",
            totalBedRooms: "",
            toilets: "",
            furnished: false,
            serviced: false,
            newly_built: false,
            property_checkbox_feature: [],
        }
    }

    componentDidMount() {
    
        this.getPropertyFeature()
    }
    handleListingPrice = (e) =>{
        this.setState({listingPrice:e})
    }
    handleHoa = (e) =>{
        this.setState({hoa_free:e})
    }
    handleFlooring = (e) => {
        this.setState({  flooring:e })
    }

    handleFeatureType(e) {
        this.setState({ featureTypeId: e })
    }
    handleTotalBedrooms = (e) => {
        this.setState({ totalBedRooms: e })
    }

    handleTotalBathRooms = (e) => {
        this.setState({  totalBathRooms: e })
    }

    handleToilets = (e) => {
        this.setState({  toilets: e })
    }

    handleFullBath = (e) => {
        this.setState({ fullBath: e })
    }

    handleHalfBath = (e) => {
        this.setState({ halfBath: e })
    }

    handleCooling = (e) => {
        this.setState({ cooling: e })
    }

    handleHeating = (e) => {
        this.setState({ heating: e })
    }
    
    handleParkingSpace = (e) => {
        this.setState({ parkingSpace: e })
    }
    
    handleWalkout = (e) => {
        this.setState({
            walkout: e
        })
    }
    handleStoryHouse = (e) => {
        this.setState({
            storiesOfHouse: e
        })
    }
    handleFinished = (e) => {
        this.setState({
            finished:e
        })
    }

    handleFurnished = (e) => {
        console.log(e)
        this.setState({
            furnished: e
        })
    }

    handleServiced = (e) => {
        console.log(e)
        this.setState({
            serviced: e
        })
    }

    handlenewlyBuilt = (e) => {
        console.log(e)
        this.setState({
            newly_built: e
        })
    }

    handleCheckboxFeatures = (index, val) =>{
        var feature = this.state.property_checkbox_feature
        feature[index].status = val
        this.setState({
            property_checkbox_feature: feature
        })

        // console.log("erg", this.state.property_checkbox_feature);
    }


    
    onSubmit() {
        this.props.data.handleActiveTab("4");
    }

    getPropertyFeature() {
        if (this.state.user) {
            const formData = new FormData();
            // formData.append('token', this.state.token);
            formData.append('property_id', this.props.data.property_id);
            var token = this.state.token
            axios
                .post(APIURL + "seller/property-features-edit", formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    const info = response.data.data;
                  
                    this.setState({
                        listingPrice:info.listing_price === "0.00" ? "" :info.listing_price,
                        hoa_free: info.hoa_free,
                        //featureTypeId:info.feature_type === "undefined" ? "" :info.feature_type,
                        totalBedRooms:info.total_bedrooms === null ? "" :info.total_bedrooms,
                        totalBathRooms:info.total_bathrooms === null ? "" :info.total_bathrooms,
                        fullBath:info.full_bath === null ? "" :info.full_bath,
                        halfBath:info.half_bath === null ? "" :info.half_bath,
                        toilets:info.toilets === null ? "" : info.toilets,
                        cooling:info.cooling === null ? "" :info.cooling,
                        heating:info.heating === null ? "" :info.heating,
                        parkingSpace:info.parking_space === null ? "" :info.parking_space,
                        walkout:info.walkout === null ? "" :info.walkout,
                        storiesOfHouse:info.stories_of_the_house === null ? "" :info.stories_of_the_house,
                        finished:info.finished === null ? "" :info.finished,
                        flooring: info.flooring === null ? "" :info.flooring,
                        fireplace: info.fireplace === null ? "" :info.fireplace,
                        swimmingPool: info.swimming_pool === null ? "" :info.swimming_pool,
                        propertyStatus: info.property_request_status,
                        furnished: info.furnished,
                        serviced: info.serviced,
                        newly_built: info.newly_built,
                        property_checkbox_feature: info.property_checkbox_feature
                    })
                    this.props.data.getStatus(info.property_request_status);
                })
                .catch((error) => {
                    this.setState({
                        errMsg: error.response.data.errors,
                        Loader: false
                    })
                });
        }
    }
    
    handleFlooring(e) {
        this.setState({
            flooring: e
        })
    }
    handleFirePlace(e) {
        this.setState({
            fireplace: e
        })
    }
    handlePool(e) {
        this.setState({
            swimmingPool: e
        })
    }

    onSubmitHandler = (e) => {
        var token = this.state.token
        const { userInfo, user } = this.state;
        const formData = new FormData();
        formData.append('listing_price', this.state.listingPrice ? this.state.listingPrice : "");
        formData.append('hoa_free', this.state.hoa_free ? this.state.hoa_free : "");
        //formData.append('feature_type', this.state.featureTypeId ? this.state.featureTypeId : "");
        formData.append('total_bedrooms', this.state.totalBedRooms ? this.state.totalBedRooms : "");
        formData.append('total_bathrooms', this.state.totalBathRooms ? this.state.totalBathRooms : "");
        formData.append('full_bath', this.state.fullBath ? this.state.fullBath : "");
        formData.append('half_bath', this.state.halfBath ? this.state.halfBath : "");
        formData.append('furnished', this.state.furnished ? this.state.furnished : "");
        formData.append('serviced', this.state.serviced ? this.state.serviced : "");
        formData.append('newly_built', this.state.newly_built ? this.state.newly_built : "");
        formData.append('toilets', this.state.toilets ? this.state.toilets : "");
        formData.append('cooling', this.state.cooling ? this.state.cooling : "");
        formData.append('heating', this.state.heating ? this.state.heating : "");
        formData.append('parking_space', this.state.parkingSpace ? this.state.parkingSpace : "");
        formData.append('walkout', this.state.walkout ? this.state.walkout : "");
        formData.append('finished', this.state.finished ? this.state.finished : "");
        formData.append('stories_of_the_house', this.state.storiesOfHouse ? this.state.storiesOfHouse : "");
        formData.append('property_id', this.props.data.property_id ? this.props.data.property_id : "");
        formData.append('flooring', this.state.flooring ? this.state.flooring : "");
        formData.append('fireplace', this.state.fireplace ? this.state.fireplace : "");
        formData.append('swimming_pool', this.state.flooring ? this.state.flooring : "");
        formData.append('property_checkbox_feature', JSON.stringify(this.state.property_checkbox_feature));
        this.setState({ Loader: true });
        axios
            .post(APIURL + "seller/property-features-update", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                this.setState({
                    errMsg: {},
                    Loader:false
                }, () => {
                    this.props.data.setMessage(response.data.message);
                    this.props.data.handleActiveTab("4");
                    this.props.data.checkPropertyDetails();
                });
            })
            .catch((error) => {
                this.setState({
                    errMsg: error.response.data.error,
                    Loader: false
                })
            });
    };
    render() {
        //console.log(this.props.data.property_id)
        
        return (
            <>
             {/* <Helmet>
                    <link rel="stylesheet" href="http://192.168.1.13/custom.css" />
                </Helmet> */}
             {this.state.Loader ? <div className="loader"> <Spinner type="grow" color="dark" style={{ width: '3rem', height: '3rem' }} /> </div> : ""}
                {/* {this.state.errMsg.message ?
                <div class="alert alert-danger text-center" role="alert">
                {this.state.errMsg.message}
               </div>
               :""}  */}
                <div className={this.props.data.activatedTab === "features" ? "tab-pane fade show" : "tab-pane fade"} id="pills-features" role="tabpanel" aria-labelledby="pills-features-tab">
                    <form class="forms-sample">
                        <div class="row mb-2">
                        <div class="col-12">
                            <h4>Feature Info</h4>
                            <hr />
                        </div>

                        <div class="col-sm-6">
                            <div class="mb-4">
                            <label for="exampleInputPassword4">Bedrooms </label>
                            <select class="form-control" value={this.state.totalBedRooms} onChange={(e) => this.handleTotalBedrooms(e.target.value)}>
                                <option value="">Select</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10+</option>
                            </select>
                            <span className="text-danger">{this.state.errMsg.total_bedrooms}</span>
                            </div>
                        </div>

                        <div class="col-sm-6 ">
                            <div class="mb-4">
                            <label for="exampleInputName1">Bathrooms </label>
                            <select class="form-control" value={this.state.totalBathRooms} onChange={(e) => this.handleTotalBathRooms(e.target.value)}>
                                <option value="">Select</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10+</option>
                            </select>
                            <span className="text-danger">{this.state.errMsg.total_bathrooms}</span>
                            </div>
                        </div>
                        {/* <div class="col-sm-6">
                            <div class="mb-4">
                            <label for="exampleInputEmail3">Type</label>
                            <select class="form-control" value={this.state.featureTypeId} onChange={(e) => this.handleFeatureType(e.target.value)} >
                                <option value="">Select</option>
                                <option value="0">Condo</option>
                                <option value="1">Apartment</option>
                                <option value="2">Single Family</option>
                                <option value="3">Townhouse</option>
                            </select>
                            <span className="text-danger">{this.state.errMsg.number_of_bedrooms}</span>
                            </div>
                        </div> */}
                        <div class="col-sm-6 ">
                            <div class="mb-4">
                            <label for="exampleInputName1">Toilets </label>
                            <select class="form-control" value={this.state.toilets} onChange={(e) => this.handleToilets(e.target.value)}>
                                <option value="">Select</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10+</option>
                            </select>
                            <span className="text-danger">{this.state.errMsg.toilets}</span>
                            </div>
                        </div>

                        <div class="col-sm-6">
                            <div class="mb-4">
                            <label for="exampleInputEmail3">Parking Space </label>
                            <select class="form-control" value={this.state.parkingSpace} onChange={(e) => this.handleParkingSpace(e.target.value)} >
                                <option value="">Select</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10+</option>
                            </select>
                             <span className="text-danger">{this.state.errMsg.parking_space}</span>
                            </div>
                        </div>

                        <div class="col-sm-6">
                            <div class="mb-4">
                            <label for="exampleInputEmail3">Stories Of The House</label>
                            <select class="form-control" value={this.state.storiesOfHouse} onChange={(e) => this.handleStoryHouse(e.target.value)}>
                                <option value="">Select</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5+</option>
                            </select>
                            </div>
                        </div>

                        <div class="col-sm-6">
                            <div class="mb-4">
                            <label for="exampleInputEmail3">Heating</label>
                            <select class="form-control" value={this.state.heating} onChange={(e) => this.handleHeating(e.target.value)}>
                                <option>Select</option>
                                <option value="Electric">Electric</option>
                                <option value="Nature Gas">Nature Gas</option>
                                <option value="Propane">Propane</option>
                            </select>
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-sm-6">
                            <div class="mb-4">
                            <label for="exampleInputEmail3">Cooling</label>
                            <select class="form-control" value={this.state.cooling} onChange={(e) => this.handleCooling(e.target.value)}>
                                <option value="">Select</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                            </div>
                        </div>

                        <div class="col-sm-6">
                            <div class="mb-4">
                                <label> &nbsp; </label>
                                <div className="d-flex">
                                    <div class="me-3">
                                        <Input
                                            class="form-control mt-0"
                                            type="checkbox"
                                            checked = {this.state.furnished}
                                            onChange={(e) => this.handleFurnished(e.target.checked)}
                                        />
                                        <label for="furnished" class="ms-2">Furnished</label>
                                    </div>
                                    <div class="me-3">
                                        <Input
                                            class="form-control mt-0"
                                            type="checkbox"
                                            checked = {this.state.serviced}
                                            onChange={(e) => this.handleServiced(e.target.checked)}
                                        />
                                        <label for="serviced" class="ms-2">Serviced</label>
                                    </div>
                                    <div class="me-3">
                                        <Input
                                            class="form-control mt-0"
                                            type="checkbox"
                                            checked = {this.state.newly_built}
                                            onChange={(e) => this.handlenewlyBuilt(e.target.checked)}
                                        />
                                        <label for="newly_built" class="ms-2">Newly Built</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col-12">
                            <h4>Basement</h4>
                            <hr />
                        </div>
                        <div class="col-sm-6">
                            <div class="mb-4">
                            <label for="exampleInputEmail3">Finished</label>
                            <select class="form-control" value={this.state.finished} onChange={(e) => this.handleFinished(e.target.value)}>
                                <option value="">Select</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                             <span className="text-danger">{this.state.errMsg.finished}</span>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="mb-4">
                            <label for="exampleInputEmail3">Walkout</label>
                            <select class="form-control" value={this.state.walkout} onChange={(e) => this.handleWalkout(e.target.value)}>
                                <option value="">Select</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                            <span className="text-danger">{this.state.errMsg.Walkout}</span>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col-12">
                            <h4>Flooring</h4>
                            <hr />
                        </div>
                        <div class="col-12">
                            <div class="mb-4">
                            <label for="exampleInputEmail3">Floor</label>
                            <select class="form-control" value={this.state.flooring} onChange={(e) => this.handleFlooring(e.target.value)}>
                                <option value="">Select</option>
                                <option value="Hardwood">Hardwood</option>
                                <option value="Carpet">Carpet</option>
                                <option value="Ceramic">Ceramic</option>
                                <option value="Vinyl">Vinyl</option>
                                <option value="Stone">Stone</option>
                            </select>
                            <span className="text-danger">{this.state.errMsg.flooring}</span>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <h4>Other</h4>
                            <hr />
                        </div>
                        <div class="col-sm-6">
                            <div class="mb-4">
                            <label for="exampleInputPassword4">Fire Places</label>
                            <select class="form-control" value={this.state.fireplace} onChange={(e) => this.handleFirePlace(e.target.value)}>
                                <option value="">Select</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                            </select>
                            <span className="text-danger">{this.state.errMsg.Fire_place}</span>
                            </div>
                        </div>

                        {/* <div class="col-sm-6">                        
                            <div class="mb-4">
                            <label for="exampleInputPassword4">Swimming Pool</label>
                            <select class="form-control" value={this.state.swimmingPool} onChange={(e) => this.handlePool(e.target.value)}>
                                <option value="">Select</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                            </div>
                        </div> */}
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <h4>Select Features</h4>
                            <hr />
                        </div>
                        <div class="col-12">
                            <div class="mb-4">
                            <div class="row">
                            {this.state.property_checkbox_feature.length > 0 && this.state.property_checkbox_feature.map((item, idx) => (    
                                <div class="col-sm-3 col-md-4 mb-1">
                                <Input
                                    class="form-control"
                                    type="checkbox"
                                    checked = {item.status === "true" || item.status === true}
                                    onChange={(e) => this.handleCheckboxFeatures(idx, e.target.checked)}
                                />
                                <label for={item.name} className="ms-2">{item.name}</label>
                                </div>
                            ))}
                            </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="form-group col-sm-6">
                        {
                            this.state.propertyStatus === null  || this.state.propertyStatus  === "verify" || this.state.propertyStatus  === "reject"
                            ? 
                            <>
                                <button type="button" onClick={(e) => this.props.data.handleActiveTab("2")}  class="btn btn-secondary me-3 my-2">Back to Address</button>
                                <button type="button" onClick={(e) => this.onSubmitHandler()}  class="btn btn-primary my-2">Save Changes</button>
                            </>
                            : ""
                        }
                        </div>
                    </div>
                </form>
            </div>
        </>
        )
    }
}
