import React, { Component } from 'react'
import { Redirect } from 'react-router';
import Sidebar from './../Sidebar'
import axios from "axios";
import { APIURL } from '../../../../components/constants/common';
import Navbar from './../Navbar'
import Button from '@restart/ui/esm/Button';
import { Spinner, Input } from 'reactstrap'
import dateFormat, { masks } from "dateformat";
import Pagination from "react-js-pagination";

export default class Enquiry extends Component {
    constructor() {
        super();
        this.state = {
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            user_type: localStorage.getItem("user_type"),
            navigate: false,
            errMsg: "",
            scsMsg: "",
            profile_image: "",
            fullscrLoader: false,
            enquireRecordes: [],
            activePage: 1,
            limit: 0,
            totalItemsCount: 0,
            userType: "",
            search: "",

        }
    }
    handleSearch(e) {
        this.setState(
            { search: e.target.value, activePage: 1 }
            , () => { this.getenquiry() });
    }
    SortBy(e) {
        this.setState({
            sort: !this.state.sort
        }, () => {
            if (this.state.sort) {
                this.setState({
                    sortby: "ASC",
                    columnName: e
                }, () => {
                    this.getenquiry()
                })

            }
            if (!this.state.sort) {
                this.setState({
                    sortby: "DESC",
                    columnName: e
                }, () => {
                    this.getenquiry()
                })
            }
        })
    }
    componentDidMount() {
        this.getenquiry()

    }


    handlePageChange(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        this.setState(
            { activePage: pageNumber }
            , () => {
                this.getenquiry()
            }
        );
    }




    getenquiry() {
        if (this.state.user) {
            const formData = new FormData();
            formData.append('search', this.state.search);
            formData.append('page', this.state.activePage);
            formData.append('limit', this.state.limit);
            var token = this.state.token
            var app_url = APIURL + "admin/get-contact-enquiry"
            axios
                .post(app_url, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {

                    console.log(response.data.data)
                    this.setState({
                        enquireRecordes: response.data.data.data,
                        activePage: response.data.data.current_page,
                        totalItemsCount: response.data.data.total,
                        limit: response.data.data.per_page
                    })

                })
                .catch((error) => {
                    this.setState({
                        // errMsg: error.response.data.errors,
                        Loader: false
                    })
                });
        }
    }




    render() {
        if (!this.state.user) {
            return <Redirect to="/signin" />;
        }
        return (
            <div className="admin">

                {this.state.Loader ? <div className="loader"> <Spinner type="grow" color="dark" style={{ width: '3rem', height: '3rem' }} /> </div> : ""}


                <Sidebar activePage="enquiry" />
                <div class="content-area">
                    <Navbar />

                    <div class="content">
                        <div class="card">
                            <div class="card-body">
                                <div class="d-flex justify-content-between align-items-center">
                                    <h2 id="page_headeing"><span>Enquiry</span></h2>
                                    <div class="row align-items-end g-0">
                                        <div className="col-4">
                                            <div className="small"></div>
                                            <Input 
                                                type="text" 
                                                name="search"
                                                value={this.state.search}
                                                onChange={(e) => this.handleSearch(e)} 
                                                placeholder="Search" 
                                                className="form-control form-control_new border-0 border-end-0"
                                            />
                                        </div>
                                       
                                    <div class="d-flex justify-content-end align-items-center">
                                        <div class="row align-items-end">
                                        </div>


                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div>
                                    <div class="table-responsive">
                                        <table class="table table-striped table-borderless">
                                            <thead>
                                                <tr class="border-bottom">
                                                    <th style={{minWidth:70}}>Sr. No.</th>
                                                    <th style={{minWidth:70}}>Name</th>
                                                    <th style={{minWidth:130}}>Email</th>
                                                    <th style={{minWidth:120}}>Phone_Number</th>
                                                    <th style={{minWidth:100}}>Subject</th>
                                                    <th style={{minWidth:130}}>Message</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.enquireRecordes.length > 0 && this.state.enquireRecordes.map((item, idx) => (
                                                    <>
                                                        <tr>
                                                            <td>{idx + 1}</td>
                                                            <td>{item.name}</td>
                                                            <td>{item.email}</td>
                                                            <td>{item.phone_number}</td>
                                                            <td>{item.subject}</td>
                                                            <td>{item.message}</td>


                                                        </tr>
                                                    </>
                                                ))}
                                            </tbody>
                                        </table>

                                    </div>
                                    <div class="mt-4 text-center">

                                        {this.state.totalItemsCount > 0 &&
                                            <div class="justify-content-center d-flex">
                                                <Pagination
                                                    activePage={this.state.activePage}
                                                    itemsCountPerPage={this.state.limit}
                                                    totalItemsCount={this.state.totalItemsCount}
                                                    pageRangeDisplayed={5}
                                                    onChange={this.handlePageChange.bind(this)}
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                    innerClass="pagination justify-content-center"
                                                    activeLinkClass="active"
                                                    nextPageText="Next"
                                                    prevPageText="Previous"
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        )
    }
}