import React, { Component } from 'react'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import Footer from '../../Front/Footer'
import Header from './Header'
import { Redirect } from 'react-router'

export default class Index extends Component {
    constructor(){
        super();
        this.state = {
            user: JSON.parse(localStorage.getItem("userData")),
            mobileView: false
        }
    }

    render() {

        if (!this.state.user) {
            return <Redirect to="/signin" />;
        }
        if(this.state.user.user_type !== "Buyer"){
            return <Redirect to="/permission" />;
        }
     
        return (
            <>
                    <Navbar />
                    <div class="content-area">
                        <div class="breadcrumb-area gradient1">
                            <div class="container">
                                <h1>My Account</h1>
                                <div><small>Welcome To Your Account</small></div>
                         
                            </div>
                        </div>
                        <div class="myaccount position-relative">
                            <div class="container">
                                <div class="row">
                                    <div class="col-xxl-3 col-sm-4">
                                        <div className="bg-light h-100">
                                            <div class="px-xxl-4 py-4 px-3">
                                                <Sidebar activePage="dashboard" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xxl-9 col-sm-8">
                                        <div class="bg-light">
                                            <div class="px-xxl-4 py-4 px-3">
                                                <div>
                                                    <div>
                                                      <h2>Dasboard</h2>
                                                     
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   <Footer />
                   
            </>
        )
    }
}
