import React, { Component } from 'react'
import { Modal, Spinner, ModalBody, ModalHeader, ModalFooter, Button, Input } from 'reactstrap'
import Sidebar from '../Sidebar';
import Navbar from '../Navbar';
import { Redirect } from 'react-router';
import { APIURL, PAYPAL_CLIENT_ID  } from '../../../constants/common';
import axios from 'axios';
import dateFormat, { masks } from "dateformat";
import { ReactComponent as DeleteProperty } from '../../../../assets/images/trash.svg';
import { ReactComponent as Add } from '../../../../assets/images/plus-lg.svg';
import Tooltip from '@mui/material/Tooltip';
import DatePicker from "react-datepicker";
import Pagination from "react-js-pagination";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const options = [
  { value: 'a', label: 'a' },
  { value: 'b', label: 'b' },
];

export default class PropertyList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: JSON.parse(localStorage.getItem("token")),
      user: JSON.parse(localStorage.getItem("userData")),
      user_type: localStorage.getItem("user_type"),
      navigate: false,
      reload: false,
      PropertyList: [],
      errMsg: "",
      scsMsg: "",
      user_id: "",
      property_id: "",
      norecord: false,
      boostList: [],
      property_id: "",
      checked:true,
      status:"",
      package: 0,
      property_count: 1,
      month_count: 1,
      price: "",
      selectPropertyerrMsg: "",
      selectPropertyscsMsg: "",
      totalprice: 0,
      boosterPlanModal: false,
      selectPropertyModal: false,
      redirect: false,
      isLoading: false,
      fullscrLoader: true,
      activePage: 1,
      limit: 0,
      totalItemsCount: 0,
      paymentModal: false
    }
  }

  componentDidMount() {
    this.getSellerPropertyList();
    this.getboostPropertyList();
    this.getPlanPrice();
  }

  paymentModal() {
    this.setState({
        paymentModal: !this.state.paymentModal,
        boosterPlanModal: !this.state.boosterPlanModal
    })
  }

  paymentModalClose() {
    this.setState({
        paymentModal: false
    })
  }

  boosterPlanModal() {
    this.setState({
      boosterPlanModal: !this.state.boosterPlanModal
    })
  }
  // purchaseModal close
  boosterPlanModalClose() {
      this.setState({
          boosterPlanModal: false,
          month_count:1,
          property_count:1,
          totalprice: this.state.package,
          errMsg:"",
          scsMsg:"",
          isLoading: false,
      })
  }

  selectPropertyModal() {
    this.setState({
      selectPropertyModal: !this.state.selectPropertyModal
    })
  }

  selectPropertyModalClose() {
    this.setState({
        selectPropertyModal: false,
        errMsg:"",
        scsMsg:"",
        isLoading: false,
    })
  }

  getPlanPrice() {
    var token = this.state.token
    const formData = new FormData();
    var app_url = APIURL + "seller/get-booster-package-price"
    axios
    .post(app_url, null, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then((response) => {
      this.setState({
        package: response.data.price,
        totalprice: response.data.price
      })
    })
  }

  addBoosterPlan = () => {
    var token = this.state.token
      const formData = new FormData();
      formData.append('property_count', this.state.property_count);
      formData.append('month_count', this.state.month_count)
      this.setState({ isLoading: true });
      axios
          .post(APIURL + "seller/purchase-property-booster", formData, {
              headers: {
                  'Authorization': `Bearer ${token}`
              }
          })
          .then((response) => {
            console.log("property-add", response)
            this.setState({
                  scsMsg: response.data.message,
                  paymentModal: false,
                  isLoading: false,
                  errMsg:"",
                }, () => {
                  setTimeout(() => this.setState({ 
                    scsMsg: "",
                  }), 4000);
              })
          })
          .catch((error) => {
              this.setState({
                  errMsg: error.response.data.error,
                  isLoading: false,
              })
            
          });
  };

  getSellerPropertyList() {
    var token = this.state.token
    const formData = new FormData();
    var app_url = APIURL + "seller/get-seller-property-for-boosting"
    axios
      .post(app_url, null, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then((response) => {
        const PropertyList = response.data.data;
        // const PropertName = [];
        for (var c = 0; c < PropertyList.length; c++) {
          this.state.PropertyList.push({ value: PropertyList[c].id, label: PropertyList[c].name })
        }

        // console.log("PropertyList13", this.state.PropertyList);
      })

  }

  onSubmitHandler = () => {
    var token = this.state.token
    const formData = new FormData();
    formData.append('property_id', this.state.property_id);
    axios
      .post(APIURL + "seller/add-boosted-property", formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then((response) => {
        this.setState({
          selectPropertyscsMsg: response.data.message,
          property_id: "",
        }, () => {
          setTimeout(() => {this.setState({ 
              selectPropertyscsMsg: "",
              selectPropertyModal: false,
              PropertyList: []
          })
          this.getSellerPropertyList()
          }, 3000)
          this.getboostPropertyList();                
        });
      })
      .catch((error) => {
        this.setState({
        selectPropertyerrMsg: error.response.data.error,
        }, () => {
          setTimeout(() => this.setState({ 
            selectPropertyerrMsg: ""
          }), 3000)               
        });
      });
  }

  handleproperty = (e) => {
    this.setState({
      property_id: e.target.value
    })
  }

  getboostPropertyList() {
    var token = this.state.token;
    const formData = new FormData();
    formData.append('page', this.state.activePage);
    formData.append('limit', this.state.limit);
    formData.append('search', this.state.search ? this.state.search : "");
    axios
      .post(APIURL + "seller/get-boosted-property-list", formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then((response) => {
        this.setState({
          boostList: response.data.data.data,
          activePage: response.data.data.current_page,
          totalItemsCount: response.data.data.total,
          limit: response.data.data.per_page,
          norecord: true
        })
      })
      .catch((error) => {
        this.setState({
          errMsg: error.response.data.error,
          norecord: true,
        })
      });

  }

  handleSearch(e) {
    this.setState(
      { search: e.target.value, activePage: 1 }
      , () => { this.getboostPropertyList() });
  }
  
  handlePageChange(pageNumber) {
    this.setState(
      { activePage: pageNumber }
      , () => {
        this.getPackageHistory()
      }
    );
  }

  handleSwitchUser = (id, val) => {
    var token = this.state.token
    const formData = new FormData();
    formData.append('id', id);
    formData.append('status', (val === "active" ? "Active" : "Deactive"));

    axios
        .post(APIURL + "seller/update-boosted-property-status", formData, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then((response) => {
            this.setState({
              Loader: false,
              scsMsg: response.data.message,
              errMsg: "",
            }, () => {
                setTimeout(() => this.setState({ 
                    scsMsg: ""
                }), 3000);             
            });
            this.getboostPropertyList(); 
        })
        .catch((error) => {
            this.setState({
                errMsg: error.response.data.error,
              }, () => {
                setTimeout(() => this.setState({ 
                  errMsg: ""
                }), 3000); 
            })
        });
  };

  removeProperty(id){
      const formData = new FormData();
      formData.append('id', id);
      var token = this.state.token
      var app_url = APIURL + "seller/delete-boosted-property"
      axios
          .post(app_url, formData, {
              headers: {
                  'Authorization': `Bearer ${token}`
              }
          })
          .then((response) => {
              this.setState({
                  scsMsg: response.data.message,
                  PropertyList: []
                }, () => {
                setTimeout(() => this.setState({ 
                  scsMsg: ""
                }), 3000);
              });
              this.getboostPropertyList()
              this.getSellerPropertyList()
          })
          .catch((error) => {
              this.setState({
                  errMsg: error.response.data.errors,
                  Loader: false,
                }, () => {
                setTimeout(() => this.setState({ 
                  errMsg: ""
                }), 3000);
              })
          });
  }

  handleProperty(e) {
    this.setState({
      property_count: e
    }, ()=>{this.calculatePrice()})
  }

  handleMonth(e) {
    this.setState({
        month_count: e,
    }, ()=>{this.calculatePrice()})
  }

  calculatePrice = () => {
    const {month_count,property_count,totalprice } = this.state
    var total_price = property_count * month_count * this.state.package;

    this.setState({
        totalprice: total_price
    })
  }

  render() {
    if (!this.state.user) {
      return <Redirect to="/signin" />;

        const { checked } = this.state;
            let title=this.state.checked ? "on":"off";
        
    }

    return (
      <div className="admin">
          {this.state.scsMsg ? <div class="alert alert-success" role="alert"> {this.state.scsMsg} </div> : ""}
          {this.state.errMsg.message ? <div class="alert alert-danger" role="alert">  {this.state.errMsg.message}   </div> : ""}
        <Sidebar activePage="propertylist" />
        <div class="content-area">
          <Navbar />
          <div class="content">
            <div className="mb-4 d-flex justify-content-between">
              <Button onClick={() => this.boosterPlanModal()} color="info" className="px-3 btn btn-primary btn-sm">Purchase Booster Plan</Button>
            </div>
            <div class="card">
              <div class="card-body">
             <div class="d-sm-flex justify-content-between align-items-center">
                  <h2 id="page_headeing"><span>Property List</span></h2>
                  <div class="row align-items-end">
                  {this.state.scsMsg ? <div class="alert alert-success" role="alert"> {this.state.scsMsg} </div> : ""}
                    {this.state.errMsg.message ? <div class="alert alert-danger" role="alert">  {this.state.errMsg.message}   </div> : ""}
                    <div class="col-6">
                      <Input
                        className="form-control form-control_new border-0 border-end-0"
                        placeholder="Search"
                        value={this.state.search}
                        onChange={(e) => this.handleSearch(e)}
                      />
                    </div>
                    <div class="col-6 text-right">
                      {/* <div className="ml-2"> */}
                      <Button onClick={() => this.selectPropertyModal()} color="info"className="px-3 btn btn-primary btn-sm"><DeleteProperty class="img-svg d-block d-sm-none" /> <span className="d-none d-sm-block">Add Property to Boost</span></Button>
                    </div>
                  </div>
                </div>
                <hr />

                <div>
                
                  <div class="table-responsive">
                    <table class="table table-striped table-borderless">
                      <thead>
                        <tr class="border-bottom">
                          <th style={{minWidth:70}}>Sr. No.</th>
                          <th style={{minWidth:120}}>Property Name</th>
                          <th style={{minWidth:100}}>Added Date</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.boostList.length > 0 && this.state.boostList.map((item, idx) => (
                          <tr>

                            <td>{idx + 1}</td>
                            <td>{item.property_name}</td>
                            <td>{dateFormat(item.created_at, "mmm d, yyyy")}</td>
                            <td> 
                            {/* <div className="custom_switch">
                                <label class="switch">
                                  <input type="checkbox" id="togBtn" 
                                   onClick={(e) => this.handleSwitchUser(item.id, e.target.checked)}
                                     checked = {item.status === 'active' || item.status === 'Active' ? true : false}
                                  />
                                    <div class="slider round">
                                      { item.status === 'active'|| item.status === 'Active'
                                      ?
                                      <span class="on">Active</span>
                                    :
                                    <span class="off">Deactive</span>
                                    } 
                                  </div>
                                </label>
                            </div> */}
                              { item.status === 'active'
                                ?
                                <button class="btn btn-success btn-sm" onClick={(e) => this.handleSwitchUser(item.id, "deactive")}>Active</button>
                                :
                                <button class="btn btn-danger btn-sm" onClick={(e) => this.handleSwitchUser(item.id, "active")}>Deactive</button>
                              }
                            </td>
                            <td>
                              <button className="btn mb-0 btn-rounded btn-default" onClick={() => this.removeProperty(item.id)}><Tooltip title="Remove Property"><DeleteProperty class="img-svg" /></Tooltip></button>
                            </td>
                          </tr>
                        ))}
                        {this.state.boostList.length === 0 && this.state.norecord==true && 
                          <tr className="text-center">
                            <td colspan="7">No property added to boosting</td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                  <div class="mt-4 text-center">
                    {this.state.totalItemsCount>0 && 
                        <div class="justify-content-center d-flex">
                        <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.limit}
                            totalItemsCount={this.state.totalItemsCount}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange.bind(this)}
                            itemClass="page-item"
                            linkClass="page-link"
                            innerClass="pagination justify-content-center"
                            activeLinkClass="active"
                            nextPageText="Next"
                            prevPageText="Previous"
                        />
                        </div>
                    }
                </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <Modal size="md" isOpen={this.state.boosterPlanModal} toggle={() => this.boosterPlanModalClose()} autoFocus={false}>
          <ModalHeader toggle={() => this.boosterPlanModal()}>Booster Plan</ModalHeader>
          <ModalBody>
              {this.state.scsMsg ?
                  <div className="text-success">
                      {this.state.scsMsg}
                  </div>
                  : ""}
              <div className="mb-4">
                  <label for="exampleusertype" class="form-label">Number Of Property to Boost
                      <strong className="text-danger" >*</strong></label>
                  <Input
                    type="number"
                    min="1"
                    placeholder="Number Of Property"
                    value={this.state.property_count}
                    onChange={(e) => this.handleProperty(e.target.value)}
                    autoFocus={true}
                  />

                  <span className="text-danger small">{this.state.errMsg.property_count}</span>
              </div>
              <div className="mb-4">
                  <label> Duration (Month)
                  <strong className="text-danger" >*</strong>
                  </label>
                  <Input type="number"
                    min="1"
                    placeholder="Number of Month"
                    value={this.state.month_count}
                    onChange={(e) => this.handleMonth(e.target.value)}
                    autoFocus={true}
                  />
                  <span className="text-danger small">{this.state.errMsg.month_count}</span>
              </div>
              <div className="mb-4">
                  <label>Amount (£)</label>
                  <h4>{ this.state.totalprice }</h4>
              </div>
          </ModalBody>
          <ModalFooter>
              <div>
                  <button className="btn btn-light me-2" onClick={() => this.boosterPlanModalClose()}> Close </button>
                  <button className="btn btn-primary" onClick={() => this.paymentModal()}> Submit
                  {this.state.isLoading ? (
                      <span
                          className="spinner-border spinner-border-sm ml-2"
                          role="status"
                          aria-hidden="true"
                      ></span>
                  ) : (
                      <span></span>
                  )}</button>
              </div>
          </ModalFooter>
        </Modal>

        <Modal size="md" isOpen={this.state.selectPropertyModal} toggle={() => this.selectPropertyModalClose()} autoFocus={false}>
          <ModalHeader toggle={() => this.selectPropertyModalClose()}>Add Property to Boost</ModalHeader>
          <ModalBody>
              {this.state.selectPropertyscsMsg ?
                  <div className="text-success">
                      {this.state.selectPropertyscsMsg}
                  </div>
                  : ""}
              <div class="mb-4">
                <label for="exampleusertype" class="form-label">Select Property</label>
                <select className="form-control form-control_new border-0 border-end-0"
                  value={this.state.property_id}
                  onChange={this.handleproperty} >
                  <option value="">Select Property</option>
                  {this.state.PropertyList.map((option) => (
                    <option value={option.value}>{option.label}</option>
                  ))}
                </select>
                <span className="text-danger">{this.state.selectPropertyerrMsg.message}</span>
              </div>
          </ModalBody>
          <ModalFooter>
              <div>
                  <button className="btn btn-light me-2" onClick={() => this.selectPropertyModalClose()}> Close </button>
                  <button className="btn btn-primary" onClick={() => this.onSubmitHandler()}> Submit
                  {this.state.isLoading ? (
                      <span
                          className="spinner-border spinner-border-sm ml-2"
                          role="status"
                          aria-hidden="true"
                      ></span>
                  ) : (
                      <span></span>
                  )}</button>
              </div>
          </ModalFooter>
        </Modal>

        <Modal size="md" isOpen={this.state.paymentModal} toggle={() => this.paymentModalClose()}>
          <ModalHeader className="" toggle={() => this.paymentModalClose()}>
              Choose Payment Option <br />
              <span className="text-danger">{this.state.errMsgCmt}</span>
          </ModalHeader>
          <ModalBody className="border-0">
              <div className="row">
                  <div className="col-lg-12 col-md-6">
                      <PayPalScriptProvider options={{ "client-id": PAYPAL_CLIENT_ID,}}>
                          <PayPalButtons
                              //fundingSource="paypal"
                              createOrder={(data, actions) => {
                                  return actions.order.create({
                                      purchase_units: [
                                          {
                                              amount: {
                                                  value: this.state.totalprice,
                                              }
                                          },
                                      ],
                                      application_context: {
                                          shipping_preference: 'NO_SHIPPING'
                                      }
                                  });
                              }}
                              onApprove={(data, actions) => {
                                  return actions.order.capture().then((details) => {
                                      const name = details.payer.name.given_name;
                                      {
                                          details.status === "COMPLETED" &&
                                          this.addBoosterPlan()
                                      }
                                  });
                              }}
                          />
                      </PayPalScriptProvider>
                  </div>
              </div>
          </ModalBody>
          <ModalFooter>
              <div className="">
                  <button type="button" className="btn btn-light" onClick={() => this.paymentModalClose()}>Close</button>
              </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
