import React, { Component } from 'react'
import logo_color from '../../../assets/images/genhaven-color.png'
import seal from '../../../assets/images/seal.png'
import check from '../../../assets/images/check.png'
import { Modal, Spinner, ModalBody, ModalHeader, ModalFooter, Button, Input } from 'reactstrap'
import { Link, Redirect} from 'react-router-dom'
import { APIURL } from '../../constants/common';
import axios from 'axios'
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, PDFViewer, Image} from '@react-pdf/renderer';

class PropertyCertificate extends Component {
    constructor() {
        super();

        this.state = {
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            docData: [],
            attachedImage: []
        }
        //this.SortBy = this.SortBy.bind(this);
    }

    getDocumentData(property_id, request_id = null) {
        var token = this.state.token;
        var api = "get-property-verification-Document-detail"
        const formData = new FormData();
        formData.append('property_id', property_id);
        request_id !== null ?
            formData.append('request_id', request_id)
        :
            api = "get-buyer-property-verification-Document-detail"
        
        axios
            .post(APIURL + api, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                //console.log("get-property-verification-Document-detail", response.data.data)
                this.setState({
                    docData: response.data.data
                })
                var docImage = []
                if(response.data.data.attachment_files.length > 0) {
                    response.data.data.attachment_files.map((item, idx) => {
                        console.log(item.b64_url_path)
                        docImage.push({b64_url_path: item.b64_url_path})
                    })
                }

                this.setState({
                    attachedImage: docImage
                })
                
            })
    }

    componentDidMount() {
        if (this.props.match.params.request_id !== "") {
            this.getDocumentData(this.props.match.params.id, this.props.match.params.request_id);
        } else {
            this.getDocumentData(this.props.match.params.id);
        }
    }
    render() {  

        const styles = StyleSheet.create({
            page: { padding:20, backgroundColor:"#f1f1f1"},
            section: {marginTop: 10, padding: 10, flexGrow: 1 },
            text_center: {textAlign: 'center'},
            text_right:{ textAlign:"right"},
            text_left:{ textAlign:"left"},
            small:{fontSize:10,},
            // border:{ borderWidth:1, borderColor:"#ddd"},
            border:{ borderWidth:2, borderColor:"#333"},
            border_b:{ borderBottom:1, borderColor:"#666"},
            border_top:{borderTop:2, borderColor:"#333"},
            w_300:{ width:300,},
            w_50:{ width:50,},
            box:{ minHeight:150, padding:5},
            box2:{ minHeight:40, padding:5},
            mb_1:{ marginBottom:10},
            mb_2:{ marginBottom:20},
            p_15:{padding:15},
            px_15:{paddingLeft:15, paddingRight:15},
            mx_15:{marginLeft:15, marginRight:15},
            p_relative:{ position:"relative"},
            p_absolute:{ position:'absolute',},

            header:{ display:"flex", flexDirection: 'row', justifyContent:"space-between", height:40, textAlign: 'center',},
            footer:{ justifyContent: 'center', alignItems: 'center', flexDirection: 'row', marginTop:10, marginBottom:10,},
            col:{display:'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row',},
            col_top:{ display:'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'row',},

            logo:{ width:80},
            h1:{ fontSize:15, fontWeight:"900",},
            top_heading:{marginTop:15, marginLeft:10, marginRight:10, borderBottom:3, borderColor:"#333",  paddingLeft:0, },
            section_heading:{fontSize:13, fontWeight:"900",},
            section_heading2:{fontSize:11, fontWeight:"900",},
            text:{fontSize:10,},
            square:{width:15, height:15,borderWidth:2, borderColor:"#333"},
            check:{width:15, height:15, left:5, top:-3},
            check_icon:{width:12,},

            box_area:{flex: 1, flexDirection: 'column', },
            seal:{ width:130, height:"auto", opacity:.4},
            img_area:{width:130,maxHeight:90,borderWidth: 1, borderColor:"#333", margin:5, padding:5},
            img:{ maxWidth:130, maxHeight:90 },

            container: {/*flex: 1, justifyContent: 'center', alignItems: 'center', flexDirection: 'row',*/ backgroundColor: '#fff', borderWidth: 2, borderColor:"#333", marginTop:10, marginBottom:10 }, 
        }); 
       
        return(

            <div>
                 
                <PDFViewer width="100%" height="800">
                    {/* <Document title={this.state.docData:this.state.docData.certificate_no}> */}
                    <Document title={this.state.docData.property_title+'_'+this.state.docData.certificate_no}>

                        <Page size="A4" style={styles.page} wrap={true}>
                            <View style={[styles.header]}>
                                <View style={[{}]}>
                                    <Image src={logo_color} style={styles.logo} />
                                </View>  

                                <View style={[styles.text_center, styles.border_b, styles.top_heading, {}]}>
                                    <Text style={[styles.h1, styles.text_center,{fontSize:15,}]}>Property Verification Certificate</Text>
                                </View>
                                <View style={[styles.text_right,{marginTop:25}]}>
                                    <Text style={[styles.small]}>Certicficate No. : {this.state.docData.certificate_no}</Text>
                                </View>
                            </View>

                            <View style={[styles.container,{}]}>
                                <View style={[styles.mb_1,{}]}>
                                    <View style={[styles.p_15,{}]}>
                                        <View style={[styles.border_b, styles.p_relative, styles.mb_1,{ paddingBottom:5, borderStyle: 'dashed'} ]}>
                                            <Text style={[styles.text,{ position:"relative", top:7, backgroundColor:"#fff", width:105}]}>Full Property Address:</Text>
                                            <Text style={[styles.text,styles.p_absolute,{ left:110, right:0, bottom:0,}]}>{this.state.docData.address}</Text>
                                        </View>
                                        <View style={[styles.border_b, styles.p_relative, styles.mb_1,{ paddingBottom:5, borderStyle: 'dashed'} ]}>
                                            <Text style={[styles.text,{ position:"relative", top:7, backgroundColor:"#fff", width:85}]}>Nearest Bus Stop:</Text>
                                            <Text style={[styles.text,styles.p_absolute,{ left:90, right:0, bottom:0,}]}>{this.state.docData.nearest_bus_stop}</Text>

                                        </View>
                                        { this.state.docData.property_type !== null &&
                                            <View style={[styles.border_b, styles.p_relative,{ paddingBottom:5, borderStyle: 'dashed'} ]}>
                                                <Text style={[styles.text,{ position:"relative", top:7, backgroundColor:"#fff", width:70}]}>Property Type: </Text>
                                                <Text style={[styles.text,styles.p_absolute,{ left:75, right:0,  bottom:0,}]}>{this.state.docData.property_type}</Text>
                                            </View>
                                        }
                                    </View>
                                </View>

                                <View style={[styles.px_15, styles.mb_2,{}]}>
                                    <View class={[styles.mb_1,{}]}>
                                        <Text style={[styles.text,]}>Pictures (Attached) : </Text>
                                    </View>
                                    <View>
                                        <View style={[styles.col_top,{ marginLeft:-5}]}>
                                            { this.state.attachedImage.map((prop_image, index) => (
                                                <View style={[styles.img_area]}>
                                                    
                                                    <Image  style={[styles.img]}  source={prop_image.b64_url_path} />

                                                </View>
                                            ))}
                                           
                                        </View>
                                    </View>
                                </View> 

                                
                                <View style={[{marginBottom:20}]}>
                                    <View style={[styles.col_top,{}]}>
                                        <View style={[styles.mx_15, styles.border,{padding:5, width:480, paddingRight:15, position: 'relative'}]}>
                                            <View style={[styles.col_top,{marginBottom:10, backgroundColor:"#ccc", width:310}]}>
                                                <View style={[styles.col,{}]}>
                                                    <View style={[{width:150,  padding:5,}]}>
                                                        <Text style={[styles.border_b,{ fontSize:13,}]}>Verification Requested </Text>
                                                    </View>
                                                    <View style={[styles.text_center,{width:30, padding:5,}]}><Text style={[{ fontSize:13}]}>Yes</Text></View>
                                                    <View style={[styles.text_center,{width:30, padding:5,}]}><Text style={[{ fontSize:13}]}>No</Text></View>
                                                </View>
                                                <View style={[{width:120, marginLeft:-1, marginTop:0, paddingTop:5, paddingBottom:6,}]}>
                                                    <Text style={[styles.text_center, { fontSize:13}]}>Status</Text>
                                                </View>
                                            </View>

                                            <View style={[styles.col_top,{ marginTop:5, marginBottom:5, width:320}]}>
                                                <View style={[styles.col,{}]}>
                                                    <View style={[{width:155,padding:5}]}>
                                                        <Text style={[{ fontSize:10,}]}>Property Title:</Text>
                                                    </View>
                                                    <View style={[styles.text_center, styles.p_relative,{width:40, paddingLeft:7}]}>
                                                        {
                                                            this.state.docData.property_title_check === "Yes" ?
                                                            <>
                                                                <View style={[styles.square,{marginLeft:7}]}></View>
                                                                <View style={[styles.check, styles.p_absolute,{marginLeft:13}]}><Image src={check} style={styles.check_icon} /></View>
                                                            </>
                                                            :
                                                            <>
                                                                <View style={[styles.square,{marginLeft:7}]}></View>
                                                            </>                                                            
                                                        }    
                                                    </View>
                                                    <View style={[styles.text_center, styles.p_relative,{width:40, marginLeft:3,}]}>
                                                        {
                                                            this.state.docData.property_title_check === "No" ?
                                                            <>
                                                                <View style={[styles.square,{marginLeft:7}]}></View>
                                                                <View style={[styles.check, styles.p_absolute,{marginLeft:7}]}><Image src={check} style={styles.check_icon} /></View>
                                                            </>
                                                            :
                                                            <>
                                                                <View style={[styles.square,{marginLeft:7}]}></View>
                                                            </>
                                                        }
                                                    </View>
                                                </View>
                                                <View style={[{width:120, marginTop:3}]}>

                                                {
                                                    this.state.docData.property_title_check === "Yes" ?
                                                    <View style={[styles.col,{width:100}]}>
                                                        <View style={[styles.square,{ marginRight:10 }]}></View>
                                                        <View style={[styles.check, styles.p_absolute,{marginLeft:0}]}><Image src={check} style={styles.check_icon} /></View>
                                                        <Text style={[styles.text_center, { fontSize:10}]}>(OK to Proceed)</Text>
                                                    </View>
                                                    :
                                                    <View style={[styles.col,{width:100}]}>
                                                        <View style={[styles.square,{ marginRight:10 }]}></View>
                                                        <Text style={[styles.text_center, { fontSize:10}]}>(OK to Proceed)</Text>
                                                    </View>
                                                }

                                                </View> 
                                            </View>

                                            <View style={[styles.col_top,{ marginTop:5, marginBottom:5, width:320}]}>
                                                <View style={[styles.col,{}]}>
                                                    <View style={[{width:155,padding:5}]}>
                                                        <Text style={[{ fontSize:10,}]}>Survey Document:</Text>
                                                    </View>
                                                    <View style={[styles.text_center, styles.p_relative,{width:40, paddingLeft:7}]}>
                                                        {
                                                            this.state.docData.survey_document_check === "Yes" ?
                                                            <>
                                                                <View style={[styles.square,{marginLeft:7}]}></View>
                                                                <View style={[styles.check, styles.p_absolute,{marginLeft:13}]}><Image src={check} style={styles.check_icon} /></View>
                                                            </>
                                                            :
                                                            <>
                                                                <View style={[styles.square,{marginLeft:7}]}></View>
                                                            </>                                                            
                                                        }    
                                                    </View>

                                                    <View style={[styles.text_center, styles.p_relative,{width:40, marginLeft:0}]}>
                                                        {
                                                            this.state.docData.survey_document_check === "No" ?
                                                            <>
                                                                <View style={[styles.square,{marginLeft:7}]}></View>
                                                                <View style={[styles.check, styles.p_absolute,{marginLeft:7}]}><Image src={check} style={styles.check_icon} /></View>
                                                            </>
                                                            :
                                                            <>
                                                                <View style={[styles.square,{marginLeft:7}]}></View>
                                                            </>
                                                        }
                                                    </View>
                                                </View>
                                                <View style={[{width:120, marginTop:3}]}>
                                                {
                                                    this.state.docData.survey_document_check === "Yes" ?

                                                    <View style={[styles.col,{width:100}]}>
                                                        <View style={[styles.square,{ marginRight:10 }]}></View>
                                                        <View style={[styles.check, styles.p_absolute,{marginLeft:0}]}><Image src={check} style={styles.check_icon} /></View>
                                                        <Text style={[styles.text_center, { fontSize:10}]}>(OK to Proceed)</Text>
                                                    </View>
                                                    :
                                                    <View style={[styles.col,{width:100}]}>
                                                        <View style={[styles.square,{ marginRight:10 }]}></View>
                                                        <Text style={[styles.text_center, { fontSize:10}]}>(OK to Proceed)</Text>
                                                    </View>
                                                }
                                                </View>
                                            </View>

                                            <View style={[styles.col_top,{ marginTop:5, marginBottom:5, width:320}]}>
                                                <View style={[styles.col,{}]}>
                                                    <View style={[{width:155,padding:5}]}>
                                                        <Text style={[{ fontSize:10,}]}>Site Visit:</Text>
                                                    </View>
                                                    <View style={[styles.text_center, styles.p_relative,{width:40, paddingLeft:7}]}>
                                                        {
                                                            this.state.docData.site_visit_check === "Yes" ?
                                                            <>
                                                                <View style={[styles.square,{marginLeft:7}]}></View>
                                                                <View style={[styles.check, styles.p_absolute,{marginLeft:13}]}><Image src={check} style={styles.check_icon} /></View>
                                                            </>
                                                            :
                                                            <>
                                                                <View style={[styles.square,{marginLeft:7}]}></View>
                                                            </>                                                            
                                                        }    
                                                    </View>

                                                    <View style={[styles.text_center, styles.p_relative,{width:40, marginLeft:0}]}>
                                                        {
                                                            this.state.docData.site_visit_check === "No" ?
                                                            <>
                                                                <View style={[styles.square,{marginLeft:7}]}></View>
                                                                <View style={[styles.check, styles.p_absolute,{marginLeft:7}]}><Image src={check} style={styles.check_icon} /></View>
                                                            </>
                                                            :
                                                            <>
                                                                <View style={[styles.square,{marginLeft:7}]}></View>
                                                            </>
                                                        }
                                                    </View>
                                                </View>
                                                <View style={[{width:120, marginTop:3}]}>
                                                {
                                                    this.state.docData.site_visit_check === "Yes" ?
                                                    <View style={[styles.col,{width:100}]}>
                                                        <View style={[styles.square,{ marginRight:10 }]}></View>
                                                        <View style={[styles.check, styles.p_absolute,{marginLeft:0}]}><Image src={check} style={styles.check_icon} /></View>
                                                        <Text style={[styles.text_center, { fontSize:10}]}>(OK to Proceed)</Text>
                                                    </View>
                                                    :
                                                    <View style={[styles.col,{width:100}]}>
                                                        <View style={[styles.square,{ marginRight:10 }]}></View>
                                                        <Text style={[styles.text_center, { fontSize:10}]}>(OK to Proceed)</Text>
                                                    </View>
                                                }
                                                </View>
                                            </View>
                                            <View style={[{ position:"absolute", left:212, top:0, height:138, borderLeft:1, borderColor:"#333",borderStyle: 'dashed',}]}></View>
                                        </View>

                                        <View style={[styles.mx_15, styles.text_center,{ width:250, height:150, padding:5}]}>
                                            <Image src={seal} style={[styles.seal,{ marginLeft:10 }]} />
                                        </View>
                                    </View> 
                                </View>                              

                                <View style={[ styles.p_15,styles.border_top,{}]}>
                                    <View style={[{width:120, backgroundColor:"#ccc", padding:5, marginBottom:10}]}>
                                        <Text style={[styles.section_heading, styles.border_b,{}]}>Verification Details </Text>
                                    </View>
                                    <View style={[{minHeight:250, marginBottom:10}]}>
                                        <View style={[styles.col_top]}>
                                            <View style={[{width:250}]}>
                                                <Text style={[styles.section_heading2, styles.mb_1]}>Property Title Verification Details:</Text>
                                                <View style={[styles.box, styles.border, {}]}>
                                                    <Text style={[styles.text]}>{this.state.docData.property_title_verify_detail}</Text>
                                                </View>
                                            </View>
                                            <View style={[styles.w_50,{}]}></View>
                                            <View style={[{width:250}]}>
                                                <Text style={[styles.section_heading2, styles.mb_1]}>Survey Document Verification Details:</Text>
                                                <View style={[styles.box, styles.border ,{}]}>
                                                    <Text style={[styles.text]}>{this.state.docData.survey_document_verify_detail}</Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>
                           
                        </Page>

                        <Page size="A4" style={styles.page} wrap={true}>
                            <View style={[styles.header]}>
                                <View style={[{}]}>
                                    <Image src={logo_color} style={styles.logo} />
                                </View> 
                                  <View style={[styles.text_center, styles.border_b, styles.top_heading, {}]}>
                                    <Text style={[styles.h1, styles.text_center,{fontSize:15,}]}>Property Verification Certificate</Text>
                                </View>
                                <View style={[styles.text_right,{marginTop:25}]}>
                                    <Text style={[styles.small]}>Certicficate No. : {this.state.docData.certificate_no}</Text>
                                </View> 
                            </View>
                            <View style={[styles.container,{minHeight:150 }]}>
                                <View style={[styles.mb_1,{}]}>
                                    <View style={[styles.p_15,{}]}>
                                        <View style={[styles.mb_2,{minHeight:150}]}>
                                            <View style={[styles.col_top]}>
                                                <View style={[{width:250}]}>
                                                    <Text style={[styles.section_heading2, styles.mb_1]}>Site Visit Verification Details:</Text>
                                                    <View style={[styles.box, styles.border]}>
                                                        <Text style={[styles.text]}>{this.state.docData.site_visit_verify_detail}</Text>
                                                    </View>
                                                </View>
                                                <View style={[styles.w_50,{}]}></View>
                                                <View style={[{width:250}]}>
                                                    <Text style={[styles.section_heading2, styles.mb_1]}>Survey Solicitors Comment & Company Stamp:</Text>
                                                    <View style={[styles.box, styles.border,{}]}>
                                                        <Text style={[styles.text]}>{this.state.docData.solicitor_comment}</Text>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                        
                                    </View>
                                </View>
                                <View style={[styles.col_top, styles.p_15, styles.border_top,{}]}>
	                                        <View style={[{width:250}]}>
	                                            <Text style={[styles.section_heading2, styles.mb_1]}>Solicitors:</Text>
	                                            <View style={[styles.box2, styles.border,{}]}>
	                                                <Text style={[styles.text]}>{this.state.docData.solicitor_name}</Text>
	                                            </View>
	                                        </View>
	                                        <View style={[styles.w_50,{}]}></View>
	                                        <View style={[{width:250}]}>
	                                            <Text style={[styles.section_heading2, styles.mb_1]}>Solicitors Address:</Text>
	                                            <View style={[styles.box2, styles.border,{}]}>
	                                                <Text style={[styles.text]}>{this.state.docData.solicitor_address}</Text>
	                                            </View>
	                                        </View>
                                        </View>
                                <View style={[styles.footer, styles.p_15, styles.border_top,{}]}>
                                    
                                    <View style={[styles.border_b, styles.w_300, styles.p_relative,{ paddingBottom:5, borderStyle: 'dashed'} ]}>
                                        <Text style={[styles.text,{ position:"relative", top:7, backgroundColor:"#fff", width:80}]}>Expire Date:</Text>
                                        <Text style={[styles.text,styles.p_absolute,{ left:80, bottom:0,}]}>{this.state.docData.date_of_expiry}</Text>
                                    </View>
                                    <View style={[{width:100}]}></View>
                                   
                                    <View style={[ styles.w_300, styles.p_relative, { paddingBottom:5, }]}>
                                        <Text style={[styles.text,{ position:"relative", top:7, backgroundColor:"#fff", width:50}]}>Solicitors Signature:</Text>               
                                        { this.state.docData.b64_signed_file_full_path!=null && 
	                                        <View style={[{ position:"absolute", right:0, top:0, padding:2, width:170}]}>

	                                            <Image src={this.state.docData.b64_signed_file_full_path} style={[styles.logo,{ maxHeight:50, maxWidth:"100%" }]}  />
	                                        </View>
	                                    }
                                        
                                        <Text style={[styles.text,styles.p_absolute,{ left:50, bottom:0,}]}></Text>
                                    </View>
                                </View>
                            </View>
                            <View>
                                <Text style={{fontSize:"8", textAlign:"justify"}}>This document contains information in connection with the verification requested for the above property. Issuance of the certificate does not in ANY way mean that your acquisition is fool proof if standard acquisition and conveyancing process is not engaged</Text>
                            </View> 
                        </Page>

                    </Document>
                </PDFViewer>
                        
            </div>
        )
    } 
}

export default PropertyCertificate