import React, { Component } from 'react'
import axios from "axios";
import { APIURL, PAYPAL_CLIENT_ID } from '../../../components/constants/common';
import { Button, Spinner, Input} from 'reactstrap';
import { Redirect, Link } from 'react-router-dom'
import Image1 from '../../../assets/images/image-1.webp'
import {ReactComponent as GeoAlt  } from '../../../assets/images/geo-alt.svg'
import {ReactComponent as Back} from '../../../assets/images/back.svg'
import {ReactComponent as Heart  } from '../../../assets/images/heart.svg'
import { ReactComponent as HeartRed } from '../../../assets/images/heart-fill.svg'
import { ReactComponent as Facebook } from '../../../assets/images/facebook.svg'
import { FacebookShareButton } from "react-share";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import NumberFormat from 'react-number-format';

export default class Sidebar extends Component {
	constructor(props){
		super(props);
		this.state = {
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            isLoggedin: false,
            Loader: false,
			stylePath:true,
            haveVerificationCode: false,
            message: "",
            requestPriceDetails: [],
            similarProperties: [],
            verificationCode: "",
            checked: false,
            scsMsg: "",
            errMsg: "",
            wishlist: false,
            shareUrl:window.location.href,
            disableSendButton: false,
            amount: 0,
            showSuccessMsg: false,
            actualAmount: 0,
            disableCheckBox: false,
            disableCouponBox: false,
            disableApplyButton: false,
            hideForm: false,
            showPaymentMethod: false
		}
	}

    handlePayment = () => {
        this.setState({
            hideForm: !this.state.hideForm,
            showPaymentMethod: !this.state.showPaymentMethod
        })
    }

    handleMessage = (e) => {
        this.setState({
            message: e
        })
    }

    handleVerificationCode = (e) => {
        this.setState({
            verificationCode: e
        })
    }

    handleVerificationCodeCheck = (e) => {      
        if(this.state.checked == false)
        {
            this.setState({
                verificationCode:""
            })
        }
        if (e) {
            this.setState({
                disableSendButton: true,
            })
        } else {
            this.setState({
                amount: this.state.actualAmount,
                disableSendButton: false,
                showSuccessMsg: false
            })
        }

        this.setState({
            haveVerificationCode: e,
            checked: !this.state.checked
        })       
    }

    getRequestPrice() {
        const formData = new FormData();
        formData.append('user_type', 'Buyer');
        formData.append('plan_type', 'request');

        axios
        .post(APIURL + "get-subscription-plan", formData)
        .then((response) => {
            this.setState({
				requestPriceDetails: response.data.data,
                amount: response.data.data.amount,
                actualAmount: response.data.data.amount
			})
        })
    }

    getWishlistStatus(property_id) {
        const formData = new FormData();
        formData.append('id', property_id);
        var token = this.state.token
        axios
            .post(APIURL + "buyer/property-wishlist-status", formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                this.setState({
                    wishlist: response.data.data.wishlist
                })
            })
    }

    getSimilarProperties(property_id) {
        const formData = new FormData();
        formData.append('property_id', property_id);
        var token = this.state.token
        axios
            .post(APIURL + "get-relavent-properties", formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                this.setState({
					similarProperties: response.data.data
				})
            })
    }

    checkVerificationCodeStatus() {
        var token = this.state.token     
        const formData = new FormData();
        formData.append('coupon_code', this.state.verificationCode ? this.state.verificationCode : "");

        this.setState({ Loader: true });
        if(this.state.haveVerificationCode)
            axios
                .post(APIURL + "buyer/check-coupon-code-used-or-not", formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                        this.setState({
                            amount: response.data.price,
                            disableSendButton: false,
                            showSuccessMsg: true
                        // }, () => {
                        //     setTimeout(() => this.setState({ 
                        //         showSuccessMsg: false
                        //     }), 4000);
                        });
                })
                .catch((error) => {
                    
                    this.setState({
                        Loader: false,
                        errMsg: error.response.data.error.message
                    });
                    setTimeout(() => this.setState({ errMsg: "", }), 4000); 
                })
        else{
            this.sendVerificationRequest(); 
        }
    }

    sendVerificationRequest() {

        var token = this.state.token
     
        const formData = new FormData();
        formData.append('property_id', this.props.id);
        formData.append('plan_id', this.state.requestPriceDetails.id ? this.state.requestPriceDetails.id : "");
        formData.append('user_id', this.state.user.id);
        formData.append('coupon_code', this.state.verificationCode ? this.state.verificationCode : "");
        formData.append('amount', this.state.amount);
        formData.append('message', this.state.message ? this.state.message : "");

        this.setState({ Loader: true });
        axios
        .post(APIURL + "buyer/property-request-send-by-buyer", formData, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then((response) => {
            this.setState({
                Loader: false,
                scsMsg: response.data.message,
                verificationCode: "",
                haveVerificationCode: false,
                checked: false,
                message: "",
                amount: this.state.actualAmount,
                hideForm: false,
                showPaymentMethod: false
            });                
            setTimeout(() => this.setState({ scsMsg: "", }), 4000);
        })
        .catch((error) => {
            this.setState({
                Loader: false,
                errMsg: error.data.data.error
            });                
            setTimeout(() => this.setState({ errMsg: "", }), 4000);
        })
    };

   handelLike(){       
        const formData = new FormData();
        formData.append('property_id', this.props.id);
        var token = this.state.token
        
        if(this.state.wishlist == false)
        {
            var app_url = APIURL+"buyer/property-wishlist-add"
        }
        else
        {
           var app_url = APIURL+"buyer/property-wishlist-remove"
        }
       
        axios
        .post(app_url, formData, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then((response) => {            
            this.setState({
                wishlist: !this.state.wishlist,
            });
        })
        .catch((error) => {
            this.setState({
                // errMsg: error.response.data.errors,
                Loader: false
            })
        });
    }

    handelSimilarLike(list){       
        const formData = new FormData();
        formData.append('property_id', list.id);
        var token = this.state.token
        
        if(list.wishlist == false)
        {
            var app_url = APIURL+"buyer/property-wishlist-add"
        }
        else
        {
           var app_url = APIURL+"buyer/property-wishlist-remove"
        }
       
        axios
        .post(app_url, formData, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then((response) => {
            
           this.getSimilarProperties(this.props.id)
            
        })
        .catch((error) => {
            this.setState({
                // errMsg: error.response.data.errors,
                Loader: false
            })
        });           
    }

	componentDidMount(){
        if (this.state.user && this.state.token) {

            this.setState({
                isLoggedin: true
            })
            this.getWishlistStatus(this.props.id)
        }
        this.getRequestPrice()
        this.getSimilarProperties(this.props.id)
	}

    getProperty(item)
    {
        this.props.changePropes(item)
        this.getSimilarProperties(item.id)
    }

	render() {
       
		console.log(this.props)
		return (
            <div>
                {this.state.scsMsg ? <div className="alert alert-success" role="alert"> {this.state.scsMsg} </div> : ""}
                {this.state.errMsg ? <div className="alert alert-danger" role="alert">  {this.state.errMsg}   </div> : ""}
                <div className="sidebar mb-4">
                    <div className="sidebar__share">
                        <div className="row">
                            <div className="col-6">
                                <FacebookShareButton
                                    url={this.state.shareUrl}
                                    quote="mahesh"
                                    className="btn btn-share btn-block"
                                    >
                                    {/*<FacebookIcon size={32} round />*/}
                                    <Facebook className="img-svg animate" alt="Save" /><span>Facebook</span>
                                </FacebookShareButton>
                            </div>
                            
                            {this.state.user==null &&
                                <React.Fragment>
                                    <div className="col-6">
                                        <Link to="/signin" className="btn btn-save btn-block"><Heart className="img-svg animate" alt="Save" /><span>Save</span></Link>
                                    </div>
                                </React.Fragment>
                            }
                                
                            {this.state.user!=null  && this.state.user.user_type=="Buyer" &&
                                <div className="col-6">
                                    <a href="javascript:;" className="btn btn-save btn-block"  onClick={()=>this.handelLike()} >{this.state.wishlist}
                                    { this.state.wishlist== true || this.state.wishlist== "true" ?
                                        <React.Fragment> <HeartRed class="img-svg"  alt="property" /><span>Unlink</span></React.Fragment>
                                        :<React.Fragment><Heart className="img-svg animate" alt="Save" /><span>Save</span></React.Fragment>
                                    }                                  
                                    </a>
                                </div>
                            }
                        </div>
                    </div>               
                </div>
                { this.state.user !== null && this.state.user.user_type === "Buyer" &&               
                    <div className="sidebar__varification">
                        <h3>Sent Verification Request</h3>
                        <hr />
                        <form>
                            <div className="packages__rate">
                                <label>Verification Price</label>
                                <h5>£{this.state.amount}</h5>
                            </div>
                        {
                            this.state.showPaymentMethod &&
                            <div style={{"cursor":"pointer"}} className="mb-3 col-5">
                                <Back class="img-svg" onClick={() => this.handlePayment()}/> Back to Form
                            </div>
                        }                         
                        {   !this.state.hideForm &&
                            <React.Fragment>
                            <div className="mb-3">
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Name" 
                                    value={this.state.user.name+" "+this.state.user.last_name}
                                    disabled
                                />
                            </div>
                            <div className="mb-3">
                                <Input 
                                    type="email"
                                    className="form-control"
                                    placeholder="Enter Email"
                                    value={this.state.user.email}
                                    disabled 
                                />
                            </div>
                            <div className="mb-4">
                                <textarea
                                    className="form-control"
                                    rows="5"
                                    placeholder="Message"
                                    value = {this.state.message}
                                    onChange={(e) => this.handleMessage(e.target.value)}>
                                </textarea>
                            </div>
                            {/* { this.state.user && this.state.user.subscriptionPlanDetail && this.state.user.subscriptionPlanDetail.plan_name!="Basic Plan" && */}
                                <div className="mb-4">
                                    <label className="customcheck">Do you have Transferrable Verification Code or Coupon Code
                                        <Input type="checkbox" checked={this.state.checked} onChange={(e) => this.handleVerificationCodeCheck(e.target.checked)} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            {/* } */}
                            {  
                                this.state.haveVerificationCode &&
                                <div className="mb-5">
                                    <div className="coupon_code_cnt">
                                        <Input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Code"
                                            value = {this.state.verificationCode}
                                            onChange={(e) => this.handleVerificationCode(e.target.value)}
                                            disabled={this.state.disableCouponBox ? true : false}
                                        />
                                        <Button className="mb-2 btn btn-secondary" onClick={(e) => this.checkVerificationCodeStatus()} disabled={this.state.disableApplyButton ? true : false}>Apply Code</Button>
                                    </div>
                                    {
                                        this.state.showSuccessMsg && 
                                        <span className="text-success">Verification Code applied successfully</span>
                                    }
                                </div>
                            }
                            <Button className="btn btn-secondary btn-block" onClick={() => this.handlePayment()} disabled={this.state.disableSendButton?true:false}>Proceed to pay</Button>
                            </React.Fragment>
                        }
                            {/* <Button className="btn btn-secondary btn-block" onClick={(e) => this.sendVerificationRequest()} disabled={this.state.disableSendButton?true:false}>Submit</Button> */}
                        { this.state.showPaymentMethod &&    
                            <PayPalScriptProvider options={{ "client-id": PAYPAL_CLIENT_ID}}>
                                <PayPalButtons
                                    //fundingSource="paypal"
                                    createOrder={(data, actions) => {
                                        return actions.order.create({
                                            purchase_units: [
                                                {
                                                    amount: {
                                                        value: this.state.amount,
                                                    },
                                                },
                                            ],
                                            application_context: {
                                                shipping_preference: 'NO_SHIPPING'
                                            }
                                        });
                                    }}
                                    onApprove={(data, actions) => {
                                        return actions.order.capture().then((details) => {
                                            const name = details.payer.name.given_name;
                                            {
                                                details.status === "COMPLETED" &&
                                                this.sendVerificationRequest()
                                            }
                                        });
                                    }}
                                />
                            </PayPalScriptProvider>
                        }
                        </form>
                    </div>
                }
                { this.state.user==null &&
                    <>
                    <div className="sidebar__varification">
                        <h3>Sent Verification Request</h3>
                        <hr />
                        <Link to={"/signin/"} type="submit" className="btn btn-secondary btn-block">Send Verification Request</Link>
                    </div>
                    </>
                }               
                <div className="sidebar__sproperties">
                <h3>Similar properties</h3>
                <div>
                    {   this.state.similarProperties.length === 0 &&
                        <article className="property">
                            <div className="row align-items-center">
                                <div className="col-sm-12">
                                No Similar properties found
                                </div>
                            </div>
                        </article>
                    }
                    {   this.state.similarProperties.length > 0 && this.state.similarProperties.map((item, index) => (
                        <article className="property">
                            <div className="row align-items-center">
                                <div className="col-sm-5">
                                <div className="position-relative">
                                {this.state.user==null   &&
                                    <Link to="/signin" className="property__like"   >
                                        <Heart className="img-svg animate" alt="Save" />
                                    </Link>
                                }
                                {this.state.user!=null  && this.state.user.user_type=="Buyer" &&
                                    <a href="javascript:;" className="property__like"  onClick={()=>this.handelSimilarLike(item)} >
                                    { item.wishlist== true ?
                                        <HeartRed class="img-svg favourite"  alt="property" />
                                        :<Heart className="img-svg animate" alt="Save" />
                                    }
                                    </a>
                                }
                                <div className="property__img">
                                    {	item.property_media.length > 0 && item.property_media[0].type === "image"
                                        ? <img src={item.property_media[0].url_path} className="animate" alt="property" />
                                        : <img src={Image1} class="animate" alt="property" />
                                    }
                                    {	
                                        item.verification_banner === "yes" && 
                                        <div class="property_verified gradient2"><span>verified</span><small>Premium</small></div>
                                    }
                                </div>
                                </div>
                                </div>
                                <div className="col-sm-7">
                                <div className="property__cnt">
                                    <h3><a style={{cursor:"pointer"}}  onClick={()=>this.getProperty(item)}  >{item.name}</a></h3>
                                    <div data-bs-toggle="tooltip" title="Location"><GeoAlt class="img-svg" alt="" /><span className="location">{item.property_address.city_name}</span> </div>
                                    <h4 className="price">{
                                        item.property_features.denomination === "Dollar" ?
                                        "$"
                                        : 
                                        item.property_features.denomination === "Naira" ?
                                        "₦"
                                        :
										item.property_features.denomination === "Pounds" ?
                                        "£"
										: ""
                                    }
                                    <NumberFormat
                                        thousandsGroupStyle="thousand"
                                        value={Math.round(item.property_features.listing_price)}
                                        displayType="text"
                                        type="text"
                                        thousandSeparator={true}
                                    /></h4>
                                </div>
                                </div>
                            </div>
                        </article>
                    ))
                    }
                </div>
                </div>
            </div>
		)
	}
}
