import React, { Component } from 'react'
import Navbar from '../Navbar'
import Sidebar from '../Sidebar'
import Footer from '../../../Front/Footer'
import axios from "axios";
import { APIURL,SOCKETURL } from '../../../../components/constants/common';
import { Redirect, Link } from 'react-router-dom'
import dateFormat, { masks } from "dateformat";
import { Modal, Spinner, ModalBody, ModalHeader, ModalFooter, Button, Input } from 'reactstrap'
import face1 from '../../../../assets/images/profile-2.webp'
import send from '../../../../assets/images/send.png'
import {ReactComponent as  ChatLoader} from '../../../../assets/images/chat_loader.svg'
import { ReactComponent as MenuList}  from '../../../../assets/images/three-dots-vertical.svg'

import socketClient from "socket.io-client";

export default class PackageTransaction extends Component {
    constructor() {
        super();
         this.chat_board_msg = React.createRef()
        this.state = {
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            subUser:[],
            messageList:[],
            to:0,
            norecordStatus:false,
            message:"",
            page:0,
            loadMoreVisiblity:false,
            cLoader:false,
            socket:"",
            subscribedPlans:"",
            subscribedPlansChcek:false,
            messageGetStatus:false,
            mobileView: false      
          
        }
        this.sendHandle = this.sendHandle.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this);
    }

    getSubUserCheck() {
        if (this.state.user) {
            const formData = new FormData();
            var token = this.state.token
            var app_url = APIURL+"buyer/check-sub-user-exist-for-chat"
            axios
                .post(app_url, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    var to=0;
                    if(response.data.data.length>0)
                    {
                      to=response.data.data[0].id
                    }
                    this.setState({
                        subUser: response.data.data,
                        to:to,
                        norecordStatus:true
                    },()=>{
                    this.getMessage()
                     this.updateMessage()
                   })
                })
                .catch((error) => {
                     this.setState({
                     
                        norecordStatus:true
                    })
                  
                });
        }
    }

    toggleView() {
        this.setState({
            mobileView: !this.state.mobileView
        })
    }

    sendHandle(){
        if(this.state.message.trim()=="")
        {
          return false  
        }
        this.setState({
            Loader:true
        })
        var token = this.state.token
        const formData = new FormData();
        formData.append('to', this.state.to);
        formData.append('message', this.state.message);
        formData.append('property_id','');
      
        axios
            .post(APIURL + "buyer/send-message", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                var messageList=this.state.messageList;
                var message=this.state.message;
                messageList.push(response.data.data);

                var socket = this.state.socket;
                socket.emit('message',response.data.data);

                this.setState({
                    messageList: messageList,
                    message:"",
                    Loader:false
                },()=>{
                    this.scrollDownMessage() 
                    
                })


            })
            .catch((error) => {
                this.setState({
                    Loader:false
                })
            });
    }


    getMessage() {
        this.setState({
            cLoader:true
        })
        var token = this.state.token
        const formData = new FormData();
        formData.append('to', this.state.to);
        formData.append('page', this.state.page);

        axios
            .post(APIURL + "buyer/get-message", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                var messageList=this.state.messageList
                messageList=[...response.data.data,...messageList];
                var loadMoreVisiblity=false;
                if(response.data.data.length>0){
                  loadMoreVisiblity=true
                }


                
                this.setState({
                    messageList: messageList,
                    cLoader:false,
                    loadMoreVisiblity:loadMoreVisiblity,
                    messageGetStatus:true
                },()=>{
                    if(this.state.page==0){
                    this.scrollDownMessage()
                   }
                })

            })
            .catch((error) => {
                this.setState({
                    cLoader:false,
                    messageGetStatus:true
                })
            });
    }

    updateMessage() {
       
        var token = this.state.token
        const formData = new FormData();
        formData.append('to', this.state.to);

        axios
            .post(APIURL + "buyer/update-message-status", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
            })
            .catch((error) => {
               
            });
    }


    setTo(item){
      
        this.setState({
            to: item.id,
        },()=>{
            this.getMessage()
        })
    }

    messageHandle(val){
      this.setState({
            message: val
        })
    }

   _handleKeyDown(e) {

        if (e.key === 'Enter') {
          this.sendHandle();
        }
      }


    scrollDownMessage(){
         this.chat_board_msg.current.scrollTop =  this.chat_board_msg.current.scrollHeight;
    }
    
    loadMore(){
        var page=this.state.page;
        page+=1;

    this.setState({
            page: page,
        },()=>{
            this.getMessage()
        })
    }


    configureSocket = () => {
        var socket = socketClient(SOCKETURL);
        
        this.setState({
            socket: socket
        })
        socket.on('message', data => {
            if(data.to==this.state.user.id && data.from==this.state.to)
            {
                var messageList=this.state.messageList;
                messageList.push(data);
                this.setState({
                    messageList: messageList
                },()=>{
                    this.scrollDownMessage()
                    this.updateMessage()
                })
            }
        });

    }

     getUserSubscribedPlans() {
        if (this.state.user) {
            var token = this.state.token
            var app_url = APIURL+"get-user-subscription-plan"
            const formData = new FormData();
            formData.append('user_id', this.state.user.id);
           
            axios
                .post(app_url,formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    this.setState({
                        subscribedPlans: response.data.data,
                        subscribedPlansChcek:true
                    })
                })
                .catch((error) => {
                    this.setState({
                        subscribedPlansChcek:true
                        //rrMsg: error.response.data.error ,
                    })
                });
        }
    }


    componentDidMount() {
      this.getSubUserCheck()
      this.getUserSubscribedPlans()
      this.configureSocket();
    }
    

    render() {

        if (!this.state.user) {
            return <Redirect to="/signin" />;
        }

        if (this.state.user.user_type !== "Buyer") {
            return <Redirect to="/permission" />;
        }

        if (this.state.norecordStatus === true && this.state.to==0) {
            return <Redirect to="/permission" />;
        }
        if (this.state.subscribedPlans.plan_name!="Premium Plan" && this.state.subscribedPlansChcek==true) {
            return <Redirect to="/permission" />;
        }


        

        return (
            <>
                <Navbar />
                <div class="content-area">
                    {this.state.Loader ? <div className="loader"> <Spinner type="grow" color="dark" /> </div> : ""}

                    <div class="breadcrumb-area gradient1">
                        <div class="container">
                            <h1>My Account</h1>
                            <div><small>Welcome To Your Account</small></div>
                        </div>
                    </div>
                    <div className={this.state.mobileView ? "sidebar_alpha open" : "sidebar_alpha"}></div>
                    <div class="myaccount">
                        <div className='more-menu' onClick={()=>this.toggleView()}><MenuList class="img-svg" alt="menu-list"/></div>
                        <div class="container">
                            <div class="row">
                                <div class="col-xxl-3 col-lg-4">
                                    <div className={this.state.mobileView ? "bg-light h-100 sidebar_mobile left open" : "bg-light h-100 sidebar_mobile left"}>
                                        <div class="px-xxl-4 py-4 px-3">
                                            <Sidebar activePage="support_chat" />
                                        </div>
                                    </div>
                                </div>
                                 <div class="col-xxl-9 col-lg-8">

                                    <div className="chat_area full_screen border">
                                     { this.state.cLoader===true &&
                                       <div className="chat_loader"><ChatLoader class="img-svg" /></div>
                                     }
                                     { this.state.subUser.length>0 &&
                                        <h2 className="chat_head">{this.state.subUser[0].fullName}</h2>
                                    }

                                        <div className="chat_board">                                          
                                            <div className="chat_board_msg" ref={this.chat_board_msg}>
                                              { this.state.loadMoreVisiblity==true &&  this.state.cLoader===false && this.state.messageList.length > 9 &&
                                                <div className="chat_load_more">
                                                <Button className="btn-primary btn btn-sm" onClick={() => this.loadMore()} > Load More</Button>
                                                </div>
                                             }
                                                {this.state.messageList.length > 0 && this.state.messageList.map((item, idx) => (
                                                <React.Fragment>
                                                {item.to!=this.state.to &&
                                                    <div className="bubble bubbleIn">
                                                        <p>{item.message}</p>
                                                        <span className="date">{item.date} {item.timestamp}</span>
                                                    </div>
                                                }
                                                {item.to==this.state.to &&
                                                    <div className="bubble bubbleOut">
                                                        <p>{item.message}</p>
                                                      
                                                        <span className="date">{item.date} {item.timestamp}</span>
                                                    </div>
                                                }
                                               
                                                 </React.Fragment>
                                                
                                              ))}

                                               {this.state.messageList.length == 0 && this.state.messageGetStatus==true &&
                                                  <div className="bubble no-message">Send message to admin</div>
                                               }
                                            </div>

                                           {this.state.to > 0 &&

                                            <div className="chat-input">
                                                <input type="text" placeholder="Type a message" value={this.state.message}  onKeyDown={this._handleKeyDown} onChange={(e) => this.messageHandle(e.target.value)} />
                                                <Button><img src={send} alt="send" onClick={() => this.sendHandle()} /></Button>
                                            </div>
                                           }
                                        </div>
                                    </div> 
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />

            </>
        )
    }
}
